import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

// mode: default / editable
import CLPolicyHolderPage from './pages/PolicyHolder/CLPolicyHolderPage';
// mode: readOnly
import CLPolicyHolderReadOnlyPage from './pages/PolicyHolder/CLPolicyHolderPage.readonly';

setComponentMapOverrides(
    {
        CLPolicyHolderPage: { component: 'CLPolicyHolderPage' },
        CLPolicyHolderReadOnlyPage: { component: 'CLPolicyHolderReadOnlyPage' },
    },
    {
        CLPolicyHolderPage,
        CLPolicyHolderReadOnlyPage
    }
);

export { default as CLPolicyHolder } from './pages/PolicyHolder/CLPolicyHolderPage';
export { default as NewSubmissionBlocked } from './pages/NewSummissionBlocked/NewSubmissionBlocked';