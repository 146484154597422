import React, { useCallback, useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useModal } from '@jutro/components';
import {
    WizardPage,
    wizardProps
} from '@xengage/gw-portals-wizard-react';
import { WizardPageTemplate, WizardPageTemplateWithTitle } from 'gw-portals-wizard-components-ui';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { Claim } from 'gw-capability-fnol-common-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { withRouter } from 'react-router-dom';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import metadata from './WhatPage.metadata.json5';
import FNOLCACollisionDetailsPage from '../Details/Collision/CollisionDetailsPage';
import FNOLCATheftDetailsPage from '../Details/Theft/TheftDetailsPage';
import FNOLCAGlassDetailsPage from '../Details/Glass/GlassDetailsPage';
import messages from '../../FNOLCA.messages';
import wizardConfig from '../../config/fnol-ca-wizard-config.json5';

const collision = {
    id: 'details',
    path: '/details',
    component: FNOLCACollisionDetailsPage,
    stepProps: {
        template: WizardPageTemplateWithTitle
    },
    title: messages.caCollisionDetails
};

const theft = {
    id: 'details',
    path: '/details',
    component: FNOLCATheftDetailsPage,
    stepProps: {
        template: WizardPageTemplateWithTitle
    },
    title: messages.caTheftDetails
};

const glass = {
    id: 'details',
    path: '/details',
    component: FNOLCAGlassDetailsPage,
    stepProps: {
        template: WizardPageTemplateWithTitle
    },
    title: messages.caGlassDetails
};

function FNOLCAWhatPage(props) {
    const modalApi = useModal();
    const {
        wizardData: claimVM,
        updateWizardData,
        changeNextSteps,
        history,
        history: {
            location: { state = {} }
        },
        authHeader
    } = props;
    const { steps: wizardSteps } = wizardConfig;
    const [isLoading, setIsLoading] = useState(false);
    const { FNOLService } = useDependencies('FNOLService');
    const [pageData] = useState(state);
    const translator = useTranslator();
    const lossCauseTypesArray = claimVM.lossCause.aspects.availableValues;
    const collisionTypeCodes = _.filter(claimVM.lossCause.aspects.availableValues, (typeCode) => {
        // for purposes of portal 'otherobjcoll' is not a collision code
        const nonCollisionAutoCodes = [
            'theftentire',
            'glassbreakage',
            'theftparts',
            'otherobjcoll'
        ];
        return nonCollisionAutoCodes.indexOf(typeCode.code) === -1;
    });
    const {
        onValidate,
        isComponentValid,
        initialValidation,
        registerInitialComponentValidation
    } = useValidation('FNOLCAWhatPage');

    const getLossCause = useCallback(
        (selectedLossCause) => {
            const LossCause = _.find(lossCauseTypesArray, (cause) => {
                return cause.code === selectedLossCause;
            });
            _.set(claimVM, 'lossCause.value', LossCause);
            updateWizardData(claimVM);
        },
        [claimVM, lossCauseTypesArray, updateWizardData]
    );

    const isClaimStatus = useCallback(() => {
        const { claimStatus } = pageData;
        return !_.isEmpty(claimStatus);
    }, [pageData]);

    useEffect(() => {
        registerInitialComponentValidation(isClaimStatus);
    }, [registerInitialComponentValidation, isClaimStatus]);

    const incidentPageHandling = useCallback(() => {
        const [, , ...remainingSteps] = wizardSteps;
        const detailPageIndex = 0;
        let fleetPage;
        let fleetpageobj;

        switch (_.get(claimVM.value, 'lossCause')) {
            case 'vehcollision':
                remainingSteps[detailPageIndex] = collision;
                break;
            case 'theftentire':
                fleetPage = remainingSteps.splice(detailPageIndex + 1, 1);
                [fleetpageobj] = fleetPage;
                remainingSteps[detailPageIndex] = fleetpageobj;
                remainingSteps[detailPageIndex + 1] = theft;
                break;
            case 'glassbreakage':
                fleetPage = remainingSteps.splice(detailPageIndex + 1, 1);
                [fleetpageobj] = fleetPage;
                remainingSteps[detailPageIndex] = fleetpageobj;
                remainingSteps[detailPageIndex + 1] = glass;
                break;
            default:
                remainingSteps[detailPageIndex] = collision;
        }
        changeNextSteps(remainingSteps);
        return claimVM;
    }, [changeNextSteps, claimVM, wizardSteps]);

    useEffect(() => {
        incidentPageHandling();
        // It should call once when page is rendered
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const redirectContactAgent = useCallback(() => {
        const { redirectPath } = pageData;
        const claimNumber = _.get(claimVM.value, 'claimNumber');
        return history.push({
            pathname: `/contact-agent/${claimNumber}`,
            state: {
                redirectPath: redirectPath
            }
        });
    }, [claimVM.value, history, pageData]);

    const onNext = useCallback(() => {
        let miscellaneous = claimVM.lossCause.value.code === 'otherobjcoll';
        const policyNumber = _.get(claimVM.value, 'policy.policyNumber');
        const isClaimNumberAvailable = _.get(claimVM.value, 'claimNumber');
        if (isClaimNumberAvailable) {
            if (miscellaneous) {
                return redirectContactAgent();
            }
            return incidentPageHandling();
        }
        setIsLoading(true);
        return FNOLService.getFNOLDetails(policyNumber, claimVM.value, authHeader)
            .then((response) => {
                claimVM.value = new Claim(response);
                miscellaneous = claimVM.lossCause.value.code === 'otherobjcoll';
                if (miscellaneous) {
                    return redirectContactAgent();
                }
                return incidentPageHandling();
            })
            .catch(() => {
                modalApi.showAlert({
                    title: messages.caCreateDraftErrorTitle,
                    message: messages.caCreateDraftErrorMessage,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [FNOLService, authHeader, claimVM, incidentPageHandling, redirectContactAgent]);

    const getIncidentTypeRadioButtonValue = useCallback(() => {
        const lossCause = _.get(claimVM.value, 'lossCause');
        const isCollisionSelected = !!_.find(collisionTypeCodes, {
            code: lossCause
        });
        return isCollisionSelected ? 'vehcollision' : lossCause;
    }, [claimVM, collisionTypeCodes]);

    const overrides = {
        incidentTypeRadioButton: {
            value: getIncidentTypeRadioButtonValue(),
            onValueChange: getLossCause
        },
        whatPageLoader: {
            loaded: !isLoading,
            loadingMessage: translator(messages.caCreateDraftClaim)
        },
        claimsCaTypeOfIncidentPage: {
            visible: !isLoading
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, claimVM, id, path, overrides);
        },
        [claimVM, overrides]
    );

    return (
        <WizardPage
            skipWhen={initialValidation}
            disableNext={!isComponentValid}
            onNext={onNext}
            template={WizardPageTemplate}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                resolveValue={readValue}
                overrideProps={overrides}
                onValidationChange={onValidate}
                onModelChange={updateWizardData}
            />
        </WizardPage>
    );
}

FNOLCAWhatPage.propTypes = wizardProps;
FNOLCAWhatPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};
export default withRouter(withAuthenticationContext(FNOLCAWhatPage));
