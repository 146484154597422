import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('imMiscellaneousFloatersService'), method, data, additionalHeaders);
}

export default class IMMiscellaneousFloatersService {
  
    /**
     * Invokes IMMiscellaneousFloatersHandler.getMiscellaneousFloatersClausesWithSync()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getMiscellaneousFloatersClausesWithSync(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getMiscellaneousFloatersClausesWithSync', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMMiscellaneousFloatersHandler.getMiscellaneousFloatersClauses()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getMiscellaneousFloatersClauses(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getMiscellaneousFloatersClauses', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMMiscellaneousFloatersHandler.updateMiscellaneousFloatersClauses()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} contractorsEquipmentToUpdate
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateMiscellaneousFloatersClauses(jobNumber, sessionUUID, contractorsEquipmentToUpdate, authHeader = {}) {
        return processSubmission('updateMiscellaneousFloatersClauses', [jobNumber, sessionUUID, contractorsEquipmentToUpdate], authHeader);
    }

    /**
     * Invokes IMMiscellaneousFloatersHandler.updateMiscellaneousFloatersSchedule()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} scheduleDto
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateMiscellaneousFloatersSchedule(jobNumber, sessionUUID, scheduleDto, authHeader = {}) {
        return processSubmission('updateMiscellaneousFloatersSchedule', [jobNumber, sessionUUID, scheduleDto], authHeader);
    }

    /**
     * Invokes IMMiscellaneousFloatersHandler.onMiscellaneousFloatersNext()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static onMiscellaneousFloatersNext(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('onMiscellaneousFloatersNext', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMMiscellaneousFloatersHandler.calculateLimit()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {string} termCode
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static calculateLimit(jobNumber, sessionUUID, termCode, authHeader = {}) {
        return processSubmission('calculateLimit', [jobNumber, sessionUUID, termCode], authHeader);
    }
}