import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('imBoatCoverageService'), method, data, additionalHeaders);
}

export default class IMBoatCoverageService {
  
    /**
     * Invokes IMBoatCoverageHandler.getBoatCoverageClausesWithSync()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getBoatCoverageClausesWithSync(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getBoatCoverageClausesWithSync', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMBoatCoverageHandler.getBoatCoverageClauses()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getBoatCoverageClauses(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getBoatCoverageClauses', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMBoatCoverageHandler.updateBoatCoverageClauses()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} boatCoverageToUpdate
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateBoatCoverageClauses(jobNumber, sessionUUID, boatCoverageToUpdate, authHeader = {}) {
        return processSubmission('updateBoatCoverageClauses', [jobNumber, sessionUUID, boatCoverageToUpdate], authHeader);
    }

    /**
     * Invokes IMBoatCoverageHandler.updateCoveragePartSchedule()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} scheduleDto
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateCoveragePartSchedule(jobNumber, sessionUUID, scheduleDto, authHeader = {}) {
        return processSubmission('updateCoveragePartSchedule', [jobNumber, sessionUUID, scheduleDto], authHeader);
    }

    /**
     * Invokes IMBoatCoverageHandler.postOnChangeCoverageTerm()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} dto
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static postOnChangeCoverageTerm(jobNumber, sessionUUID, dto, authHeader = {}) {
        return processSubmission('postOnChangeCoverageTerm', [jobNumber, sessionUUID, dto], authHeader);
    }

    /**
     * Invokes IMBoatCoverageHandler.onBoatCoverageNext()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static onBoatCoverageNext(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('onBoatCoverageNext', [jobNumber, sessionUUID], authHeader);
    }
}