import _ from 'lodash';
import { WniProductsUtil } from 'wni-portals-util-js';

function checkProductGoliveAvailable(productCode, enableCommercialLine, getProductItem) {
  let isGoLiveAvailable = true;
  const isCL = WniProductsUtil.isCLProduct(productCode);
  if (isCL && enableCommercialLine) {
      const productItem = getProductItem(productCode);
      if (!_.isEmpty(productItem)) {
          isGoLiveAvailable = _.get(productItem, 'available');
      }
  }
  return isGoLiveAvailable;
}

function filterActiveQuotesByGoLiveConfig(activeQuotes, enableCommercialLine, getProductItem) {
  const filteredQuotes = [];
  _.each(activeQuotes, (accountActiveJobDTO) => {
    const {
      productCode
    } = accountActiveJobDTO;
    const isGolivePass = (checkProductGoliveAvailable(productCode, enableCommercialLine, getProductItem));
    if (isGolivePass) {
      filteredQuotes.push(accountActiveJobDTO);
    }
  })
  return filteredQuotes;
}

export default {
  checkProductGoliveAvailable,
  filterActiveQuotesByGoLiveConfig
};