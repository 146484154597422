import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import WniCheckboxGroup from './components/CheckboxField/WniCheckboxGroup';
import WniImageCheckboxField from './components/CheckboxField/ImageCheckboxField';
import WniImageCheckboxGroup from './components/CheckboxField/ImageCheckboxGroup';
import WniDataTable from './components/DataTable/WniDataTable';
import WniDateField from './components/DateField/WniDateField';
import WniDropdownGroup from './components/DropdownGroup/DropdownGroup';
import {
    WniCurrencyWithAction,
    WniDateWithAction,
    WniDropdownWithAction,
    WniInputMaskWithAction,
    WniInputNumberWithAction,
    WniInputTextWithAction,
    WniLookupFieldWithAction,
    WniMultiSelectWithAction,
    WniTextAreaWithAction,
    WniTypeaheadMultiSelectWithAction,
} from './components/FieldWithAction/FieldWithAction';
import {
    WniCheckboxField,
    WniCheckboxGroupField,
    WniCurrency,
    WniDate,
    WniDropdownSelect,
    WniInputMask,
    WniInputNumber,
    WniPhoneNumber,
    WniInputText,
    WniLookupField,
    WniMultiSelect,
    WniMultiSelectNext,
    WniTextArea,
    WniToggle,
    WniRadio,
    WniTypeaheadMultiSelect,
} from './components/FieldWithTooltip/FieldWithTooltip';
import WniInlineNotification from './components/InlineNotification/InlineNotification';
import MultiSelectField from './components/MultiSelectField/MultiSelectField';
import WniHeader from './components/Header/WniHeader';
import WniNotification from './components/Notification/WniNotifiction';

setComponentMapOverrides(
    {
        WniDateField: { component: 'WniDateField' },
        WniDataTable: { component: 'WniDataTable' },
        MultiSelectField: { component: 'MultiSelectField' },
        WniDropdownGroup: {component: 'WniDropdownGroup' },
        // custom inlineNotification
        InlineNotification: { component: 'InlineNotification' },
        inlinenotification: { component: 'inlinenotification' },
        Inlinenotification: { component: 'inlinenotification' },

        /**
         * for ImageCheckbox Component
         */
        WniCheckboxGroup: { component: "WniCheckboxGroup" },
        WniImageCheckboxField: { component: 'WniImageCheckboxField' },
        WniImageCheckboxGroup: { component: 'WniImageCheckboxGroup' },
        /**
         * for Field With Tooltip Component
         */
        WniInputText: { component: 'WniInputText' },
        WniInputNumber: { component: 'WniInputNumber' },
        WniPhoneNumber: { component: 'WniPhoneNumber' },
        WniInputMask: { component: 'WniInputMask' },
        WniDropdownSelect: { component: 'WniDropdownSelect' },
        WniCurrency: { component: 'WniCurrency' },
        WniDate: { component: 'WniDate' },
        WniTextArea: { component: 'WniTextArea' },
        WniTypeaheadMultiSelect: { component: 'WniTypeaheadMultiSelect' },
        WniMultiSelect: { component: 'WniMultiSelect' },
        WniMultiSelectNext: { component: 'WniMultiSelectNext' },
        WniLookupField: { component: 'WniLookupField' },
        WniToggle: { component: 'WniToggle' },
        WniRadio: { component: 'WniRadio' },
        WniCheckboxField: { component: 'WniCheckboxField,' },
        WniCheckboxGroupField: { component: 'WniCheckboxGroupField' },
        /**
         * for Field With Action Component
         */
        WniInputTextWithAction: { component: 'WniInputTextWithAction' },
        WniInputNumberWithAction: { component: 'WniInputNumberWithAction' },
        WniInputMaskWithAction: { component: 'WniInputMaskWithAction' },
        WniDateWithAction: { component: 'WniDateWithAction' },
        WniCurrencyWithAction: { component: 'WniCurrencyWithAction' },
        WniDropdownWithAction: { component: 'WniDropdownWithAction' },
        WniTypeaheadMultiSelectWithAction: { component: "WniTypeaheadMultiSelectWithAction"},
        WniMultiSelectWithAction: { component: "WniMultiSelectWithAction"},
        WniLookupFieldWithAction: { component: "WniLookupFieldWithAction"},

        WniHeader: { component: "WniHeader"},
        WniNotification: {component: "WniNotification"}
    },
    {
        WniDateField,
        WniDataTable,
        MultiSelectField,
        WniDropdownGroup,
        InlineNotification: WniInlineNotification,
        inlinenotification: WniInlineNotification,
        Inlinenotification: WniInlineNotification,
        WniCheckboxGroup: WniCheckboxGroup,
        WniImageCheckboxField: WniImageCheckboxField,
        WniImageCheckboxGroup: WniImageCheckboxGroup,
        // with tooltip
        WniInputText,
        WniInputNumber,
        WniPhoneNumber,
        WniInputMask,
        WniDropdownSelect,
        WniCurrency,
        WniDate,
        WniTextArea,
        WniTypeaheadMultiSelect,
        WniMultiSelect,
        WniMultiSelectNext,
        WniLookupField,
        WniToggle,
        WniRadio,
        WniCheckboxField,
        WniCheckboxGroupField,
        // with action
        WniInputTextWithAction,
        WniInputMaskWithAction,
        WniInputNumberWithAction,
        WniDateWithAction,
        WniCurrencyWithAction,
        WniDropdownWithAction,
        // WniTextAreaWithAction,
        WniTypeaheadMultiSelectWithAction,
        WniMultiSelectWithAction,
        WniLookupFieldWithAction,
        WniHeader,
        WniNotification
    }
);
export { default as WniInlineNotification } from './components/InlineNotification/InlineNotification';
export { default as ModalBody } from './components/Modal/components/ModalBody';
export { default as ModalFooter } from './components/Modal/components/ModalFooter';
export { default as ModalHeader } from './components/Modal/components/ModalHeader';
export { default as ModalNext } from './components/Modal/components/ModalNext';
export { default as useWniBaseModal } from './components/Modal/useWniModal';
export { default as WniModalProvider } from './components/Modal/WniModalProvider';
export {
    WniDateField,
    WniDataTable,
    MultiSelectField,
    WniDropdownGroup,
    WniCheckboxGroup,
    WniImageCheckboxField,
    WniImageCheckboxGroup,
    // with tooltip
    WniInputText,
    WniInputNumber,
    WniPhoneNumber,
    WniInputMask,
    WniDropdownSelect,
    WniCurrency,
    WniDate,
    WniTextArea,
    WniTypeaheadMultiSelect,
    WniMultiSelect,
    WniMultiSelectNext,
    WniLookupField,
    WniToggle,
    WniRadio,
    WniCheckboxField,
    WniCheckboxGroupField,
    // with action
    WniInputTextWithAction,
    WniInputMaskWithAction,
    WniInputNumberWithAction,
    WniDateWithAction,
    WniCurrencyWithAction,
    WniDropdownWithAction,
    WniTextAreaWithAction,
    WniTypeaheadMultiSelectWithAction,
    WniMultiSelectWithAction,
    WniLookupFieldWithAction,
    WniHeader,
    WniNotification
};
