import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('imMsicFormsService'), method, data, additionalHeaders);
}

export default class IMMsicFormsService {
  
    /**
     * Invokes IMMiscFormsHandler.getValuablePapersPartWithSync()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getMiscFormsWithSync(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getMiscFormsWithSync', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMMiscFormsHandler.getMiscForms()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getMiscForms(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getMiscForms', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMMiscFormsHandler.updateDroneCoverage()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} droneCoverageDTO
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateDroneCoverage(jobNumber, sessionUUID, droneCoverageDTO, authHeader = {}) {
        return processSubmission('updateDroneCoverage', [jobNumber, sessionUUID, droneCoverageDTO], authHeader);
    }


    /**
     * Invokes IMMiscFormsHandler.calculateLimit()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {string} termCode
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static calculateLimit(jobNumber, sessionUUID, termCode, authHeader = {}) {
        return processSubmission('calculateLimit', [jobNumber, sessionUUID, termCode], authHeader);
    }
    
    /**
     * Invokes IMMiscFormsHandler.updateDroneSupplementalCoverges()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} supplementalCoverages
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateDroneSupplementalCoverges(jobNumber, sessionUUID, supplementalCoverages, authHeader = {}) {
        return processSubmission('updateDroneSupplementalCoverges', [jobNumber, sessionUUID, supplementalCoverages], authHeader);
    }

    /**
     * Invokes IMMiscFormsHandler.updateDroneCoverage()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} coldStorageLockerCoverageDTO
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateColdStorageLockerCoverage(jobNumber, sessionUUID, coldStorageLockerCoverageDTO, authHeader = {}) {
        return processSubmission('updateColdStorageLockerCoverage', [jobNumber, sessionUUID, coldStorageLockerCoverageDTO], authHeader);
    }

    /**
     * Invokes IMMiscFormsHandler.onMiscFormsNext()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static onMiscFormsNext(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('onMiscFormsNext', [jobNumber, sessionUUID], authHeader);
    }
}