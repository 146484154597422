import { defineMessages } from 'react-intl';

export default defineMessages({
    caTypeOfCollisionOccured: {
        id: 'fnol.ca.views.ca-claim-type.What type of collision occurred?',
        defaultMessage: 'What type of collision occurred?'
    },
    caDetailsTypeOfIncident: {
        id: 'fnol.ca.views.ca-collision-loss-details.Type of Incident',
        defaultMessage: 'Type of Incident'
    },
    caDescribeWhatHappened: {
        id: 'fnol.ca.views.ca-collision-loss-details.Please describe what happened:',
        defaultMessage: 'Please describe what happened:'
    },
    caAnyPropertyDamaged: {
        id: 'fnol.ca.views.ca-collision-loss-details.Was any property damaged, other than the vehicle(s) involved in the collision?',
        defaultMessage: 'Was any property damaged, other than the vehicle(s) involved in the collision?'
    },
    caLossDetailsYes: {
        id: 'fnol.ca.views.ca-collision-loss-details.Yes',
        defaultMessage: 'Yes'
    },
    caLossDetailsNo: {
        id: 'fnol.ca.views.ca-collision-loss-details.No',
        defaultMessage: 'No'
    },
    caDescribePropertyDamage: {
        id: 'fnol.ca.views.ca-collision-loss-details.Please briefly describe the property damage',
        defaultMessage: 'Please briefly describe the property damage'
    }
});
