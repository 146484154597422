import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import FNOLCAWizard from './FNOLCAWizard';
import FNOLCAWhatPage from './pages/What/WhatPage';
import FNOLCAVehiclesPage from './pages/Vehicles/VehiclesPage';
import FNOLCACollisionDetailsPage from './pages/Details/Collision/CollisionDetailsPage';
import FNOLCAGlassDetailsPage from './pages/Details/Glass/GlassDetailsPage';
import FNOLCATheftDetailsPage from './pages/Details/Theft/TheftDetailsPage';
import FNOLCAFleetPage from './pages/Fleet/FleetPage';

setComponentMapOverrides(
    {
        FNOLCAWizard: { component: 'FNOLCAWizard' },
        FNOLCAWhatPage: { component: 'FNOLCAWhatPage' },
        FNOLCAVehiclesPage: { component: 'FNOLCAVehiclesPage' },
        FNOLCACollisionDetailsPage: { component: 'FNOLCACollisionDetailsPage' },
        FNOLCAGlassDetailsPage: { component: 'FNOLCAGlassDetailsPage' },
        FNOLCATheftDetailsPage: { component: 'FNOLCATheftDetailsPage' },
        FNOLCAFleetPage: { component: 'FNOLCAFleetPage' }
    },
    {
        FNOLCAWizard,
        FNOLCAWhatPage,
        FNOLCAVehiclesPage,
        FNOLCACollisionDetailsPage,
        FNOLCAGlassDetailsPage,
        FNOLCATheftDetailsPage,
        FNOLCAFleetPage
    }
);

export {
    FNOLCAWizard as default
};
