

import React from 'react';
import { IMPolicyDetailsReadOnlyPage } from 'wni-capability-quoteandbind-im-react';


const IMPolicyDetailsChangeReadOnlyPage = (props) => {
    return <IMPolicyDetailsReadOnlyPage {...props} isPolicyChange isReadOnly />
};
export default IMPolicyDetailsChangeReadOnlyPage
