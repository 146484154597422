import React, {useState, useCallback, useMemo, useEffect} from 'react'
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import {
    QuoteUtil,
    ErrorsAndWarningsUtil,
} from 'wni-portals-util-js';
import { useWniModal } from 'wni-components-platform-react';
import { PortalConstants } from 'wni-portals-config-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { CURiskDetailsService } from 'wni-capability-quoteandbind-cu'
import metadata from './CURiskDetails.metadata.json5';
import WizardPage from '../../templates/CUWizardPage';
import CoveragesConfigContext from '../../context/CUCoveragesConfigContext';
import CUCoveragesConfig from '../../config/CUCoverages.config';
import UmbrellaCoveragesCard from './component/UmbrellaCoveragesCard';
import UmbrellaAdditionalCoveragesCard from './component/UmbrellaAdditionalCoveragesCard';
import ConditionsAndExclusionsCard from './component/ConditionsAndExclusionsCard';
import SearchAndAddClausesPopup from './component/SearchAndAddClausesPopup/SearchAndAddClausesPopup';
// import CoveragesCard from './component/CoveragesCard/CoveragesCard';
// import AdditionalCoveragesCard from './component/AdditionalCoveragesCard/AdditionalCoveragesCard';
// import ExclusionsAndConditionsCard from './component/ExclusionsAndConditionsCard/ExclusionsAndConditionsCard';
// import DetailsCard from './component/DetailsCard';

const CURiskDetailsPage = (props) => {
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData,
        isReadOnly,
    } = props;

    const { initialValidation, onValidate, isComponentValid } = useValidation('CURiskDetailsPage');
    const { authHeader } = useAuthentication();
    const [validationIssues, updateValidationIssues] = useState([])
    const [isEditing, setIsEditing] = useState(false)
    const [showErrors, setShowErrors] = useState(false)
    const [riskDetails, setRiskDetails] = useState()

    const {
        jobID,
        sessionUUID,
        baseData: { 
            periodStatus
        }
    } = submissionVM.value

    const {
        loadingMask: { isLoadingMask, setLoadingMask },
    } = useDependencies('loadingMask');

    const pageLevelValidation = useMemo(
        () => validationIssues,
        [
            validationIssues
        ]
    );

    // Middle layer introduced for the convenience of debugging
    const updateErrorsAndWarningsForCovComponent = (errorsAndWarnings) => {
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        updateValidationIssues(newValidationIssues);
    }

    const initRiskDetails = useCallback(async () => {
        setLoadingMask(true)
        const isDraftPeriod = periodStatus ===  PortalConstants.QUOTE_STATUS_DRAFT

        const res = isDraftPeriod && !isReadOnly ? await CURiskDetailsService.getRiskDetailsWithSync(jobID, sessionUUID, authHeader) 
            : await CURiskDetailsService.getRiskDetails(jobID, sessionUUID, authHeader)
        const {
            errorsAndWarnings,
        } = res
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        setRiskDetails(res)
        setLoadingMask(false)
    }, [authHeader, isReadOnly, jobID, periodStatus, sessionUUID, setLoadingMask]) 

    useEffect(() => {
        initRiskDetails()
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const showCoveragesModal = useCallback((covType) => {
        const componentProps = {
            showCloseBtn: false,
            showCancelBtn: false,
            serviceProps: {
                jobID,
                sessionUUID,
                authHeader
            },
            setLoadingMask,
            covType,
            searchClausesFunc: CURiskDetailsService.searchCEICoverages,
            addSearchedClausesFunc: CURiskDetailsService.addSearchedCEICoverages,
            coverableName: 'Commercial Umbrella and Excess Liability',
            coverableCategory: 'Umbrella'
        };
        return modalApi.showModal(
            <SearchAndAddClausesPopup {...componentProps} />
        );
    }, [authHeader, jobID, modalApi, sessionUUID, setLoadingMask]);

    const onClickAddSearchClauses = useCallback(async (covType) => {
        const data = await showCoveragesModal(covType)
        setRiskDetails(data)
    },[showCoveragesModal])

    const updateWizardDataWhileSetPeriodStatus = (newSubmissionVM) => {
        setWizardDataToDraft(newSubmissionVM)
        updateWizardData(newSubmissionVM)
    } 
    

    const setWizardDataToDraft = useCallback((newSubmissionVM) => {
        // Refactoring Notice: Consider to extract this into a common method
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        return newSubmissionVM
    }, []);

    const onPageNext = async () => {
        if (isReadOnly) {
            return submissionVM
        }
        setLoadingMask(true)
        const errorsAndWarnings = await CURiskDetailsService.onPageNext(jobID, sessionUUID, authHeader)      
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        const hasErrorIssues = newValidationIssues.some((issue) => {
            return issue.type === 'error';
        });
        const hasNewErrorsOrWarnings = ErrorsAndWarningsUtil.hasNewValidationIssue(validationIssues, newValidationIssues)
        if (hasErrorIssues || hasNewErrorsOrWarnings) {
            return false;
        }
        setLoadingMask(false)
        return submissionVM
    }

    const handleError = () => {
        setShowErrors(true)
        return false
    }

    const accordionCommonProps = {
        riskDetails: riskDetails,
        setRiskDetails: (newRiskDetails) => {
            setRiskDetails(newRiskDetails)
            updateWizardDataWhileSetPeriodStatus(submissionVM)
        },
        isEditing,
        setIsEditing,
        isEditable: !isReadOnly,
        showErrors,
        onValidate,
        wizardData: submissionVM,
        updateWizardDataWhileSetPeriodStatus,
        onClickAddSearchClauses
    }

    const overrideProps = {
        '@field': {
            isEditable: !isReadOnly,
        },
        UmbrellaCoveragesCard: {
            ...accordionCommonProps
        },
        UmbrellaAdditionalCoveragesCard: {
            ...accordionCommonProps
        },
        ConditionsAndExclusionsCard: {
            ...accordionCommonProps
        }
    };

    

    const resolvers = {
        callbackMap: {
        },
        componentMap: {
            UmbrellaCoveragesCard: UmbrellaCoveragesCard,
            UmbrellaAdditionalCoveragesCard: UmbrellaAdditionalCoveragesCard,
            ConditionsAndExclusionsCard: ConditionsAndExclusionsCard,
        },
    };

    const disableNavigator = !isReadOnly && (isEditing || isLoadingMask)

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            disableNext={disableNavigator}
            disablePrevious={disableNavigator}
            disableCancel={disableNavigator}
            onNext={isComponentValid || isReadOnly ? onPageNext : handleError}
            alwaysCallOnNext
            showRequiredInfoForFasterQuote
            pageLevelValidationIssues={pageLevelValidation}
        >
            {riskDetails && 
                <CoveragesConfigContext.Provider value={CUCoveragesConfig}>
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        showErrors={showErrors}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.callbackMap}
                        componentMap={resolvers.componentMap}
                    />
                </CoveragesConfigContext.Provider>
            }
        </WizardPage>
    );
}

export default CURiskDetailsPage;