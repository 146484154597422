import _ from 'lodash';
import moment from 'moment';
import React, { useCallback } from 'react';
import { IMPolicyDetailsChangeService } from 'wni-capability-policychange-im';
import { IMPolicyDetailsPage } from 'wni-capability-quoteandbind-im-react';
import { 
    WizardPageJumpUtil,
    WniDateUtil
 } from 'wni-portals-util-js';
import { WizardConstants } from 'wni-portals-config-js';

const IMPolicyDetailsChangePage = (props) => {
    const { 
        wizardData: submissionVM,
        wizardSnapshot,
        wizardPageData,
        updateWizardPageData,
        jumpTo: wizardJumpTo,
        steps: wizardSteps
     } = props;

    const {
        [WizardConstants.landingPage]: landingPage,
    } = wizardPageData;

    const changeData = _.get(submissionVM.value, 'changeData');
    const maximumEffectiveDate = _.get(changeData, 'maximumEffectiveDate');
    const initialEffectiveDate = _.get(wizardSnapshot.value, 'baseData.effectiveDate_Ext');

    const savePolicyDetailsData = async (
        quoteID,
        sessionUUID,
        imPolicyDetailsData,
        // questionSetData,
        quoteBaseData,
        authHeader
    ) => {
        return IMPolicyDetailsChangeService.saveIMPolicyDetailsData(
            quoteID,
            sessionUUID,
            imPolicyDetailsData,
            quoteBaseData,
            changeData,
            authHeader
        );
    };

    const pageOnInit = useCallback(() => {
        if (landingPage) {
            updateWizardPageData({ [WizardConstants.landingPage]: undefined });
            if (landingPage !== 'IMPolicyDetailsPage') {
                const jumpToLandingPage = WizardPageJumpUtil.getJumpToPageFn(wizardSteps, wizardJumpTo, landingPage);
                jumpToLandingPage();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const getOOSPeriods = useCallback(() => {
        const oOSSliceDates = _.get(submissionVM.value, 'changeData.oossliceDates_Ext');
            let oOSStartDate = _.get(wizardSnapshot.value, 'baseData.periodStartDate')
            let oOSEndDate = _.get(wizardSnapshot.value, 'baseData.periodEndDate')
            
            const oOSSliceDatesInMoment = oOSSliceDates.map((res) => {
                return moment(res)
            })
            const sortOOSSliceDatesInMoment = oOSSliceDatesInMoment.sort((a, b) => a.diff(b))
            const oOSEndDateIndex = sortOOSSliceDatesInMoment.findIndex((res) => res.isAfter(moment(initialEffectiveDate)))
            if (oOSEndDateIndex !==-1 ) { 
                oOSEndDate = sortOOSSliceDatesInMoment[oOSEndDateIndex] 
                if (oOSEndDateIndex > 0 ) { 
                oOSStartDate = sortOOSSliceDatesInMoment[oOSEndDateIndex-1]
                }
            }
            if (oOSEndDateIndex ===-1 && (sortOOSSliceDatesInMoment.length>0 )) {
                oOSStartDate = sortOOSSliceDatesInMoment[sortOOSSliceDatesInMoment.length-1]
            }
            return {oOSStartDate, oOSEndDate}
    }, [initialEffectiveDate, submissionVM.value, wizardSnapshot.value])

    const effectiveMinDate = useCallback(() => {
        const {oOSStartDate} = getOOSPeriods()
        return WniDateUtil.getDateObj(oOSStartDate)
    }, [getOOSPeriods])

    const effectiveMaxDate = useCallback(() => {
        const maxEffectiveDateForChangeWithoutBoundChange = moment(_.get(submissionVM, 'value.baseData.periodEndDate')).add(-1, 'days').toDate()
        const maxDate =  ( moment(maximumEffectiveDate).isBefore(maxEffectiveDateForChangeWithoutBoundChange) ?
                maximumEffectiveDate : WniDateUtil.getDateObj(maxEffectiveDateForChangeWithoutBoundChange) )
        return maxDate
    }, [submissionVM, maximumEffectiveDate])

    const isEffectiveDateValid = useCallback(() => {
        const baseEffectiveDate = _.get(submissionVM.value, 'baseData.effectiveDate_Ext');
        const minDate = effectiveMinDate();
        const maxDate = effectiveMaxDate();
        const minDif = moment(baseEffectiveDate).valueOf() - moment(minDate).valueOf();
        const maxDif = moment(maxDate).valueOf() - moment(baseEffectiveDate).valueOf();
        
        return ((minDif >= 0) && (maxDif >= 0))
    }, [effectiveMaxDate, effectiveMinDate, submissionVM.value]);

    const policyChangeOverrideProps = {
        pageOnInit: pageOnInit,
        effectiveMinDate: effectiveMinDate,
        effectiveMaxDate: effectiveMaxDate,
        isEffectiveDateValid: isEffectiveDateValid
    };

    return (
        <IMPolicyDetailsPage
            {...props}
            savePolicyDetailsData={savePolicyDetailsData}
            isPolicyChange
            policyChangeOverrideProps={policyChangeOverrideProps}
        />
    );
};
export default IMPolicyDetailsChangePage;
