import { AccordionCard } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { IMMsicFormsService } from 'wni-capability-quoteandbind-im';
import { WniCheckboxField, WniDropdownSelect, WniInputNumber, WniInputNumberWithAction, WniTextArea } from 'wni-common-base-components';
import MainCoveragesCard from '../../../../components/IMCommonMainCoverage/MainCoveragesCard';
import MiscFormsCoverageSchedule from '../MiscFormsCoverageSchedule';
import DroneSupplementalCoverages from '../DroneSupplementalCoverages';
import messages from './DroneCoverageCard.messages';

const TypeKeyField = (props) => {
    const translator = useTranslator();

    const {
        path,
        label,
        isEditable,
        droneCoverageVM,
        updateDroneCoverage,
        onValidate: onDroneCoverageValidate,
        showErrors,
    } = props;

    const id = `typekeyField_${path}`;

    const { onValidate, isComponentValid } = useValidation(id);

    useEffect(() => {
        if (onDroneCoverageValidate) {
            onDroneCoverageValidate(isComponentValid, id);
        }
        return () => {
            if (onDroneCoverageValidate) {
                onDroneCoverageValidate(true, id);
            }
        };
    }, [id, isComponentValid, onDroneCoverageValidate]);

    return (
        <WniDropdownSelect
            id={id}
            label={label}
            readOnly={!isEditable}
            onValueChange={(value) => updateDroneCoverage(value, path)}
            value={droneCoverageVM.value[path]}
            availableValues={_.get(
                droneCoverageVM,
                `${path}.aspects.availableValues`,
                []
            ).map((e) => ({
                code: e.code,
                name: translator({ id: e.name }),
            }))}
            required
            showRequired
            onValidationChange={onValidate}
            showErrors={showErrors}
        />
    );
};

const LimitTermField = (props) => {

    const {
        path,
        isEditable,
        setIsEditing,
        droneCoverageVM,
        updateDroneCoverage,
        onValidate: onDroneCoverageValidate,
        showErrors,
        actionConfig,
    } = props;

    const id = `LimitTermField_${path}`;

    const term = droneCoverageVM.value[path]

    const {
        directValue: originValue,
        name,
        required,
    } = term

    const [editingValue, setEditingValue] = useState(originValue);

    const { onValidate, isComponentValid } = useValidation(id);

    useEffect(() => setEditingValue(originValue), [originValue])

    useEffect(() => {
        if (onDroneCoverageValidate) {
            onDroneCoverageValidate(isComponentValid, id);
        }
        return () => {
            if (onDroneCoverageValidate) {
                onDroneCoverageValidate(true, id);
            }
        };
    }, [id, isComponentValid, onDroneCoverageValidate]);

    return (
        <WniInputNumberWithAction
            id={id}
            label={name}
            className='fieldLayout_3_2'
            readOnly={!isEditable}
            onValueChange={(value) => {
                setIsEditing(true)
                setEditingValue(value)
            }}
            onBlur={() => {
                setIsEditing(false)
                const newTerm = {
                    ...term,
                    updated: true,
                    directValue: editingValue
                }
                updateDroneCoverage(newTerm, path)
            }}
            value={editingValue}
            showFractions={false}
            required={required}
            showRequired={required}
            onValidationChange={onValidate}
            showErrors={showErrors}
            actionVisible={!!actionConfig}
            actionConfig={actionConfig}
        />
    );
};

const TextAreaTermField = (props) => {

    const {
        path,
        isEditable,
        setIsEditing,
        droneCoverageVM,
        updateDroneCoverage,
        onValidate: onDroneCoverageValidate,
        showErrors,
    } = props;

    const id = `LimitTermField_${path}`;

    const term = droneCoverageVM.value[path]

    const {
        directStringValue: originValue,
        name,
        required,
    } = term

    const [editingValue, setEditingValue] = useState(originValue);

    const { onValidate, isComponentValid } = useValidation(id);

    useEffect(() => setEditingValue(originValue), [originValue])

    useEffect(() => {
        if (onDroneCoverageValidate) {
            onDroneCoverageValidate(isComponentValid, id);
        }
        return () => {
            if (onDroneCoverageValidate) {
                onDroneCoverageValidate(true, id);
            }
        };
    }, [id, isComponentValid, onDroneCoverageValidate]);

    return (
        <WniTextArea
            id={id}
            label={name}
            readOnly={!isEditable}
            onValueChange={(value) => {
                setIsEditing(true)
                setEditingValue(value)
            }}
            onBlur={() => {
                setIsEditing(false)
                const newTerm = {
                    ...term,
                    updated: true,
                    directStringValue: editingValue
                }
                updateDroneCoverage(newTerm, path)
            }}
            value={editingValue}
            required={required}
            showRequired={required}
            onValidationChange={onValidate}
            showErrors={showErrors}
        />
    );
};

const BlanketDroneCoverageLimit = (props) => {
    const translator = useTranslator();

    const {
        isEditable,
        setIsEditing,
        blanketDroneCoverageLimit,
        updateBlanketDroneCoverageLimit,
        onValidate: onDroneCoverageValidate,
        showErrors,
    } = props;

    const id = 'BlanketDroneCoverageLimit';

    const { onValidate, isComponentValid } = useValidation(id);

    const [editingValue, setEditingValue] = useState(blanketDroneCoverageLimit);

    useEffect(() => {
        setEditingValue(blanketDroneCoverageLimit);
    }, [blanketDroneCoverageLimit]);

    useEffect(() => {
        if (onDroneCoverageValidate) {
            onDroneCoverageValidate(isComponentValid, id);
        }
        return () => {
            if (onDroneCoverageValidate) {
                onDroneCoverageValidate(true, id);
            }
        };
    }, [id, isComponentValid, onDroneCoverageValidate]);

    return (
        <WniInputNumber
            id={id}
            label={translator(messages.BlanketDroneLimit)}
            readOnly={!isEditable}
            onValueChange={(value) => {
                setEditingValue(value);
                setIsEditing(true);
            }}
            onBlur={() => {
                setIsEditing(false);
                updateBlanketDroneCoverageLimit(editingValue);
            }}
            showFractions={false}
            required
            showRequired
            value={editingValue}
            onValidationChange={onValidate}
            showErrors={showErrors}
        />
    );
};

const DroneCoverageCard = (props) => {
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');
    const viewModelService = useContext(ViewModelServiceContext);

    const withLoadingMask = async (serviceCallFunc) => {
        setLoadingMask(true);
        const res = await serviceCallFunc();
        setLoadingMask(false);
        return res;
    };

    const {
        miscForms,
        setMiscForms,
        setIsEditing,
        isEditable,
        showErrors,
        onValidate: onPageValidate,

        wizardData: submissionVM,
        updateWizardDataWhileSetPeriodStatus,
        defaultOpenId
    } = props;

    const { droneCoverage } = miscForms;

    const droneCoverageVM = useMemo(
        () =>
            viewModelService.create(
                droneCoverage,
                'pc',
                'wni.edge.capabilities.quote.lob.inlandmarine.dto.miscforms.IMMiscFormsDroneCoverageDTO'
            ),
        [droneCoverage, viewModelService]
    );

    const setDroneCoverage = (newDroneCoverage) => {
        setMiscForms({
            ...miscForms,
            droneCoverage: newDroneCoverage,
        });
    };

    const translator = useTranslator();

    const validationID = 'DroneCoverageCard';

    const { jobID, sessionUUID } = submissionVM.value;

    const { onValidate, isComponentValid } = useValidation(validationID);
    const { authHeader } = useAuthentication();

    useEffect(() => {
        if (onPageValidate) {
            onPageValidate(isComponentValid, validationID);
        }
        return () => {
            if (onPageValidate) {
                onPageValidate(true, validationID);
            }
        };
    }, [isComponentValid, onPageValidate]);

    if (!droneCoverage) {
        return null
    }

    const { supplementalCoverages } = droneCoverage;

    const setSupplementalCoverages = (newSupplementalCoverages) => {
        setDroneCoverage({
            ...droneCoverage,
            supplementalCoverages: newSupplementalCoverages,
        });
    };

    const updateSupplementalCoveragesService = (
        updateSupplementalCoveragesDTO
    ) =>
        IMMsicFormsService.updateDroneSupplementalCoverges(
            jobID,
            sessionUUID,
            updateSupplementalCoveragesDTO,
            authHeader
        );

    const updateDroneCoverage = async (value, path) => {
        const droneCoverageForUpdate = {
            [path]: value,
        };
        const newDroneCoverage = await withLoadingMask(() =>
            IMMsicFormsService.updateDroneCoverage(
                jobID,
                sessionUUID,
                droneCoverageForUpdate,
                authHeader
            )
        );
        setDroneCoverage(newDroneCoverage);
    };

    const calculateLimit = async (termCode) => {
        if (!isEditable) {
            return;
        }
        setLoadingMask(true)
        const newDroneCoverage = await IMMsicFormsService.calculateLimit(jobID, sessionUUID, termCode, authHeader)
        setDroneCoverage(newDroneCoverage)
        setLoadingMask(false)
    }

    const commonFieldProps = {
        isEditable,
        droneCoverageVM,
        updateDroneCoverage,
        onValidate,
        showErrors,
    }

    const commonLimitTermProps = {
        setIsEditing,
        ...commonFieldProps
    }

    return (
        <AccordionCard
            id="DroneCoverageCard"
            type="container"
            initialExpanded={defaultOpenId === 'DroneCoverageCard'}
            chevron
            cardHeadingClassName="font-PrimaryDark-bold-16 font-capitalize"
            title={translator(messages.DroneCoverage)}
            errorState={showErrors && !isComponentValid}
        >
            <MainCoveragesCard
                id="DroneCoverageMainTermsComponent"
                coveragePartClauses={droneCoverage}
                setCoveragePartClauses={setDroneCoverage}
                setIsEditing={setIsEditing}
                isEditable={isEditable}
                showErrors={showErrors}
                onValidate={onValidate}
                wizardData={submissionVM}
                updateWizardDataWhileSetPeriodStatus={
                    updateWizardDataWhileSetPeriodStatus
                }
                mainCoverage={droneCoverage.imdroneMainTermCoverage}
                coverageFormPath="imdroneMainTermCoverage"
                updateCoverageFormClausesService={
                    IMMsicFormsService.updateDroneCoverage
                }
                calculateLimit={calculateLimit}
            />
            <DroneSupplementalCoverages
                supplementalCoverages={supplementalCoverages}
                setSupplementalCoverages={setSupplementalCoverages}
                updateSupplementalCoveragesService={
                    updateSupplementalCoveragesService
                }
                setIsEditing={setIsEditing}
                isEditable={isEditable}
                showErrors={showErrors}
                onValidate={onValidate}
            />
            <h4 className='mb-10'>{translator(messages.DroneCoverage)}</h4>
            <WniDropdownSelect
                id="DroneCoverage"
                label={translator(messages.DroneCoverage)}
                readOnly={!isEditable}
                onValueChange={(value) =>
                    updateDroneCoverage(value, 'droneCoverage')
                }
                value={droneCoverage.droneCoverage}
                availableValues={_.get(
                    droneCoverageVM,
                    'droneCoverage.aspects.availableValues',
                    []
                ).map((e) => ({
                    code: e.code,
                    name: translator({ id: e.name }),
                }))}
                required
                showRequired
                onValidationChange={onValidate}
                showErrors={showErrors}
            />
            {droneCoverage.isBlanketDroneCoverageLimitVisible && (
                <BlanketDroneCoverageLimit
                    isEditable={isEditable}
                    setIsEditing={setIsEditing}
                    blanketDroneCoverageLimit={
                        droneCoverage.blanketDroneCoverageLimit
                    }
                    updateBlanketDroneCoverageLimit={(value) =>
                        updateDroneCoverage(value, 'blanketDroneCoverageLimit')
                    }
                    onValidate={onValidate}
                    showErrors={showErrors}
                />
            )}
            {droneCoverage.droneCoverage === 'ScheduledDroneCoverage' && (
                <MiscFormsCoverageSchedule
                    path='scheduledDroneCoverage'
                    isEditable={isEditable}
                    onValidate={onValidate}
                    setIsEditing={setIsEditing}
                    coverageContainer={droneCoverage}
                    setCoverageContainer={setDroneCoverage}
                    updateCoverageContainerService={IMMsicFormsService.updateDroneCoverage}
                    showErrors={showErrors}
                    jobID={jobID}
                    sessionUUID={sessionUUID}
                />
            )}
            <h4 className='mb-10'>{translator(messages.DroneAndGroundEquipmentCoverage)}</h4>
            <TypeKeyField
                path="droneAndGroundEquipmentCov"
                label={translator(messages.DroneAndGroundEquipmentCoverage)}
                {...commonFieldProps}
            />
            {droneCoverage.droneAndGroundEquipmentCov === 'BlanketDroneAndGroundEquipment' && <>
                <TypeKeyField
                    path="blanketDroneAndGroundEq"
                    label={translator(messages.BlanketDroneandGroundEquipment)}
                    {...commonFieldProps}
                />
                {droneCoverage.blanketDroneAndGroundEq === 'BlanketDroneAndGroundEquipmentLimit' && <LimitTermField
                    path='blanketDroneandGroundEquipmentLimit'
                    {...commonLimitTermProps}
                />}
            </>}
            {droneCoverage.droneAndGroundEquipmentCov === 'ScheduledDroneAndGroundEquipmentCov' && (
                <MiscFormsCoverageSchedule
                    path='scheduledDroneandGroundEquipmentCoverage'
                    isEditable={isEditable}
                    onValidate={onValidate}
                    setIsEditing={setIsEditing}
                    coverageContainer={droneCoverage}
                    setCoverageContainer={setDroneCoverage}
                    updateCoverageContainerService={IMMsicFormsService.updateDroneCoverage}
                    showErrors={showErrors}
                    jobID={jobID}
                    sessionUUID={sessionUUID}
                />
            )}
            <h4 className='mb-10'>{translator(messages.DroneCargoCoverage)}</h4>
            <TypeKeyField
                path="droneCargoCov"
                label={translator(messages.DroneCargoCoverage)}
                {...commonFieldProps}
            />
            {droneCoverage.hasIMDroneCoverageDroneCargoDescriptionTerm && <TextAreaTermField
                path='droneCargoDescriptionTerm'
                {...commonLimitTermProps}
            />}
            {droneCoverage.hasIMDroneCoverageDroneCargoLimitTerm && <LimitTermField
                path='droneCargoLimitTerm'
                {...commonLimitTermProps}
            />}
            <WniCheckboxField
                id='IsIncomeCov_Checkbox'
                label= {translator(messages.DroneCoverageIncomeCoveragePart)}
                readOnly={!isEditable}
                onValueChange={(value) => updateDroneCoverage(value, 'isIncomeCov')}
                labelPosition = 'right'
                showInlineLabel
                value={droneCoverage.isIncomeCov}
            />
            {droneCoverage.isIncomeCov && <>
                {droneCoverage.hasIMDroneCoverageIncomeCoverageLimitSchTerm && <LimitTermField
                    path='incomeCoverageLimitSchTerm'
                    {...commonLimitTermProps}
                />}
                {droneCoverage.hasIMDroneCoverageIncomeCoverageLimitTerm && <LimitTermField
                    path='incomeCoverageLimitTerm'
                    {...commonLimitTermProps}
                />}
                <TypeKeyField
                    path="inCovWaitingPeriod"
                    label={translator(messages.IncomeCoverageWaitingPeriod)}
                    {...commonFieldProps}
                />
                {droneCoverage.hasIMDroneCoverageIncomeCoverageWaitingPeriodTerm && <LimitTermField
                    path='incomeCoverageWaitingPeriodTerm'
                    actionConfig={{text: translator(messages.days)}}
                    {...commonLimitTermProps}
                />}
            </>}
            
        </AccordionCard>
    );
};

export default DroneCoverageCard;
