import { defineMessages } from 'react-intl';

export default defineMessages({
    caDescribeWhatHappened: {
        id: 'fnol.ca.views.ca-theft-details.Type of Incident',
        defaultMessage: 'Type of Incident'
    },
    caVehicleStolen: {
        id: 'fnol.ca.views.ca-theft-details.What was stolen?',
        defaultMessage: 'What was stolen?'
    },
    caEntireVehicle: {
        id: 'fnol.ca.views.ca-theft-details.The entire vehicle',
        defaultMessage: 'The entire vehicle'
    },
    caAudioVehicle: {
        id: 'fnol.ca.views.ca-theft-details.Audio or other equipment on or in the vehicle',
        defaultMessage: 'Audio or other equipment on or in the vehicle'
    },
    caTheftAdditionalInformation: {
        id: 'fnol.ca.views.ca-theft-details.Any additional information about the theft?',
        defaultMessage: 'Any additional information about the theft?'
    }
});
