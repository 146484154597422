import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('imContractorsEquipmentService'), method, data, additionalHeaders);
}

export default class IMContractorsEquipmentService {
  
    /**
     * Invokes IMContractorsEquipmentHandler.getContractorsEquipmentClausesWithSync()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getContractorsEquipmentClausesWithSync(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getContractorsEquipmentClausesWithSync', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMContractorsEquipmentHandler.getContractorsEquipmentClauses()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getContractorsEquipmentClauses(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getContractorsEquipmentClauses', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMContractorsEquipmentHandler.updateContractorsEquipmentClauses()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} contractorsEquipmentToUpdate
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateContractorsEquipmentClauses(jobNumber, sessionUUID, contractorsEquipmentToUpdate, authHeader = {}) {
        return processSubmission('updateContractorsEquipmentClauses', [jobNumber, sessionUUID, contractorsEquipmentToUpdate], authHeader);
    }

    /**
     * Invokes IMContractorsEquipmentHandler.updateContractorsEquipmentSchedule()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} scheduleDto
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateContractorsEquipmentSchedule(jobNumber, sessionUUID, scheduleDto, authHeader = {}) {
        return processSubmission('updateContractorsEquipmentSchedule', [jobNumber, sessionUUID, scheduleDto], authHeader);
    }

    /**
     * Invokes IMContractorsEquipmentHandler.updateContractorsEquipmentAddiInsuredSchedule()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} scheduleDto
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateContractorsEquipmentAddiInsuredSchedule(jobNumber, sessionUUID, scheduleDto, authHeader = {}) {
        return processSubmission('updateContractorsEquipmentAddiInsuredSchedule', [jobNumber, sessionUUID, scheduleDto], authHeader);
    }

    /**
     * Invokes IMContractorsEquipmentHandler.onContractorsEquipmentNext()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static onContractorsEquipmentNext(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('onContractorsEquipmentNext', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMContractorsEquipmentHandler.searchClauses()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} keyword
     * @param {string} searchType
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static searchClauses(jobID, sessionUUID, keyword, searchType, authHeader = {}) {
        return processSubmission('searchClauses', [jobID, sessionUUID, keyword, searchType], authHeader);
    }

    /**
     * Invokes IMContractorsEquipmentHandler.addSearchedClauses()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} clausesToAddData
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addSearchedClauses(jobID, sessionUUID, clausesToAddData, authHeader = {}) {
        return processSubmission('addSearchedClauses', [jobID, sessionUUID, clausesToAddData], authHeader);
    }

    /**
     * Invokes IMContractorsEquipmentHandler.calculateLimit()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {string} termCode
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static calculateLimit(jobNumber, sessionUUID, termCode, authHeader = {}) {
        return processSubmission('calculateLimit', [jobNumber, sessionUUID, termCode], authHeader);
    }
}