import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import BaseFNOLWizard from './BaseFNOLWizard';
import FNOLSelectPolicy from './pages/FNOLSelectPolicy/FNOLSelectPolicy';
import FNOLSummaryPage from './pages/Summary/SummaryPage';
import FNOLContactDetailsPage from './pages/ContactDetails/ContactDetailsPage';
import FNOLWherePage from './pages/Where/WherePage';
import FNOLAdditionalInformationPage from './pages/AdditionalInformation/AdditionalInformationPage';
import ClaimsConfirmationPage from './pages/ConfirmationPage/ConfirmationPage';
import Claim from './models/Claim';
import Contact from './models/Contact';
import Address from './models/Address';
import FNOLTypeOfIncidentPage from './pages/TypeOfIncident/TypeOfIncidentPage';
import ClaimUtil from './utils/ClaimUtil';
import VehicleDamagePicker from './components/VehicleCollision/VehicleDamagePicker';

setComponentMapOverrides(
    {
        BaseFNOLWizard: { component: 'BaseFNOLWizard' },
        FNOLSelectPolicy: { component: 'FNOLSelectPolicy' },
        FNOLSummaryPage: { component: 'FNOLSummaryPage' },
        FNOLContactDetailsPage: { component: 'FNOLContactDetailsPage' },
        FNOLTypeOfIncidentPage: { component: 'FNOLTypeOfIncidentPage' },
        FNOLWherePage: { component: 'FNOLWherePage' },
        FNOLAdditionalInformationPage: { component: 'FNOLAdditionalInformationPage' },
        ClaimsConfirmationPage: { component: 'ClaimsConfirmationPage' },
    },
    {
        BaseFNOLWizard,
        FNOLSelectPolicy,
        FNOLSummaryPage,
        FNOLContactDetailsPage,
        FNOLTypeOfIncidentPage,
        FNOLWherePage,
        FNOLAdditionalInformationPage,
        ClaimsConfirmationPage,
    }
);

export {
    BaseFNOLWizard as default,
    Claim,
    ClaimsConfirmationPage,
    Contact,
    Address,
    ClaimUtil,
    VehicleDamagePicker
};
export { default as FNOLLossLocation } from './components/LossLocation/LossLocation';
export { default as fnolCommonMessages } from './FNOL.messages';
