import { defineMessages } from 'react-intl';

export default defineMessages({
    paTypeOfIncident: {
        id: 'fnol.pa.views.pa-claim-type.Type of Incident',
        defaultMessage: 'Type of Incident'
    },
    paCollision: {
        id: 'fnol.pa.views.pa-claim-type.Collision',
        defaultMessage: 'Collision'
    },
    paTheft: {
        id: 'fnol.pa.views.pa-claim-type.Theft',
        defaultMessage: 'Theft'
    },
    paBrokenGlass: {
        id: 'fnol.pa.views.pa-claim-type.Broken Glass',
        defaultMessage: 'Broken Glass'
    },
    paOther: {
        id: 'fnol.pa.views.pa-claim-type.Other',
        defaultMessage: 'Other'
    },
    paTypeOfCollisionOccured: {
        id: 'fnol.pa.views.pa-claim-type.What type of collision occurred?',
        defaultMessage: 'What type of collision occurred?'
    },
    repairFacility: {
        id: 'fnol.pa.views.pa-new-body-shop.What is your preferred repair facility?',
        defaultMessage: 'What is your preferred repair facility?',
    },
    repairFacilityName: {
        id: 'fnol.pa.views.pa-service-new-vendor.Repair Facility Name',
        defaultMessage: 'Repair Facility Name',
    },
    city: {
        id: 'fnol.common.directives.policy-search-criteria.City',
        defaultMessage: 'City',
    },
    zipCode: {
        id: 'fnol.common.directives.policy-search-criteria.Zip Code',
        defaultMessage: 'ZIP Code',
    },
    chooseState: {
        id: 'fnol.common.directives.policy-search-criteria.Choose State.-- Choose State --',
        defaultMessage: '-- Choose State --'
    },
    taxId: {
        id: 'fnol.pa.views.pa-service-new-vendor.Tax ID',
        defaultMessage: 'Tax ID',
    },
    newVendorTitle: {
        id: 'fnol.pa.views.pa-service-choice.I’ll provide repair facility details myself',
        defaultMessage: 'I’ll provide repair facility details myself'
    },
    newVendorSubTitle: {
        id: 'fnol.pa.views.pa-service-choice.Select this option if you have your own preferred repair facility.',
        defaultMessage: 'Select this option if you have your own preferred repair facility.'
    },
    headerRepair: {
        id: 'fnol.pa.views.pa-service-choice.Do you need a repair facility?',
        defaultMessage: 'Do you need a repair facility?'
    },
    choiceSubtitle: {
        id: 'fnol.pa.views.pa-service-choice.Lifetime guarantee on all parts and labour',
        defaultMessage: 'Lifetime guarantee on all parts and labour'
    },
    noRepair: {
        id: 'fnol.pa.views.pa-service-choice.I don’t need a repair facility',
        defaultMessage: 'I don’t need a repair facility'
    },
    noRepairSubTitle: {
        id: 'fnol.pa.views.pa-service-choice.Don’t worry, you can request service later from an adjuster.',
        defaultMessage: 'Don’t worry, you can request service later from an adjuster.'
    },
    recommendRepairFacility: {
        id: 'fnol.pa.views.pa-service-choice.I want to use a recommended repair facility',
        defaultMessage: 'I want to use a recommended repair facility'
    },
    recommendedFacilityYouGet: {
        id: 'fnol.pa.views.pa-service-choice.With a recommended repair facility you get',
        defaultMessage: 'With a recommended repair facility you get:'
    },
    lifetimeGuarantee: {
        id: 'fnol.pa.views.pa-service-choice.Lifetime guarantee on all parts and labour',
        defaultMessage: 'Lifetime guarantee on all parts and labour'
    },
    discountOnOtherRepairs: {
        id: 'fnol.pa.views.pa-service-choice.10% discount on other repairs',
        defaultMessage: '10% discount on other repairs'
    },
    promptRepairService: {
        id: 'fnol.pa.views.pa-service-choice.Prompt, high quality repair service',
        defaultMessage: 'Prompt, high quality repair service'
    },
    paCollisionDetails: {
        id: 'fnol.wizard.steps.pa.CollisionDetails',
        defaultMessage: 'Details'
    },
    paGlassDetails: {
        id: 'fnol.wizard.steps.pa.GlassDetails',
        defaultMessage: 'Damage'
    },
    paTheftDetails: {
        id: 'fnol.wizard.steps.pa.TheftDetails',
        defaultMessage: 'Details'
    },
    paWhatTypeOfIncident: {
        id: 'fnol.wizard.steps.pa.Type of Incident',
        defaultMessage: 'Type of Incident'
    },
    paVehicle: {
        id: 'fnol.wizard.steps.pa.Vehicles',
        defaultMessage: 'Vehicles'
    },
    paClaimAboutTheVehicle: {
        id: 'fnol.pa.directives.vehicle-incident.About the Vehicle',
        defaultMessage: 'About the Vehicle'
    },
    paClaimWhichVehicle: {
        id: 'fnol.pa.directives.vehicle-incident.Which vehicle was damaged?',
        defaultMessage: 'Which vehicle was damaged?'
    },
    paClaimChooseVehicle: {
        id: 'fnol.pa.directives.vehicle-incident.Choose Vehicle',
        defaultMessage: 'Choose Vehicle'
    },
    paClaimsWhoDriving: {
        id: 'fnol.pa.directives.vehicle-incident.Who was driving?',
        defaultMessage: 'Who was driving?'
    },
    paClaimChooseDriver: {
        id: 'fnol.pa.directives.vehicle-incident.Choose Driver',
        defaultMessage: 'Choose Driver'
    },
    paClaimVehicleSafeToDrive: {
        id: 'fnol.pa.directives.vehicle-incident.Is this vehicle safe to drive?',
        defaultMessage: 'Is this vehicle safe to drive?'
    },
    paClaimYes: {
        id: 'fnol.pa.directives.vehicle-incident.Yes',
        defaultMessage: 'Yes'
    },
    paClaimNo: {
        id: 'fnol.pa.directives.vehicle-incident.No',
        defaultMessage: 'No'
    },
    paClaimDidAirbagDeploy: {
        id: 'fnol.pa.directives.vehicle-incident.Did the airbag deploy?',
        defaultMessage: 'Did the airbag deploy?'
    },
    paClaimEquipmentFailure: {
        id: 'fnol.pa.directives.vehicle-incident.Was there equipment failure?',
        defaultMessage: 'Was there equipment failure?'
    },
    paClaimWasVehicleTowed: {
        id: 'fnol.pa.directives.vehicle-incident.Was the vehicle towed?',
        defaultMessage: 'Was the vehicle towed?'
    },
    paClaimWasRentalNeeded: {
        id: 'fnol.pa.directives.vehicle-incident.Is a rental car needed?',
        defaultMessage: 'Is a rental car needed?'
    },
    paClaimPointOfImpact: {
        id: 'fnol.pa.directives.vehicle-collision.Point of Impact',
        defaultMessage: 'Point of Impact'
    },
    paClaimPassengers: {
        id: 'fnol.pa.directives.vehicle-incident.Passengers',
        defaultMessage: 'Passengers'
    },
    paClaimAddAPassenger: {
        id: 'fnol.pa.directives.vehicle-incident.Add a Passenger',
        defaultMessage: 'Add a Passenger'
    },
    paClaimAnotherPerson: {
        id: 'fnol.pa.directives.vehicle-incident.Another person',
        defaultMessage: 'Another person'
    },
    paClaimAddVehicle: {
        id: 'fnol.pa.directives.vehicle-incident.Add Another Vehicle',
        defaultMessage: 'Add Another Vehicle'
    },
    paClaimPassengerFirstName: {
        id: 'fnol.pa.directives.vehicle-incident.First name',
        defaultMessage: 'First name'
    },
    paClaimPassengerLastName: {
        id: 'fnol.pa.directives.vehicle-incident.Last name',
        defaultMessage: 'Last name'
    },
    paClaimPassengerPhoneNumber: {
        id: 'fnol.pa.directives.vehicle-incident.Phone number',
        defaultMessage: 'Phone number (optional)'
    },
    paClaimPassengerEdit: {
        id: 'fnol.pa.directives.vehicle-incident.Remove',
        defaultMessage: 'Remove'
    },
    paClaimPassengerDelete: {
        id: 'fnol.pa.directives.vehicle-incident.Delete',
        defaultMessage: 'Delete'
    },
    wizardPARepair: {
        id: 'fnol.wizard.steps.pa.Repair',
        defaultMessage: 'Repair'
    },
    wizardPARepairFacility: {
        id: 'fnol.wizard.steps.pa.Repair Facility',
        defaultMessage: 'Repair Facility'
    },
    paVehicleOtherDriver: {
        id: 'fnol.pa.directives.vehicle-incident.Other Driver',
        defaultMessage: 'Other Driver'
    },
    paVehicleOtherVehicle: {
        id: 'fnol.pa.directives.vehicle-incident.Other Vehicle',
        defaultMessage: 'Other Vehicle'
    },
    paVehicleNewVehicle: {
        id: 'fnol.pa.directives.vehicle-incident.New Vehicle',
        defaultMessage: 'New Vehicle'
    },
    paVehicleColor: {
        id: 'fnol.pa.directives.add-new-vehicle.Vehicle Color',
        defaultMessage: 'Vehicle Color'
    },
    paVehicleLicensePlate: {
        id: 'fnol.pa.directives.add-new-vehicle.License Plate',
        defaultMessage: 'License Plate Number'
    },
    paVehicleMake: {
        id: 'fnol.pa.directives.add-new-vehicle.Make',
        defaultMessage: 'Make'
    },
    paVehicleManufacturer: {
        id: 'fnol.pa.directives.add-new-vehicle.Choose Vehicle Manufacturer',
        defaultMessage: 'Choose Vehicle Manufacturer'
    },
    paVehicleModel: {
        id: 'fnol.pa.directives.add-new-vehicle.Model',
        defaultMessage: 'Model'
    },
    paVehicleState: {
        id: 'fnol.pa.directives.add-new-vehicle.State',
        defaultMessage: 'State'
    },
    paVehicleChooseState: {
        id: 'fnol.pa.directives.add-new-vehicle.Choose Vehicle State',
        defaultMessage: 'Choose Vehicle State'
    },
    paVehicleVIN: {
        id: 'fnol.pa.directives.add-new-vehicle.VIN',
        defaultMessage: 'VIN'
    },
    paVehicleYear: {
        id: 'fnol.pa.directives.add-new-vehicle.Year',
        defaultMessage: 'Year'
    },
    paVehicleChooseYear: {
        id: 'fnol.pa.directives.add-new-vehicle.Choose Vehicle Year',
        defaultMessage: 'Choose Vehicle Year'
    },
    paVehicleDriverFirstName: {
        id: 'fnol.pa.directives.add-new-driver.First Name',
        defaultMessage: 'First Name'
    },
    paVehicleDriverLastName: {
        id: 'fnol.pa.directives.add-new-driver.Last Name',
        defaultMessage: 'Last Name'
    },
    paVehicleDriverHomePhone: {
        id: 'fnol.pa.directives.add-new-driver.Home Phone',
        defaultMessage: 'Home Phone'
    },
    paVehicleDriverMobilePhone: {
        id: 'fnol.pa.directives.add-new-driver.Mobile Phone',
        defaultMessage: 'Mobile Phone'
    },
    paVehicleValidPhoneNumber: {
        id: 'fnol.pa.directives.add-new-driver.Value must be a valid phone number',
        defaultMessage: 'Value must be a valid phone number'
    },
    paCreateDraftClaim: {
        id: 'fnol.pa.config.FlowConfig.Creating personal auto draft claim...',
        defaultMessage: 'Creating personal auto draft claim...'
    },
    paCreateDraftErrorTitle: {
        id: 'fnol.pa.config.FlowConfig.Error creating claim',
        defaultMessage: 'Error creating claim'
    },
    paCreateDraftErrorMessage: {
        id: 'fnol.pa.config.FlowConfig.An error occurred attempting to create personal auto claim.',
        defaultMessage: 'An error occurred attempting to create personal auto claim.'
    },
    paVehicleNewDriver: {
        id: 'fnol.pa.directives.vehicle-incident.New Driver',
        defaultMessage: 'New Driver'
    },
    paRepairChoiceVehicleDamaged: {
        id: 'fnol.pa.views.pa-repair-choice.Which vehicle you want to repair?',
        defaultMessage: 'Which vehicle you want to repair?'
    },
    paRepairChoiceChooseVehicle: {
        id: 'fnol.pa.views.pa-repair-choice.Choose Vehicle',
        defaultMessage: 'Choose Vehicle'
    },
    optional: {
        id: 'fnol.pa.directives.add-new-driver.optional',
        defaultMessage: 'optional'
    }
});
