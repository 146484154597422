import React, {useState, useCallback, useMemo, useEffect} from 'react'
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal } from 'wni-components-platform-react';
import { useTranslator } from '@jutro/locale';
import {
    QuoteUtil,
    ErrorsAndWarningsUtil,
} from 'wni-portals-util-js';
import { PortalConstants } from 'wni-portals-config-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { IMElectronicDataProcessingService as  ElectronicDataProcessingService} from 'wni-capability-quoteandbind-im';
import CoveragesConfigContext from '../../context/IMCoveragesConfigContext';
import IMCoveragesConfig from '../../config/IMCoverages.config';
import metadata from './ElectronicDataProcessingPage.metadata.json5';
import IMSearchAndAddClausesPopup from '../../components/IMSearchAndAddClausesPopup/IMSearchAndAddClausesPopup';
import WizardPage from '../../templates/IMWizardPage';
import IMCommonMainCoverage from '../../components/IMCommonMainCoverage/MainCoveragesCard';
import IMCommonCovPartSchedule from '../../components/IMComonCovPartSchedule/CommonCovPartSchedule';
import IMCommonAdditionalCovCard from '../../components/IMCommonAdditionalCovCard/IMCommonAdditionalCovCard';
import IMExclusionAccordionCard from '../../components/IMCommonExclusionCard/IMCommonExclusionCard';
import LineMainCoveragesComponent from './LineMainCoveragesCard/LineMainCoveragesComponent';
import messages from './ElectronicDataProcessingPage.messages';

const EDP_COVERAGE__ACCORDION_CARD_COMPONENT_MAPS = ['topCoverageMainCovsCard', 'leftCoverageMainCovsCard', 'rightCoverageMainCovsCard', 'incomeLeftCoverageMainCovsCard', 'incomeRightCoverageMainCovsCard'];
const ElectronicDataProcessingPage = (props) => {
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData,
    } = props;

    const { initialValidation, onValidate, isComponentValid, invalidFields } = useValidation('ElectronicDataProcessingPage');
    const { authHeader } = useAuthentication();
    const translator = useTranslator();
    const [validationIssues, updateValidationIssues] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [electronicDataProcessingClauses, setElectronicDataProcessingClauses] = useState();
    const [electronicDataProcessingLoadingClauses, setElectronicDataProcessingLoadingClauses] = useState();

    const {
        jobID,
        sessionUUID,
        baseData: { 
            periodStatus
        }
    } = submissionVM.value

    const {
        loadingMask: { isLoadingMask, setLoadingMask },
    } = useDependencies('loadingMask');

    const pageLevelValidation = useMemo(
        () => validationIssues,
        [
            validationIssues
        ]
    );

    // Middle layer introduced for the convenience of debugging
    const updateErrorsAndWarningsForCovComponent = (errorsAndWarnings) => {
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        updateValidationIssues(newValidationIssues);
    }

    const initCoverablePartClauses = useCallback(async () => {
        setLoadingMask(true)
        const isDraftPeriod = periodStatus ===  PortalConstants.QUOTE_STATUS_DRAFT

        const res = isDraftPeriod ? await ElectronicDataProcessingService.getElectronicDataProcessingClausesWithSync(jobID, sessionUUID, authHeader) 
            : await ElectronicDataProcessingService.getElectronicDataProcessingClauses(jobID, sessionUUID, authHeader)
        const {
            errorsAndWarnings,
        } = res
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        setElectronicDataProcessingClauses(res)
        setLoadingMask(false)
    }, [authHeader, jobID, periodStatus, sessionUUID, setLoadingMask]) 

    useEffect(() => {
        initCoverablePartClauses()
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const showClausesModal = useCallback((clauseType) => {
        const componentProps = {
            showCloseBtn: false,
            showCancelBtn: false,
            serviceProps: {
                jobID,
                sessionUUID,
                authHeader
            },
            setLoadingMask,
            clauseType,
            coverableService: ElectronicDataProcessingService
        };
        return modalApi.showModal(
            <IMSearchAndAddClausesPopup {...componentProps} />
        );
    }, [authHeader, jobID, modalApi, sessionUUID, setLoadingMask]);

    const onClickAddSearchClauses = useCallback(async (clauseType) => {
        const data = await showClausesModal(clauseType)
        setElectronicDataProcessingClauses(data)
    },[showClausesModal])

    const setWizardDataToDraft = useCallback((newSubmissionVM) => {
        // Refactoring Notice: Consider to extract this into a common method
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        return newSubmissionVM
    }, []);

    const updateWizardDataWhileSetPeriodStatus = (newSubmissionVM) => {
        setWizardDataToDraft(newSubmissionVM)
        updateWizardData(newSubmissionVM)
    } 

    const onPageNext = async () => {
        setLoadingMask(true)
        const errorsAndWarnings = await ElectronicDataProcessingService.onElectronicDataProcessingNext(jobID, sessionUUID, authHeader)      
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        const hasErrorIssues = newValidationIssues.some((issue) => {
            return issue.type === 'error';
        });
        const hasNewErrorsOrWarnings = ErrorsAndWarningsUtil.hasNewValidationIssue(validationIssues, newValidationIssues)
        if (hasErrorIssues || hasNewErrorsOrWarnings) {
            return false;
        }
        setLoadingMask(false)
        return submissionVM
    }

    const handleError = () => {
        setShowErrors(true)
        return false
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const accordionCommonProps = {
            coveragePartClauses: electronicDataProcessingClauses,
            setCoveragePartClauses: setElectronicDataProcessingClauses,
            isEditing,
            setIsEditing,
            isEditable: true,
            showErrors,
            onValidate: onValidate,
            loadingClauses: electronicDataProcessingLoadingClauses,
            setLoadingClauses: setElectronicDataProcessingLoadingClauses,
            onClickAddSearchClauses,
            wizardData: submissionVM,
            updateWizardDataWhileSetPeriodStatus,
            updateCoverageFormClausesService: ElectronicDataProcessingService.updateElectronicDataProcessingClauses
        }

    const updateCoinsuranceHardwareMediaProgramsand = useCallback(async (value) => {
        const coinsuranceHardwareMediaProgramsand = _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.coinsuranceHardwareMediaProgramsand')
        const currentValue = _.get(coinsuranceHardwareMediaProgramsand, 'chosenTerm');
        if (value !== currentValue) {
            _.set(coinsuranceHardwareMediaProgramsand, 'chosenTerm', value);
            _.set(coinsuranceHardwareMediaProgramsand, 'updated', true);
            const toUpdate = {
                electronicDataProcessingCov: {
                    coinsurancePart: {
                        coinsuranceHardwareMediaProgramsand: coinsuranceHardwareMediaProgramsand
                    }
                }
            }
            setLoadingMask(true);
            const rs = await ElectronicDataProcessingService.updateElectronicDataProcessingClauses(jobID, sessionUUID, toUpdate, authHeader);
            setLoadingMask(false);
            setElectronicDataProcessingClauses(rs);
        }
    }, [authHeader, electronicDataProcessingClauses, jobID, sessionUUID, setLoadingMask]);

    const updateCoinsuranceDataRecordsProprietary = useCallback(async (value) => {
        const coinsuranceDataRecordsProprietary = _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.coinsuranceDataRecordsProprietary');
        const currentValue = _.get(coinsuranceDataRecordsProprietary, 'chosenTerm');
        if (value !== currentValue) {
            _.set(coinsuranceDataRecordsProprietary, 'chosenTerm', value);
            _.set(coinsuranceDataRecordsProprietary, 'updated', true);
            const toUpdate = {
                electronicDataProcessingCov: {
                    coinsurancePart: {
                        coinsuranceDataRecordsProprietary: coinsuranceDataRecordsProprietary
                    }
                }
            }
            setLoadingMask(true);
            const rs = await ElectronicDataProcessingService.updateElectronicDataProcessingClauses(jobID, sessionUUID, toUpdate, authHeader);
            setLoadingMask(false);
            setElectronicDataProcessingClauses(rs)
        }
    }, [authHeader, electronicDataProcessingClauses, jobID, sessionUUID, setLoadingMask]);

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                isEditable: true,
            },
            electronicDataProcessingCoverage: {
                errorState: !_.isEmpty(_.intersection(invalidFields, EDP_COVERAGE__ACCORDION_CARD_COMPONENT_MAPS))
            },
            topCoverageMainCovsCard: {
                ...accordionCommonProps,
                coveragePatterns: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.topCoverage'),
                coverageFormPath: 'electronicDataProcessingCov.topCoverage'
            },
            leftCoverageMainCovsCard: {
                ...accordionCommonProps,
                coveragePatterns: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.leftCoverage'),
                coverageFormPath: 'electronicDataProcessingCov.leftCoverage'
            },
            rightCoverageMainCovsCard: {
                ...accordionCommonProps,
                coveragePatterns: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.rightCoverage'),
                coverageFormPath: 'electronicDataProcessingCov.rightCoverage'
            },
            incomeLeftCoverageMainCovsCard: {
                ...accordionCommonProps,
                coveragePatterns: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.incomeLeftCoverage'),
                coverageFormPath: 'electronicDataProcessingCov.incomeLeftCoverage'
            },
            incomeRightCoverageMainCovsCard: {
                ...accordionCommonProps,
                coveragePatterns: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.incomeRightCoverage'),
                coverageFormPath: 'electronicDataProcessingCov.incomeRightCoverage'
            },
            coinsuranceHardwareMediaProgramsand: {
                dataType: 'string',
                required: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.coinsuranceHardwareMediaProgramsand.required'),
                showRequired: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.coinsuranceHardwareMediaProgramsand.required'),
                availableValues: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.coinsuranceHardwareMediaProgramsand.options'),
                value: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.coinsuranceHardwareMediaProgramsand.chosenTerm'),
                onValueChange: updateCoinsuranceHardwareMediaProgramsand,
                showErrors: showErrors,
                label: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.coinsuranceHardwareMediaProgramsand.name'),
            },
            coinsuranceDataRecordsProprietary: {
                dataType: 'string',
                required: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.coinsuranceDataRecordsProprietary.required'),
                showRequired: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.coinsuranceDataRecordsProprietary.required'),
                availableValues: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.coinsuranceDataRecordsProprietary.options'),
                value: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.coinsuranceDataRecordsProprietary.chosenTerm'),
                onValueChange: updateCoinsuranceDataRecordsProprietary,
                showErrors: showErrors,
                label: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.coinsuranceDataRecordsProprietary.name'),
                visible: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.coinsuranceDataRecordsProprietaryVisible'),
            },
            coinsurancePart7200ScheduleCovCard: {
                ...accordionCommonProps,
                coveragePartSchedule: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.electronicDataProcessingScheduledCovIM7200'),
                updateScheduleService: ElectronicDataProcessingService.updateElectronicDataProcessing7200Schedule,
                schedulePath: 'electronicDataProcessingCov.coinsurancePart.electronicDataProcessingScheduledCovIM7200',
                scheduleLable: translator(messages.ListOfScheduleItems),
                scheduleFormHeader: translator(messages.ScheduleDetails),
                visible: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.electronicDataProcessingScheduledCovIM7200Visible'),
            },
            coinsurancePart7201ScheduleCovCard: {
                ...accordionCommonProps,
                coveragePartSchedule: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.electronicDataProcessingScheduledCovIM7201'),
                updateScheduleService: ElectronicDataProcessingService.updateElectronicDataProcessing7201Schedule,
                schedulePath: 'electronicDataProcessingCov.coinsurancePart.electronicDataProcessingScheduledCovIM7201',
                scheduleLable: translator(messages.ListOfScheduleItems),
                scheduleFormHeader: translator(messages.ScheduleDetails),
                visible: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.electronicDataProcessingScheduledCovIM7201Visible'),
            },
            additionalAccordionCard: {
                ...accordionCommonProps,
                coveragePartName: 'electronicDataProcessing'
            },
            exclusionAccordionCard: {
                ...accordionCommonProps,
                coveragePartName: 'electronicDataProcessing'
            }
        }
    }, [accordionCommonProps, electronicDataProcessingClauses, invalidFields, showErrors, translator, updateCoinsuranceDataRecordsProprietary, updateCoinsuranceHardwareMediaProgramsand]);

    
    const overrideProps = generateOverrides();
    const resolvers = {
        callbackMap: {
        },
        componentMap: {
            topCoverageMainCovsCard: LineMainCoveragesComponent,
            leftCoverageMainCovsCard: LineMainCoveragesComponent,
            // leftCoverageEquipmentScheduleCovCard: IMCommonCovPartSchedule,
            // leftCoverageAdditionalInsuredScheduleCovCard: IMCommonCovPartSchedule,
            rightCoverageMainCovsCard: LineMainCoveragesComponent,
            incomeLeftCoverageMainCovsCard: LineMainCoveragesComponent,
            incomeRightCoverageMainCovsCard: LineMainCoveragesComponent,
            AdditionalAccordionCard: IMCommonAdditionalCovCard,
            ExclusionAccordionCard: IMExclusionAccordionCard,
            coinsurancePart7200ScheduleCovCard: IMCommonCovPartSchedule,
            coinsurancePart7201ScheduleCovCard: IMCommonCovPartSchedule,
            // coinsuranceHardwareMediaProgramsand: IMCommonMainCoverage
        },
    };

    const disableNavigator = isEditing || isLoadingMask || electronicDataProcessingLoadingClauses

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            disableNext={disableNavigator}
            disablePrevious={disableNavigator}
            disableCancel={disableNavigator}
            onNext={isComponentValid ? onPageNext : handleError}
            alwaysCallOnNext
            showRequiredInfoForFasterQuote
            pageLevelValidationIssues={pageLevelValidation}
        >
            {electronicDataProcessingClauses && 
                <CoveragesConfigContext.Provider value={IMCoveragesConfig}>
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        showErrors={showErrors}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.callbackMap}
                        componentMap={resolvers.componentMap}
                    />
                </CoveragesConfigContext.Provider>
            }
        </WizardPage>
    );
}

export default ElectronicDataProcessingPage
