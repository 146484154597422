import React, { useCallback, useEffect } from 'react';
import _ from 'lodash';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { getFieldModel, isYearValid, isNumberValid } from './FieldSetModel.util'

const YEAR_VALIDATE_MAP = ['ca7_year_ext', 'year_ext']
const FieldSetModel = (props) => {
    const {
        className,
        readOnly,
        onValueChange,
        onBlur,
        fieldItem,
        onValidate,
        availableValues,
        onSearch = _.noop,
        actionVisible = !readOnly,
        additionalValidationFn,
        additionalValidationMessages
    } = props;

    const isAdditionalValid = !additionalValidationMessages || additionalValidationMessages.length < 1;
    const {
        label,
        required,
        inputSetMode,
        propertyName,
        rangeValueOptions = [],
    } = fieldItem || {};
    const fieldModel = getFieldModel(inputSetMode);

    const {
        onValidate: onValidationChange, 
        isComponentValid,
        registerComponentValidation
    } = useValidation(propertyName);

    const registerFieldValidation = useCallback(() => {
        if(additionalValidationFn) {
            return additionalValidationFn()
        }
        if(YEAR_VALIDATE_MAP.includes(inputSetMode)){
            return isYearValid(fieldItem[fieldModel.value], fieldItem)
        }
        const isValid = isNumberValid(fieldItem[fieldModel.value], fieldItem);
        return isValid;
    }, [additionalValidationFn, fieldItem, fieldModel, inputSetMode])

    useEffect(() => {
        registerComponentValidation(registerFieldValidation)
    }, [registerFieldValidation, registerComponentValidation]);
   

    const initAvailableOptions = availableValues || rangeValueOptions;
    const initAvailableValues = initAvailableOptions.map((option) => {
        return {
            code: option,
            name: option,
        };
    });

    useEffect(() => {
        if (onValidate && !readOnly) {
            onValidate(isComponentValid, propertyName);
        }
        return () => {
            if (onValidate) {
                onValidate(true, propertyName);
            }
        }
    }, [onValidate, isComponentValid, propertyName, readOnly])

    const handleValueChange = (newValue) => {
        if(newValue === fieldItem[fieldModel.value]) {
            return false;
        }
        const newDsiplayable = {
            ...fieldItem,
            updated: true,
            isValid: !_.isNil(newValue) || (_.isArray(newValue) && newValue.length !== 0),
            [fieldModel.value]: newValue
        }
        onValueChange(newDsiplayable, fieldModel)
    };

    const handleBlur = (e, valueChanges) => {
        const { beforeValue, value } = valueChanges;
        if(value === beforeValue) {
            return false;
        }
        if(YEAR_VALIDATE_MAP.includes(inputSetMode) && !isYearValid(value, fieldItem)) {
            return false;
        }
        if(!isNumberValid(value, fieldItem)) {
            return false;
        }
        onBlur(value, fieldModel)
    };

    const getFieldProps = () => {
        const defaultFieldProps = _.get(fieldModel, 'defaultFieldProps', {});
        if(fieldItem.minValue) {
            _.set(defaultFieldProps, 'minValue', fieldItem.minValue)
        }
        if(fieldItem.maxValue) {
            _.set(defaultFieldProps, 'maxValue', fieldItem.maxValue)
        }
        return defaultFieldProps;
    };
    const extendedAsteriskFields = [
        'ca7_year_asterisk_ext', 
        'dynamicrange_asterisk_ext',
        'range_asterisk_ext',
        'ca7_classcode_asterisk_ext'
    ]
    const renderMetadata = () => {
        let dom;
        const newProps = _.omit(props, ['onSearch', 'additionalValidationFn', 'additionalValidationMessages']);
        const newFieldProps = {
            ...newProps,
            label,
            required: extendedAsteriskFields.indexOf(inputSetMode||'')>-1 || required,
            availableValues: initAvailableValues,
            dataType: fieldModel.datatype,
            value: fieldItem[fieldModel.value],
            onValueChange: handleValueChange,
            onValidationChange: onValidationChange,
            onBlur: fieldModel.triggerFunc === 'onBlur' ? handleBlur : _.noop,
            ...getFieldProps(),
        };
        switch (inputSetMode) {
            case 'label':
                dom = {
                    id: propertyName,
                    type: 'element',
                    component: 'h5',
                    componentProps: {
                        className: 'font-NeutralUltraDark-bold-16 ph-10'
                    },
                    content: fieldItem.label
                };
                break;
            case 'ca7_classcode_ext':
            case 'searchablerange':
                dom = {
                    id: propertyName,
                    type: 'field',
                    component: fieldModel.component,
                    componentProps: {
                        ...newFieldProps,
                        className: `${className} searchFieldWithButtonFilled`,
                        actionFn: () => onSearch(fieldItem),
                        actionVisible: actionVisible,
                        actionConfig: {
                            id: `${propertyName}Action`,
                            text: "Search",
                            type: "filled"
                        },
                    }
                };
                break;
            default:
                const initValidationMessages = !isAdditionalValid ? {
                    validationMessages: additionalValidationMessages
                } : {}
                dom = {
                    id: propertyName,
                    type: 'field',
                    component: fieldModel.component,
                    componentProps: {
                        ...newFieldProps,
                        ...initValidationMessages
                    },
                };
                break;
        }
        return [dom]
    };

    const overrides = {};

    const resolvers = {};

    return (
        <MetadataContent
            uiProps={renderMetadata()}
            overrideProps={overrides}
            {...resolvers}
        />
    );
};

export default FieldSetModel;
