import _ from 'lodash';

export default class CPClaimExtension {
    constructor(claim) {
        Object.defineProperty(this, 'claim', {
            enumerable: false,
            value: claim
        });

        this.incidentReport = this.claim.lobs.commercialProperty
            && _.isBoolean(this.claim.lobs.commercialProperty.incidentReport)
            ? this.claim.lobs.commercialProperty.incidentReport
            : false;
        this.dateOfNotice = this.claim.lobs.commercialProperty
            && this.claim.lobs.commercialProperty.dateOfNotice
            ? this.claim.lobs.commercialProperty.dateOfNotice
            : null;
        this.fixedPropertyIncidents = this.claim.lobs.commercialProperty
        && this.claim.lobs.commercialProperty.fixedPropertyIncidents
            ? this.claim.lobs.commercialProperty.fixedPropertyIncidents
            : null;
    }
}
