import moment from 'moment';
import _ from 'lodash';
import { WniDateUtil, WniFormat } from 'wni-portals-util-js';

/**
 * validate routing number
 * Example: "a15b2223M997" is a valid routing number
 * Rules:
 * ABA(American Bank Association) routing number has 9 numbers. (There maybe other A-Z character mixed but the number count must be 9.)
 * Remove any non-numeric characters and lets say the first 2 digits of the routing is XX, XX must be in the range of 01<=XX<=15 or 21<=XX<=32 or 61<=XX<=72.
 * Lets say the 9-digit ABA routing number to be ABCDEFGHI, sum the digits with (A+D+G)*3 + (B+E+H)*7 + (C+F+I) and mod it by 10, if the remainder = 0, then it’s a valid number; otherwise its not a valid number.
 * @param {string} routingNumber Routing (ABA) Number
 * @returns {boolean} if the number is validated
 */
// const filterDigitsReg = new RegExp('[^0-9]', 'g');
// const has9DigitsReg = new RegExp(/^\d{9}$/);


export default {
    validateRoutingNumber: (routingNumber) => {
        const filterDigitsReg = new RegExp('[^0-9]', 'g');
        const has9DigitsReg = new RegExp(/^\d{9}$/);
        const newRoutingNumber = routingNumber.replace(filterDigitsReg, '');
        const all9Digits = has9DigitsReg.test(newRoutingNumber);
        if (!all9Digits) {
            return false;
        }
        const first2digits = Number.parseInt(newRoutingNumber.substring(0, 2), 10);
        if (!(first2digits >= 1 && first2digits <= 15)
            && !(first2digits >= 21 && first2digits <= 32)
            && !(first2digits >= 61 && first2digits <= 72)) {
            return false;
        }
    
        /*
           Lets say the 9-digit ABA routing number to be ABCDEFGHI,
           sum the digits with (A+D+G)*3 + (B+E+H)*7 + (C+F+I) and mod it by 10,
           if the remainder = 0, then it’s a valid number;
           otherwise its not a valid number.
           ABCDEFGHI
           012345678
        */
        const numberList = Array.from(newRoutingNumber).map((char) => Number.parseInt(char, 10));
        const checksum = (numberList[0] + numberList[3] + numberList[6]) * 3
            + (numberList[1] + numberList[4] + numberList[7]) * 7
            + (numberList[2] + numberList[5] + numberList[8]);
        return checksum % 10 === 0;
    },
    
    get5DaysInPast: () => {
        return moment().subtract(5, 'd').startOf('day').toDate();
    },

    getNextYear: () => {
        return moment().add(1, 'y').format("YYYY");
    },
    
    isAmountInteger: (value) => {
        return _.isSafeInteger(value.amount) && value.amount > 0;
    },
    
    isAmountPositive: (value) => {
        return value.amount >= 0;
    },
    
    compareWithMinDateByQuoteFlow: (periodStart, quoteFlowExt) => {
        return WniDateUtil.compareWithMinDateByQuoteFlow(
            periodStart,
            quoteFlowExt
        );
    },

    validationNoNegativesForInt: (value) => {
        return value >= 0
    },

    validationNoNegativesForDecimal: (value) => {
        return value >= 0
    },

    isPostalCodePatternMatchesByCountry: (aCountry, postalCode) => {
        return WniFormat.isZipCodeMatchPattern(_.get(aCountry, 'code'), postalCode)
    },

    ArrayContains: (strArray, str ) => {
        return strArray?.includes(str);
    },
    typekeyContains: (strArray, str ) => {
        let newStr = str;
        if(_.isObject(str)) {
            newStr = str.code
        }
        return strArray?.includes(newStr);
    },
    NonEmptyArray: (arr) => {
        return arr?.length > 0
    }
}