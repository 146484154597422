import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import FNOLWCWizard from './FNOLWCWizard';
import WCInjuryPage from './pages/Injury/InjuryPage';
import WCWhatPage from './pages/What/WhatPage';

setComponentMapOverrides(
    {
        FNOLWCWizard: { component: 'FNOLWCWizard' },
        WCWhatPage: { component: 'WCWhatPage' },
        WCInjuryPage: { component: 'WCInjuryPage' }
    },
    {
        FNOLWCWizard,
        WCWhatPage,
        WCInjuryPage
    }
);

export {
    FNOLWCWizard as default
};
