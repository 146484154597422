import React from 'react';
// import { Route, Switch, Redirect } from 'react-router-dom';
import _ from 'lodash';
import BaseFNOLWizard, { ClaimsConfirmationPage } from 'gw-capability-fnol-common-react';
import { ContactAgentPage, Preferences } from 'gw-pages-platform-react';
import FNOLHOWizard from 'gw-capability-fnol-ho-react';
import FNOLPAWizard from 'gw-capability-fnol-pa-react';
import FNOLCAWizard from 'gw-capability-fnol-ca-react';
import FNOLCPWizard from 'gw-capability-fnol-cp-react';
import FNOLWCWizard from 'gw-capability-fnol-wc-react';
import FNOLBOPWizard from 'gw-capability-fnol-bop-react';
import FNOLGeneralWizard from 'gw-capability-fnol-gl-react';
import { PASubmissionWizard, PaySuccessPageByBCC } from 'gw-capability-gateway-quoteandbind-pa-react';
import { HOSubmissionWizard } from 'wni-capability-quoteandbind-ho-react';
import { DPSubmissionWizard } from 'wni-capability-quoteandbind-dp-react';
// QUOTEANDBIND ROUTING LOB START Dummy
import { DummySubmissionWizard } from 'wni-capability-quoteandbind-dummy-react';
// QUOTEANDBIND ROUTING LOB END Dummy
import { WALSubmissionWizard } from 'wni-capability-quoteandbind-wal-react';
import { RTSubmissionWizard } from 'wni-capability-quoteandbind-rt-react';
import { PEHOWizard } from 'gw-capability-gateway-quoteandbind-ho-react';
import { PEBOPWizard } from 'gw-capability-gateway-quoteandbind-bop-react';
import { PECPWizard } from 'gw-capability-gateway-quoteandbind-cp-react';
import { PAPolicyChangeWizard } from 'gw-capability-policychange-pa-react';
import { HOPolicyChangeWizard } from 'wni-capability-policychange-ho-react';
import { DPPolicyChangeWizard } from 'wni-capability-policychange-dp-react';
// POLICYCHANGE ROUTING LOB START Dummy
import { DummyPolicyChangeWizard } from 'wni-capability-policychange-dummy-react';
// POLICYCHANGE ROUTING LOB END Dummy
import { WALPolicyChangeWizard } from 'wni-capability-policychange-wal-react';
import { RTPolicyChangeWizard } from 'wni-capability-policychange-rt-react';
// import { CPPolicyChangeWizard } from 'gw-capability-gateway-policychange-cp-react';
import { BOPPolicyChangeWizard } from 'gw-capability-gateway-policychange-bop-react';
import { CPPolicyRenewalWizard } from 'gw-capability-gateway-policyrenewal-cp-react';
import { BOPPolicyRenewalWizard } from 'gw-capability-gateway-policyrenewal-bop-react';


// QUOTEANDBIND ROUTING LOB START CA
import { CASubmissionWizard } from 'wni-capability-quoteandbind-ca-react';
// QUOTEANDBIND ROUTING LOB END CA

// QUOTEANDBIND ROUTING LOB START WC
import { WCSubmissionWizard } from 'wni-capability-quoteandbind-wc-react';
// QUOTEANDBIND ROUTING LOB END WC
// QUOTEANDBIND ROUTING LOB START PU
import { PUSubmissionWizard } from 'wni-capability-quoteandbind-pu-react';
// QUOTEANDBIND ROUTING LOB END PU
// QUOTEANDBIND ROUTING LOB START GL
import { GLSubmissionWizard } from 'wni-capability-quoteandbind-gl-react';
// QUOTEANDBIND ROUTING LOB END GL
// QUOTEANDBIND ROUTING LOB START CP
import { CPSubmissionWizard } from 'wni-capability-quoteandbind-cp-react';
// QUOTEANDBIND ROUTING LOB END CP
// QUOTEANDBIND ROUTING LOB START IM
import { IMSubmissionWizard } from 'wni-capability-quoteandbind-im-react';
// QUOTEANDBIND ROUTING LOB END IM
// QUOTEANDBIND ROUTING LOB START CPP
import { CPPSubmissionWizard } from 'wni-capability-quoteandbind-cpp-react';
// QUOTEANDBIND ROUTING LOB END CPP
// QUOTEANDBIND ROUTING LOB START CR
import { CRSubmissionWizard } from 'wni-capability-quoteandbind-cr-react';
// QUOTEANDBIND ROUTING LOB END CR
// QUOTEANDBIND ROUTING LOB START CU
import { CUSubmissionWizard } from 'wni-capability-quoteandbind-cu-react';
// QUOTEANDBIND ROUTING LOB END CU
// QUOTEANDBIND ROUTING LOB SECTION ANCHOR

// POLICYCHANGE ROUTING LOB START PU
import { PUPolicyChangeWizard } from 'wni-capability-policychange-pu-react';
// POLICYCHANGE ROUTING LOB END PU
// POLICYCHANGE ROUTING LOB START WC
import { WCPolicyChangeWizard } from 'wni-capability-policychange-wc-react';
// POLICYCHANGE ROUTING LOB END WC
// POLICYCHANGE ROUTING LOB START CA
import { CAPolicyChangeWizard } from 'wni-capability-policychange-ca-react';
// POLICYCHANGE ROUTING LOB END CA
// POLICYCHANGE ROUTING LOB START CP
import { CPPolicyChangeWizard } from 'wni-capability-policychange-cp-react';
// POLICYCHANGE ROUTING LOB END CP
// POLICYCHANGE ROUTING LOB START GL
import { GLPolicyChangeWizard } from 'wni-capability-policychange-gl-react';
// POLICYCHANGE ROUTING LOB END GL
// POLICYCHANGE ROUTING LOB START CPP
import { CPPPolicyChangeWizard } from 'wni-capability-policychange-cpp-react';
// POLICYCHANGE ROUTING LOB END CPP
// POLICYCHANGE ROUTING LOB START IM
import { IMPolicyChangeWizard } from 'wni-capability-policychange-im-react';
// POLICYCHANGE ROUTING LOB END IM
// POLICYCHANGE ROUTING LOB START CR
import { CRPolicyChangeWizard } from 'wni-capability-policychange-cr-react';
// POLICYCHANGE ROUTING LOB END CR
// POLICYCHANGE ROUTING LOB START CU
import { CUPolicyChangeWizard } from 'wni-capability-policychange-cu-react';
// POLICYCHANGE ROUTING LOB END CU
// POLICYCHANGE ROUTING LOB SECTION ANCHOR
import FaqPage from 'gw-capability-faq-react';
import { KnockOutPage } from 'gw-components-platform-react';
import { InternalCommonRoutes } from 'wni-portals-route-react';

import PELandingPage, {
    Accounts,
    Policies,
    Activities,
    Analytics,
    AccountDetails,
    PolicyDetails,
    SearchResults,
    QuoteDetails,
    Renewal,
    Endorsement,
    Rewrite,
    Cancellation,
    AccountBillingAndPayment,
    MakePayment,
    ContactUnderwriter,
    PaymentConfirmation,
    NewQuotePage,
    NewQuoteAccountSearch,
    Statements
} from 'gw-capability-gateway-react';

import { UIPlatform } from 'wni-debug-platform-react';
import { DebugUtil } from 'wni-portals-util-js';
import { ClaimDetails } from 'gw-capability-claim-react';
import { ClaimsLanding } from 'gw-capability-gateway-claim-react';
import { CommissionLanding } from 'gw-capability-gateway-commission-react';
import { PolicyDocumentError } from 'gw-capability-document-react';
import PropTypes from 'prop-types';
import { SubmitPaymentComponent, AutoPaymentPage, NewQuoteForExistingAccount } from 'wni-capability-gateway-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { AQAccountQuotesWizard, AQPaymentPage } from 'wni-capability-account-quotes-react';
import { CLPolicyHolder, NewSubmissionBlocked } from 'wni-capability-account-commercial-react';
import { ExternalCommonRoutes } from 'wni-capability-ao-react';

const { gatewayAnalytics, fnolLobs } = appConfig;
const gatewayClaims = !_.isEmpty(fnolLobs);
const CurrentLandingPage = DebugUtil.getCurrentLandingPage(PELandingPage, Policies);

const componentMap = {
    currentlandingpage: Policies,
    landingpage: PELandingPage,
    accounts: Accounts,
    policies: Policies,
    claimslanding: ClaimsLanding,
    activities: Activities,
    statements: Statements,
    newquotepage: NewQuotePage,
    analytics: Analytics,
    accountdetails: AccountDetails,
    policydetails: PolicyDetails,
    newquoteaccountsearch: NewQuoteAccountSearch,
    aqaccountquoteswizard: AQAccountQuotesWizard,
    searchresults: SearchResults,
    quotedetails: QuoteDetails,
    renewal: Renewal,
    rewrite: Rewrite,
    endorsement: Endorsement,
    cancellation: Cancellation,
    accountbillingandpayment: AccountBillingAndPayment,
    makepayment: MakePayment,
    submitpaymentcomponent: SubmitPaymentComponent,
    contactunderwriter: ContactUnderwriter,
    paymentconfirmation: PaymentConfirmation,
    basefnolwizard: BaseFNOLWizard,
    claimsconfirmationpage: ClaimsConfirmationPage,
    contactagentpage: ContactAgentPage,
    preferences: Preferences,
    fnolhowizard: FNOLHOWizard,
    fnolpawizard: FNOLPAWizard,
    fnolcawizard: FNOLCAWizard,
    fnolcpwizard: FNOLCPWizard,
    fnolwcwizard: FNOLWCWizard,
    fnolbopwizard: FNOLBOPWizard,
    fnolgeneralwizard: FNOLGeneralWizard,
    pepawizard: PASubmissionWizard,
    pehowizard: HOSubmissionWizard,
    dpsubmissionwizard: DPSubmissionWizard,
// QUOTEANDBIND ROUTING LOB START Dummy
    dummysubmissionwizard: DummySubmissionWizard,
// QUOTEANDBIND ROUTING LOB END Dummy
    walsubmissionwizard: WALSubmissionWizard,
    rtsubmissionwizard: RTSubmissionWizard,
    pebopwizard: PEBOPWizard,
    pecpwizard: PECPWizard,
    papolicychangewizard: PAPolicyChangeWizard,
    hopolicychangewizard: HOPolicyChangeWizard,
    dppolicychangewizard: DPPolicyChangeWizard,
// POLICYCHANGE ROUTING LOB START Dummy
    dummypolicychangewizard: DummyPolicyChangeWizard,
// POLICYCHANGE ROUTING LOB END Dummy
    walpolicychangewizard: WALPolicyChangeWizard,
    rtpolicychangewizard: RTPolicyChangeWizard,
    // cppolicychangewizard: CPPolicyChangeWizard,
    boppolicychangewizard: BOPPolicyChangeWizard,
    cppolicyrenewalwizard: CPPolicyRenewalWizard,
    boppolicyrenewalwizard: BOPPolicyRenewalWizard,

// QUOTEANDBIND ROUTING LOB START CA
    casubmissionwizard: CASubmissionWizard,
// QUOTEANDBIND ROUTING LOB END CA

// QUOTEANDBIND ROUTING LOB START WC
    wcsubmissionwizard: WCSubmissionWizard,
// QUOTEANDBIND ROUTING LOB END WC
// QUOTEANDBIND ROUTING LOB START PU
    pusubmissionwizard: PUSubmissionWizard,
// QUOTEANDBIND ROUTING LOB END PU
// QUOTEANDBIND ROUTING LOB START GL
    glsubmissionwizard: GLSubmissionWizard,
// QUOTEANDBIND ROUTING LOB END GL
// QUOTEANDBIND ROUTING LOB START CP
    cpsubmissionwizard: CPSubmissionWizard,
// QUOTEANDBIND ROUTING LOB END CP
// QUOTEANDBIND ROUTING LOB START IM
    imsubmissionwizard: IMSubmissionWizard,
// QUOTEANDBIND ROUTING LOB END IM
// QUOTEANDBIND ROUTING LOB START CPP
    cppsubmissionwizard: CPPSubmissionWizard,
// QUOTEANDBIND ROUTING LOB END CPP
// QUOTEANDBIND ROUTING LOB START CR
    crsubmissionwizard: CRSubmissionWizard,
// QUOTEANDBIND ROUTING LOB END CR
// QUOTEANDBIND ROUTING LOB START CU
    cusubmissionwizard: CUSubmissionWizard,
// QUOTEANDBIND ROUTING LOB END CU
// QUOTEANDBIND ROUTING LOB SECTION ANCHOR
// POLICYCHANGE ROUTING LOB START PU
    pupolicychangewizard: PUPolicyChangeWizard,
// POLICYCHANGE ROUTING LOB END PU
// POLICYCHANGE ROUTING LOB START WC
    wcpolicychangewizard: WCPolicyChangeWizard,
// POLICYCHANGE ROUTING LOB END WC
// POLICYCHANGE ROUTING LOB START CA
    capolicychangewizard: CAPolicyChangeWizard,
// POLICYCHANGE ROUTING LOB END CA
// POLICYCHANGE ROUTING LOB START CP
    cppolicychangewizard: CPPolicyChangeWizard,
// POLICYCHANGE ROUTING LOB END CP
// POLICYCHANGE ROUTING LOB START GL
    glpolicychangewizard: GLPolicyChangeWizard,
// POLICYCHANGE ROUTING LOB END GL
// POLICYCHANGE ROUTING LOB START CPP
    cpppolicychangewizard: CPPPolicyChangeWizard,
// POLICYCHANGE ROUTING LOB END CPP
// POLICYCHANGE ROUTING LOB START IM
    impolicychangewizard: IMPolicyChangeWizard,
// POLICYCHANGE ROUTING LOB END IM
// POLICYCHANGE ROUTING LOB START CR
    crpolicychangewizard: CRPolicyChangeWizard,
// POLICYCHANGE ROUTING LOB END CR
// POLICYCHANGE ROUTING LOB START CU
    cupolicychangewizard: CUPolicyChangeWizard,
// POLICYCHANGE ROUTING LOB END CU
// POLICYCHANGE ROUTING LOB SECTION ANCHOR
    faqpage: FaqPage,
    knockoutpage: KnockOutPage,
    claimdetails: ClaimDetails,
    // createNote: CreateNote,
    commissionlanding: CommissionLanding,
    policydocumenterror: PolicyDocumentError,
    aqpaymentpage: AQPaymentPage,
    // agentsonline route
    externalcommonroutes: ExternalCommonRoutes,
    autopaymentpage: AutoPaymentPage,
    paysuccesspage: PaySuccessPageByBCC,
    internalcommonroutes: InternalCommonRoutes,

    // UI 
    uiplatform: UIPlatform,
    newquoteforexistingaccount: NewQuoteForExistingAccount,

    // for CL
    claccountquotes: CLPolicyHolder,
    newsubmissionblocked: NewSubmissionBlocked

};
export default componentMap;