import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import React, { useState, useContext, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { QuoteUtil, ValidationIssueUtil, WindowUtil } from 'wni-portals-util-js';
import { useModal } from '@jutro/components';
import { GLSublineService } from 'wni-capability-quoteandbind-gl';
import WizardPage from '../../templates/GLWizardPage';
import metadata from './GLSublineSelectionPage.metadata.json5';
import messages from './GLSublineSelectionPage.messages';
import styles from './GLSublineSelectionPage.module.scss';

import { CheckboxField } from '@jutro/legacy/components';
import { Link } from '@jutro/router';

const LOB_NAME = 'generalLiability';
const COVAEABLES_PATH = `lobData.${LOB_NAME}.coverables`;
const PATH = `${COVAEABLES_PATH}.subLines`;
const SELECTED_SUBLINE_PATH = `${COVAEABLES_PATH}.sublineSelected`;
const LOCATIONS_PATH = `${COVAEABLES_PATH}.locations`;
const stateSimpleInfosPATH = `lobData.${LOB_NAME}.stateSimpleInfos`;
const EXPOSURES_PATH = `${COVAEABLES_PATH}.exposures`;

const noneSelectedState = 'None Selected';
const premiseSublineText = 'Premises/Operations and Products/Completed Operations';
const liquorSublineText = 'Liquor';
const proWithdrawalSublineText = 'Product Withdrawal';
const ownersSublineText = 'Owners and Contractors';
const railroadSublineText = 'Railroad';

const LIQUOR_PRODUCTWITHDRAWAL_LINES = [liquorSublineText, proWithdrawalSublineText];
const OWNERS_RAILROAD_LINES = [ownersSublineText, railroadSublineText];

function GLSublineSelectionPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardSnapshot,
        updateWizardData,
        isReadOnly,
    } = props;

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value;

    const translator = useTranslator();
    const modalApi = useModal();
    const { authHeader } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const { loadingMask: { setLoadingMask }, domainCompany } = useDependencies(['loadingMask', 'domainCompany']);
    const { initialValidation, onValidate, isComponentValid, registerComponentValidation } = useValidation('GLSublineSelectionPage');
    const [showErrors, updateShowErrors] = useState(false);
    const [validationIssues, updateValidationIssues] = useState([]);
    const [displayWarnings, updateDisplayWarnings] = useState(false);

    const [allErrors, updateAllErrors] = useState({
        premisesSublineError: false,
        ownersSublineError: false,
        railroadSublineError: false
    });

    const subLines = _.get(submissionVM.value, PATH);
    const [tableData, updateTableData] = useState(subLines);
    const [titleErrorMessage, updateTitleErrorMessage] = useState([]);

    const registerFieldValidation = useCallback(() => {
        const checkValidFields = ['ownersSublineError', 'railroadSublineError'];
        const isValid = _.every(checkValidFields, (item) => !_.get(allErrors, item, false));
        return isValid;
    }, [allErrors])

    useEffect(() => {
        registerComponentValidation(registerFieldValidation)
    }, [registerFieldValidation, registerComponentValidation]);

    const renderTitleErrorMessage = (data) => {
        return data.map((item) => {
            return <div className='font-normal'>{translator(messages.errorMessage, {subline: item.subline})}</div>
        })
    };

    const generateValidationIssues = (issues) => {
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(issues);
    
        updateValidationIssues(newValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }
        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            updateShowErrors(true);
            return false;
        }
        return true;
    };

    const validateSublines = useCallback((sublineData, isInitialization = false) => {
        const oldSublineData = _.get(submissionVM.value, PATH, []);
        // const newSublineData = 
        const isPremisesSelected = _.find(sublineData, (item) => item.subline === premiseSublineText)?.selected;
        const isOwnersSelected = _.find(sublineData, (item) => item.subline === ownersSublineText)?.selected;
        const isRailroadSelected = _.find(sublineData, (item) => item.subline === railroadSublineText)?.selected;

        // check if popco unSelected, if liquor or productWithDrawal is selected, will sync it to unSelected, and show the warning message
        const filterLiquorAndProductWithDrawalSelected = _.filter(oldSublineData, (item) => _.includes(LIQUOR_PRODUCTWITHDRAWAL_LINES, item.subline) && item.selected);
        const errorMessages = renderTitleErrorMessage(filterLiquorAndProductWithDrawalSelected);
        updateTitleErrorMessage(errorMessages);

        let newTableData = sublineData;
        let allSublines = sublineData;
        let isVisiblePremisesError = false;

        if(!isPremisesSelected) {
            newTableData = sublineData.filter((item) => !_.includes(LIQUOR_PRODUCTWITHDRAWAL_LINES, item.subline));
            allSublines = _.map(sublineData, (item) => {
                let newItem = item;
                if (_.includes(LIQUOR_PRODUCTWITHDRAWAL_LINES, item.subline) && !isInitialization) {
                    newItem = {
                        ...item,
                        selected: false,
                        formattedJurisdictions: noneSelectedState,
                    };
                }
                return newItem;
            });
            isVisiblePremisesError = !_.isEmpty(filterLiquorAndProductWithDrawalSelected);
        };

        updateTableData(newTableData);
        if(!isReadOnly) {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM.value, PATH, allSublines);
            updateWizardData(newSubmissionVM);
        }

        const selectedSublines = _.filter(newTableData, (item) => item.selected);
        const isVisibleOwnersError = isOwnersSelected && selectedSublines.length > 1;
        const isVisibleRailroadError = isRailroadSelected && selectedSublines.length > 1;

        const newAllErrors = {
            premisesSublineError: isVisiblePremisesError,
            ownersSublineError: isVisibleOwnersError,
            railroadSublineError: isVisibleRailroadError
        };

        updateAllErrors(newAllErrors);
    }, [submissionVM.value])

    const onSelectionChange = (val, path, item) => {
        const newValue = {
            ...item,
            updated: true, // if subline update, will update it to database,
            [path]: val,
            formattedJurisdictions: val ? 'AK' : noneSelectedState,
        };

        const newTableData = _.get(submissionVM.value, PATH, []);
        const currentIndex = newTableData.findIndex((v) => v.subline === item.subline);
        _.set(newTableData, currentIndex, newValue);

        validateSublines(newTableData);
        updateValidationIssues([]);
    };

    useEffect(() => {
        const newTableData = _.get(submissionVM.value, PATH, []);
        validateSublines(newTableData, true);
        // Premises/Operations... will always be selected as a default For UIC
        // if submission from pc, will has no subline selected data, and from PE, subline will be selected when create GL line
        const hasSelectedSubline = _.find(tableData, subline => {
            return subline.selected
        });

        if (_.get(domainCompany, 'code') === 'UIC' && _.isEmpty(hasSelectedSubline)) {
            const premisesSubline = _.find(tableData, (subline) => subline.subline === premiseSublineText)
            onSelectionChange(true, 'selected', premisesSubline);
        };

    }, []);

    const renderCheckbox = (item, index, { path}) => {
        return <CheckboxField value={item[path]} readOnly={isReadOnly} onValueChange={(value) => onSelectionChange(value, path, item)} />
    }

    const handleValidation = () => {
        updateShowErrors(true);
    };

    const onPageNext = async () => {
        if (isReadOnly) {
            return submissionVM;
        };
        if (!isComponentValid) {
            handleValidation();
            return false;
        };
        setLoadingMask(true);
        const requestData = {
            jobID,
            sessionUUID,
            dto: _.get(submissionVM.value, PATH, [])
        }
        const res = await GLSublineService.updateSublineSelected(requestData, authHeader);
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM.value, PATH, res.subLines);
        _.set(newSubmissionVM.value, SELECTED_SUBLINE_PATH, res.sublineSelected);
        _.set(newSubmissionVM.value, stateSimpleInfosPATH, res.stateSimpleInfos);
        _.set(newSubmissionVM.value, LOCATIONS_PATH, res.gllocations);
        _.set(newSubmissionVM.value, EXPOSURES_PATH, res.exposures);
        updateWizardSnapshot(newSubmissionVM);
        setLoadingMask(false);
        const isPageValid = generateValidationIssues(res.errorsAndWarnings);
        if(!isPageValid) {
            return false;
        }
        return newSubmissionVM;
    };

    const renderSublineCell = (rowData, index, { path }) => {
        let dom = rowData[path];
        switch (rowData[path]) {
            case ownersSublineText:
                const isVisibleOwnersError = _.get(allErrors, 'ownersSublineError', false);
                dom = (
                    <div>
                        <div>{rowData[path]}</div>
                        {isVisibleOwnersError && <div className="font-error">{translator(messages.ownersSublineErrorMessage)}</div>}
                    </div>
                );
                break;
            case railroadSublineText:
                const isVisibleRailroadError = _.get(allErrors, 'railroadSublineError', false);
                dom = (
                    <div>
                        <div>{rowData[path]}</div>
                        {isVisibleRailroadError && <div className="font-error">{translator(messages.railroadSublineErrorMessage)}</div>}
                    </div>
                );
                break;
            default:
                dom = rowData[path];
        };

        return dom;
    }

    const selectOtherStates = (event, rowData) => {
        event.preventDefault();
        event.stopPropagation();
        modalApi.showAlert({
            title: 'select different states',
            message: 'requirements to come with later state release...',
            status: 'info',
            icon: 'mi-info-outline'
        }).catch(_.noop);
    }

    const renderStateCell = (rowData, index, property) => {
        if (_.get(domainCompany, 'code') === 'UIC' || !rowData.selected) {
            return rowData.formattedJurisdictions
        }
        return (
            <Link onClick={(event) => selectOtherStates(event, rowData)} href="/">{rowData.formattedJurisdictions}</Link>)
    }


    const overrideProps = {
        '@field': {
            readOnly: isReadOnly
        },
        wniHeaderSection: {
            errorState: _.get(allErrors, 'premisesSublineError', false)
        },
        sublineErrorMessages: {
            visible: _.get(allErrors, 'premisesSublineError', false),
            content: titleErrorMessage
        },
        sublineTable: {
            data: tableData
        }
        
    };

    const resolvers = {
        callbackMap: {
            renderCheckbox,
            renderSublineCell,
            renderStateCell
        },
        componentMap: {
        },
        classNameMap: styles
    };
    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={onPageNext}
            pageLevelValidationIssues={validationIssues}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                showErrors={showErrors}
                onValidationChange={onValidate}
                {...resolvers}
            />
        </WizardPage>
    );
}

export default GLSublineSelectionPage;
