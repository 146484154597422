import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import styles from './VehicleDamagePicker.module.scss';
import { Button } from '@jutro/legacy/components';

const DamageList = memo(({ damagedParts, offDamage }) => {
    const translator = useTranslator();
    return (
        <div>
            {damagedParts.map((item) => (
                <div key={item.name}>
                    <span>
                        {translator({
                            id: item.name,
                            defaultMessage: item.name
                        })}
                    </span>
                    <Button
                        id={`damageParts${item.name}`}
                        size="large"
                        type="text"
                        icon="mi-delete"
                        iconPosition={null}
                        className={styles.gw_trash_digital_button}
                        onClick={() => offDamage(item, damagedParts)}
                    />
                </div>
            ))}
        </div>
    );
});
DamageList.propTypes = {
    damagedParts: PropTypes.shape([]).isRequired,
    offDamage: PropTypes.func.isRequired
};

export default DamageList;
