import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import FNOLCPWizard from './FNOLCPWizard';
import FNOLCPWhatPage from './pages/What/WhatPage';
import FNOLCPDetailsPage from './pages/Details/DetailsPage';
import FNOLCPBuildingPage from './pages/CPBuilding/BuildingPage';

setComponentMapOverrides(
    {
        FNOLCPWizard: { component: 'FNOLCPWizard' },
        FNOLCPWhatPage: { component: 'FNOLCPWhatPage' },
        FNOLCPDetailsPage: { component: 'FNOLCPDetailsPage' },
        FNOLCPBuildingPage: { component: 'FNOLCPBuildingPage' }
    },
    {
        FNOLCPWizard,
        FNOLCPWhatPage,
        FNOLCPDetailsPage,
        FNOLCPBuildingPage
    }
);

export {
    FNOLCPWizard as default
};
