/* eslint no-param-reassign: "error"*/
import _ from 'lodash';
import HOClaimExtension from './models/HOClaimExtension';
import CPClaimExtension from './models/CPClaimExtension';
import GeneralClaimExtension from './models/GeneralClaimExtension';
import CAClaimExtension from './models/CAClaimExtension';
import PAClaimExtension from './models/PAClaimExtention';
import WCClaimExtension from './models/WCClaimExtension';

export default {
    mixinLobDraftDataExtensions: (claim) => {
        if (!claim.policy) {
            return;
        }
        claim.lobs = (_.isObject(claim.lobs)) ? claim.lobs : {};
        const { policyType } = claim.policy;
        switch (policyType) {
            case 'PersonalAuto':
                claim.lobs.personalAuto = new PAClaimExtension(claim, 'personalAuto');
                break;
            case 'Homeowners':
            case 'HOPHomeowners':
                claim.lobs.homeowners = new HOClaimExtension(claim);
                break;
            case 'BusinessAuto':
                claim.lobs.commercialAuto = new CAClaimExtension(claim, 'commercialAuto');
                break;
            case 'GeneralLiability':
                claim.lobs.generalLiability = new GeneralClaimExtension(claim, 'generalLiability');
                break;
            case 'BusinessOwners':
                claim.lobs.businessOwners = new GeneralClaimExtension(claim, 'businessOwners');
                break;
            case 'InlandMarine':
                claim.lobs.inlandMarine = new GeneralClaimExtension(claim, 'inlandMarine');
                break;
            case 'WorkersComp':
                claim.lobs.workersComp = new WCClaimExtension(claim);
                break;
            case 'CommercialProperty':
                claim.lobs.commercialProperty = new CPClaimExtension(claim);
                break;
            default:
                break;
        }
    },

    setLossDetails: (claim) => {
        const { policyType } = claim.policy;
        switch (policyType) {
            case 'PersonalAuto':
                claim.lossType = 'AUTO';
                claim.lossCause = 'vehcollision';
                break;
            case 'BusinessAuto':
                claim.lossType = 'AUTO';
                claim.lossCause = 'vehcollision';
                break;
            case 'Homeowners':
            case 'HOPHomeowners':
                claim.lossType = 'PR';
                claim.lossCause = 'fire';
                break;
            case 'GeneralLiability':
                claim.lossType = 'GL';
                claim.lossCause = undefined;
                break;
            case 'BusinessOwners':
                claim.lossType = 'GL';
                claim.lossCause = undefined;
                break;
            case 'InlandMarine':
                claim.lossType = 'PR';
                claim.lossCause = undefined;
                break;
            case 'WorkersComp':
                claim.lossType = 'WC';
                claim.lossCause = undefined;
                break;
            case 'CommercialProperty':
                claim.lossType = 'PR';
                claim.lossCause = undefined;
                break;
            default:
                break;
        }
    }
};
