import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('imPolicyDetailsChangeService'), method, data, additionalHeaders);
}

export default class IMPolicyDetailsChangeService {

    /**
     * Invokes IMPolicyDetailsChangeHandler.saveIMPolicyDetailsData()
     * 
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {Object} policyDetailsDTO
     * @param {Object} quoteBaseDataDTO
     * @param {Object} changeData
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static saveIMPolicyDetailsData(jobID, sessionUUID, policyDetailsDTO, quoteBaseDataDTO, changeData, authHeader = {}) {
        return processSubmission('saveIMPolicyDetailsData', [jobID, sessionUUID, policyDetailsDTO, quoteBaseDataDTO, changeData], authHeader);
    }

}