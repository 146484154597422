import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { IntlContext, useTranslator } from '@jutro/locale';
import {
    ErrorsAndWarningsUtil,
    ServiceErrorUtil,
    WindowUtil,
    IssuanceValidationUtil,
    PolicyChangeQuoteUtil,
    WniDateUtil
} from 'wni-portals-util-js';
import { WizardConstants} from 'wni-portals-config-js';
import {
    ModalDialogHelper,
} from 'wni-portals-util-react';
import { ValidationIssuesComponent, QuoteUnderwritingIssuesList, useWniModal } from 'wni-components-platform-react';
import { WniCommonChangeService } from 'wni-capability-policychange';


import { PolicyDiffService } from 'gw-capability-policyjob';
import {
    SideBySidePeriodsComponent,
    ReferToUnderwriterComponent,
    ChangeSummaryComponent,
    TextEditConfirmComponent,
} from 'wni-capability-gateway-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { PolicyChangeCommonMessages as currentMessages } from 'wni-platform-translations';
import {
    CUWizardPage as WizardPage,
    CUQuoteIssueRenderHelper,
} from 'wni-capability-quoteandbind-cu-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import CUPolicyChangeQuoteComponent from './components/CUPolicyChangeQuoteComponent';
import CUAddNewChangeVersionComponent from './components/AddNewVersion/CUAddNewChangeVersionComponent';
import styles from './CUQuoteChangePage.module.scss';
import metadata from './CUQuoteChangePage.metadata.json5';

const {
    getQuotePageErrorsAndWarningsTitle,
    getQuotePageErrorsAndWarningsFooter,
    getQuotePageIssueRenderFn,
} = CUQuoteIssueRenderHelper; // ValidationIssueRenderUtil;

// const BRANCH_NAME_PATH = `lobData.${SXS_LOB_NAME}.offerings[0].branchName`;

function CUQuoteChangeReadOnlyPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData: updateSubmissionVM,
        updateWizardSnapshot,
        wizardStepToFieldMapping,
        jumpTo: wizardJumpTo,
        steps: wizardSteps,
        updateWizardReadOnly,
        wizardPageData,
        updateWizardPageData,
    } = props;
    const translator = useTranslator();
    const intl = useContext(IntlContext);

    const { authHeader } = useAuthentication();

    const {
        onValidate,
        invalidFields,
        isComponentValid,
    } = useValidation('CUQuoteChangePage');
    const [showErrors, updateShowErrors] = useState(false);
    const modalApi = useWniModal();
    const [sxsDataDTO, updateSxsDataDTO] = useState(undefined);
    const [errorsAndWarnings, updateErrorsAndWarnings] = useState(undefined);
    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);
    const [isShowMulti, updateShowMulti] = useState(false);
    const [hasBlockingIssue, updateHasBlockingIssue] = useState(false);
    const [policyDiffData, updatePolicyDiffData] = useState([]);
    const [extraBlockingSubmitIssues, updateExtraBlockingSubmitIssues] = useState([]);

    const {
        jobID,
        sessionUUID,
        baseData: {
            effectiveDate_Ext: effectiveDate,
            periodStartDate,
            periodEndDate,
            selectedVersion_Ext: selectedVersion,
            periodStatus: jobStatus,
        },
        // lobData: {
        // },
        changeData: {
            totalCost,
            totalCost: {
                amount: totalCostAmount,
            } = {},
            transactionCost = {},
            transactionCost: {
                amount: transactionCostAmount,
            } = {},
            previousTotalCost,
            isBillingOptionsEditable,
        }
    } = submissionVM.value;
    const modalHelper = ModalDialogHelper(modalApi);
    // const oosErrorsAndWarnings = OOSUtil.getOOSSliceDatesWarning(
    //     OOSUtil.getOOSSliceDatesString(
    //         oossliceDates,
    //         effectiveDate
    //     ),
    //     translator
    // );

    // const updateSideBySideData = async ({
    //     refreshSxsCache = true,
    //     clearUWIssues = false,
    // } = {}) => {
    //     let sideBySideData = wizardPageData[WizardConstants.sideBySideData];
    //     if (sideBySideData && !refreshSxsCache) {
    //         if (!sxsDataDTO && sideBySideData.personalAuto) {
    //             updateSxsDataDTO(sideBySideData);
    //         }
    //         return;
    //     }
    //     sideBySideData = await WniSxsChangeService.retrieveSxsData(jobID, authHeader);
    //     // const sideBySideData = await WniSxsChangeService.retrieveSxsData(jobID, authHeader);
    //     const newErrorsAndWarnings = _.get(sideBySideData, 'errorsAndWarnings', {});
    //     if (clearUWIssues) {
    //         _.unset(newErrorsAndWarnings, 'underwritingIssues');
    //     }
    //     updateErrorsAndWarnings(newErrorsAndWarnings);
    //     if (sideBySideData.personalAuto) {
    //         updateSxsDataDTO(sideBySideData);
    //         updateWizardPageData({ [WizardConstants.sideBySideData]: sideBySideData });
    //     }
    // };

    const updatePolicyDifferences = async () => {
        const newPolicyDiffData = await PolicyDiffService.getPolicyDiffWithPrevious(jobID,
            authHeader);
        updatePolicyDiffData(newPolicyDiffData);
        updateWizardPageData({ [WizardConstants.policyDiffData]: newPolicyDiffData });
    };

    const withServiceInProgressUpdated = async (cb) => {
        updateServiceCallInProgress(true);
        try {
            await cb();
        } catch (e) {
            const errorMessage = ServiceErrorUtil.getErrorMessage(e);
            modalHelper.alert({ errorMessage });
        }
        updateServiceCallInProgress(false);
    };

    // // const createNewVersion = async () => {
    // //     submissionVM.value = await WniSxsChangeService.createNewSideBySideVersion(jobID, authHeader);
    // //     updateSideBySideData();
    // // };

    const handlePrint = () => {
        window.print();
    };

    useEffect(() => {
        WindowUtil.scrollToTop();
        // to be updated
        withServiceInProgressUpdated(async () => {
            updatePolicyDifferences();
        });
    }, []);

    const onReferToUnderwriter = async (noteForUW) => {
        withServiceInProgressUpdated(async () => {
            const jobResponse = await WniCommonChangeService.referToUnderwriter(jobID,
                noteForUW, authHeader);

            // const serverErrors = _.get(jobResponse, 'errorsAndWarnings_Ext.serverErrors_Ext');
            const referToUWIssues = ErrorsAndWarningsUtil.extractServerErrors(jobResponse);
            if (!_.isEmpty(referToUWIssues)) {
                updateExtraBlockingSubmitIssues(referToUWIssues);
                WindowUtil.scrollTo('policyChangeErrorsAndWarnings');
            } else if (!_.isEmpty(jobResponse)) {
                // history.push(`/change/${jobID}/summary`);
                updateWizardReadOnly(true);
            }
        });
    };

    const generateOverrides = useCallback(() => {
        const effectiveDateFormat = WniDateUtil.formatDateWithPattern(effectiveDate);
        const startPeriodDateFormat = WniDateUtil.formatDateWithPattern(periodStartDate);
        const endPeriodDateFormat = WniDateUtil.formatDateWithPattern(periodEndDate);
        const newErrorsAndWarnings = _.get(submissionVM.value, 'errorsAndWarnings');
        const validationIssues = ErrorsAndWarningsUtil.getValidationIssues(newErrorsAndWarnings,
            [
                // ...oosErrorsAndWarnings,
                ...extraBlockingSubmitIssues
            ]);

        const issueJumpFnMap = ErrorsAndWarningsUtil.getValidationIssueJumpFnMap(validationIssues,
            wizardSteps, wizardStepToFieldMapping, wizardJumpTo);
        const sortByFlowStepFunc = ErrorsAndWarningsUtil.getValidationIssueSortByFlowStepFunc(wizardSteps, wizardStepToFieldMapping);

        const hasValidationError = ErrorsAndWarningsUtil.hasValidationError(newErrorsAndWarnings);

        // const underwritingIssues = _.get(newErrorsAndWarnings, 'underwritingIssues', []);
        const underwritingIssues = ErrorsAndWarningsUtil.filterUWIssuesBasedOnPeriod(
            _.get(newErrorsAndWarnings, 'underwritingIssues', []), selectedVersion
        );
        // const hasBlockUWIssue = ErrorsAndWarningsUtil.hasBlockingOrRejectedUWIssue(newErrorsAndWarnings);
        const hasBlockUWIssue = ErrorsAndWarningsUtil.hasBlockingOrRejectedUWIssue({ underwritingIssues });

        updateHasBlockingIssue(hasBlockUWIssue || hasValidationError);

        const changeUtil = PolicyChangeQuoteUtil({
            intl, translator, totalCost, transactionCost, currentMessages
        });
        const premiumDiffMsg = changeUtil.getPremiumDiffMessage();
        const premiumDiffTitle = changeUtil.getPremiumDiffTitle();
        return {
            '@field': {
                labelPosition: 'left'
            },
            errorsAndWarningsSection: {
                validationIssues: IssuanceValidationUtil.getIssuesMap(validationIssues),
                issueJumpFnMap,
                typeTitleFormatter: getQuotePageErrorsAndWarningsTitle(translator, (issueType) => {
                    return issueType === 'error' ? currentMessages.submitTheChange : undefined;
                }),
                typeFooterFormatter: getQuotePageErrorsAndWarningsFooter({
                    wizardSteps,
                    wizardStepToFieldMapping,
                    wizardJumpTo,
                    resolveButtonLabel: translator(currentMessages.resolveErrors),
                    isServiceCallInProgress,
                }),
                issueRenderFn: getQuotePageIssueRenderFn,
                scrollToIssues: true,
                getSortKeyForIssueWithSameType: sortByFlowStepFunc,
                // Refactoring Notes: should retrieve validation result for current slice only
                issueDistinguisher: true,
            },
            quoteUWIssues: {
                underwritingIssues,
                // headerObject: currentMessages.IssuesRequiringReferral,
            },
            addNewVersionBtn: {
                visible: false,
            },
            branchNameEdit: {
                visible: false,
            },
            deleteQuoteVersionButton: {
                visible: false,
            },
            referToUWBtn: {
                onReferToUnderwriter,
                isServiceCallInProgress,
                visible: false, // hasBlockUWIssue,
                // renderContent: renderHelper.getButtonRenderFunc(isServiceCallInProgress),
            },
            printBtn: {
                disabled: isServiceCallInProgress,
            },
            // printBtnV2: {
            //     disabled: isServiceCallInProgress,
            // },
            // =====================
            policyChangeSummary: {
                validationIssues: premiumDiffMsg,
                typeTitleFormatter: premiumDiffTitle,
                previousTotalCost,
                transactionCost,
                totalCost,
                effectiveDateFormat,
                periodDateRange: `${startPeriodDateFormat} ~ ${endPeriodDateFormat}`,
                // transactionCostAmount,
                showPremiumChange: jobStatus === 'Quoted' || jobStatus === 'Bound',
            },
            // // ==========================
            PAHeadId: {
                visible: !isShowMulti
            },
            policyChangeDetailId: {
                visible: !isShowMulti
            },
            paPolicyDiff: {
                policyDiffData,
                isServiceCallInProgress
            }
        };
    },
    [
        submissionVM,
        isServiceCallInProgress,
        errorsAndWarnings, sxsDataDTO,
        translator,
        isShowMulti,
        policyDiffData,
        showErrors,
    ]);

    const renderQuotePage = useCallback(({ onNext }) => {
        const overrideProps = generateOverrides(onNext);
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                onPrint: handlePrint
            },
            resolveComponentMap: {
                sxsquoteperiods: SideBySidePeriodsComponent,
                validationissuescomponent: ValidationIssuesComponent,
                quoteunderwritingissueslist: QuoteUnderwritingIssuesList,
                policychangequotecomponent: CUPolicyChangeQuoteComponent,
                refertouw: ReferToUnderwriterComponent,
                changesummary: ChangeSummaryComponent,
                addnewversioncomponent: CUAddNewChangeVersionComponent,
                texteditconfirm: TextEditConfirmComponent,
            }
        };

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateSubmissionVM}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        );
    }, [generateOverrides, submissionVM, updateSubmissionVM]); // sxsDataDTO

    return (
        <WizardPage
            showNext={false}
            // cancelLabel={(appConfig.persona === 'policyholder' ? messages.cancelAllChanges : messages.cancel)}
            nextLabel={ isBillingOptionsEditable ? undefined : currentMessages.submitChange}
        >
            {renderQuotePage}
        </WizardPage>
    );
}

CUQuoteChangeReadOnlyPage.propTypes = wizardProps;
export default CUQuoteChangeReadOnlyPage;