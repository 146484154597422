import { defineMessages } from 'react-intl';

export default defineMessages({
    caDetailsTypeOfIncident: {
        id: 'fnol.ca.views.ca-glass-details.Type of Incident',
        defaultMessage: 'Type of Incident'
    },
    caGlassDescribeWhatHappened: {
        id: 'fnol.ca.views.ca-glass-details.Please describe what happened:',
        defaultMessage: 'Please describe what happened:'
    }
});
