import { defineMessages } from 'react-intl';

export default defineMessages({
    whereDidThisHappen: {
        id: 'fnol.common.directives.loss-location.Where did this happen?',
        defaultMessage: 'Where did this happen?'
    },
    fnolUseMyPolicyAddress: {
        id: 'fnol.common.directives.loss-location.Use my policy address',
        defaultMessage: 'Use my policy address'
    },
    fnolSpecifyFullAddress: {
        id: 'fnol.common.directives.loss-location.Specify full address manually',
        defaultMessage: 'Specify full address manually'
    },
    fnolKnowCity: {
        id: 'fnol.common.directives.loss-location.I just know the city',
        defaultMessage: 'I just know the city'
    },
    fnolLocationMap: {
        id: 'fnol.common.directives.loss-location.Choose location on a map',
        defaultMessage: 'Choose location on a map'
    },
    fnolSelectAddress: {
        id: 'fnol.common.directives.loss-location.Select Address',
        defaultMessage: 'Select Address'
    },
    fnolChooseAddress: {
        id: 'fnol.common.directives.loss-location.-- Choose Address --',
        defaultMessage: '-- Choose Address --'
    },
    fnolProvideAddress: {
        id: 'fnol.common.directives.loss-location.Please provide an address.',
        defaultMessage: 'Please provide an address.'
    },
    fnolMapLocation: {
        id: 'fnol.common.directives.loss-location.Click anywhere on the map to set a location or drag the pin to adjust it.',
        defaultMessage: 'Click anywhere on the map to set a location or drag the pin to adjust it.'
    },
});
