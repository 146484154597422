import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useModal } from '@jutro/components';
import { Link as RouterLink, withRouter, useHistory } from 'react-router-dom';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { SelectProducerCode } from 'gw-gateway-common-react';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import appConfig from 'app-config';
import { useBusinessData } from 'wni-portals-util-react';
import { WniGatewayClaimService } from "wni-capability-gateway-claim";
import messages from '../../Claims.messages'
import styles from './ClaimsLanding.module.scss';
import metadata from './ClaimsLanding.metadata.json5';


import { Link } from '@jutro/router';


const { gatewayParamConfig = {},  capabilitiesConfig: {claim: enableClaimsTab} = {}} = appConfig;

const ClaimsLanding = () => {
    const modalApi = useModal();
    const history = useHistory();
    const { getFeatureCodeValue } = useBusinessData();
    const translator = useTranslator();
    const { authHeader, authUserData: {producerCodes_Ext: producerCodesMap} = {} } = useAuthentication();
    const producerCodeList = producerCodesMap.map((item) => item.code);

    const [currentView, setCurrentView] = useState('recentlyViewedTile')
    const [selectedProducerCode, setSelectedProducerCode] = useState('all')
    const [selectedHeaderContent, setSelectedHeaderContent] = useState(messages.recentlyViewed)
    const enableClaimsForPE = getFeatureCodeValue("EnableClaimsForPE") === true;

    const processResponseData = (responseData) => {
        if (!_.isEmpty(responseData)) {
            return responseData.map((claimInfo) => {
                const claims = {
                    type: claimInfo.isPersonalAccount ? 'Personal' : 'Commercial',
                    product: claimInfo.product,
                    account: claimInfo.insuredName,
                    claimNumber: claimInfo.claimNumber,
                    dateOfLoss: moment(claimInfo.dateOfLoss).format("M/DD/YYYY"),
                    status: claimInfo.state,
                    paid: claimInfo.totalPayments,
                    netIncurred: claimInfo.totalIncurredNet,
                    policyNumber: claimInfo.policyNumber,
                    accountNumber: claimInfo.accountNumber
                };
                return claims;
            });
        }
        return [];
    };

    const getCell = (item, index, { id: property }) => {
        const toolTipMessage = {
            product: translator(messages.product),
            dateOfLoss: translator(messages.dateOfLoss),
            status: translator(messages.status)
        };

        return (
            <span title={toolTipMessage[property]}>
                {item[property]}
            </span>
        );
    };

    const getCurrencyCell = (item, index, { id: property }) => {
        const toolTipMessage = {
            paid: translator(messages.totalPayments),
            netIncurred: translator(messages.totalIncurrednet)
        };
        return (
            <span title={toolTipMessage[property]}>
                <CurrencyField
                    id={`claimsCurrency_${index}`}
                    value={item[property]}
                    datatype="object"
                    readOnly
                    hideLabel
                />
            </span>
        );
    };

    const getAccount = (item, index, { id: property }) => {
        const toolTipMessage = translator(messages.account);
        // const icon = item.type === 'Personal' ? 'gw-account-circle' : 'gw-business';
        return (
            <div className={styles.linkAccount}>
                {/* <Icon icon={icon} className={styles.gwClaimsAccountIcon} /> */}
                <RouterLink
                    to={`/accounts/${item.accountNumber}/summary`}
                    className={styles.gwClaimsLinkContent}
                    title={toolTipMessage}
                >
                    {item[property]}
                </RouterLink>
            </div>
        );
    };

    const handleOnClick = (id) => {
        setCurrentView(id)
    };

    const handleValueChange = (producerCode) => {
        setSelectedProducerCode(producerCode)
    };

    const getClaimDetailsLink = (item, index, property) => {
            const toolTipMessage = {
                policyNumber: translator(messages.policyNumberClaimDetail),
                claimNumber: translator(messages.claimNumber)
            };
            const redirectRoute = `/claims/${item[property.id]}`;
            const redirectPath = {
                pathname: redirectRoute,
                state: {
                    claimNumber: item[property.id],
                    redirectPath: '/claims',
                    claimStatus: item.status
                }
            };
            return (
                <Link
                    to={redirectPath}
                    className={styles.gwClaimsLinkContent}
                    title={toolTipMessage[property.id]}
                >
                    {item[property.id]}
                </Link>
            );
    };

    const getLink = (item, index, { id: property }) => {
        let toolTipMessage = translator(messages.claimNumber);
        if (property === 'policyNumber') {
            toolTipMessage = translator(messages.policyNumber);
            return (
                <RouterLink
                    to={`/policies/${item[property]}/summary`}
                    className={styles.gwClaimsLinkContent}
                    title={toolTipMessage}
                >
                    {item[property]}
                </RouterLink>
            );
        }
        if (
            item.status
            === translator({
                id: 'typekey.ClaimState.draft',
                defaultMessage: 'Draft'
            })
        ) {
            const redirectpath = {
                pathname: '/fnol-select-policy',
                state: {
                    claimNumber: item[property],
                    redirectPath: '/claims',
                    claimStatus: item.status
                }
            };
            return (
                <RouterLink
                    to={redirectpath}
                    className={styles.gwClaimsLinkContent}
                    title={toolTipMessage}
                >
                    {item[property]}
                </RouterLink>
            );
        }
        const redirectpath = {
            pathname: `/claims/${item[property]}`,
            state: {
                claimNumber: item[property],
                redirectPath: '/claims',
                claimStatus: item.status
            }
        };
        return (
            <RouterLink to={redirectpath} className={styles.gwClaimsLinkContent} title={toolTipMessage}>
                {item[property]}
            </RouterLink>
        );
    };

    
    const filterOutDraftClaims = (claimsData) => {
        return claimsData?.filter(elt => elt.status !== 'Draft');
    }

    const recentView = (sorted) => {
        return WniGatewayClaimService.getRecentlyViewedClaims(authHeader).then((response) => {
            setSelectedHeaderContent(messages.recentlyViewed)
            const data = processResponseData(response);
            const sortedData = _.orderBy(
                data,
                sorted.map(({ id }) => (row) => row[id]),
                sorted.map(({ desc }) => (desc ? 'desc' : 'asc'))
            );
            return {
                rows: filterOutDraftClaims(sortedData),
                numberOfRows: sortedData.length
            };
        });
    };

    const recentCreated = (paginationParams, paramProducerCode, sorted) => {
        let claimsPromise = null;
        claimsPromise = _.isNull(paramProducerCode)
            ? WniGatewayClaimService.getClaimsForCurrentUser(
                [
                    gatewayParamConfig.claimsCreatedInLastXDays,
                    paginationParams,
                    paramProducerCode,
                    producerCodeList
                ],
                authHeader
            )
            : WniGatewayClaimService.getClaimsForProducerCode(
                [
                    gatewayParamConfig.claimsCreatedInLastXDays,
                    paginationParams,
                    paramProducerCode
                ],
                authHeader
            );

        return claimsPromise.then((response) => {
            setSelectedHeaderContent(messages.recentlyCreated)
            const data = processResponseData(response.claims);
            const sortedData = _.orderBy(
                data,
                sorted.map(({ id }) => (row) => row[id]),
                sorted.map(({ desc }) => (desc ? 'desc' : 'asc'))
            );
            return {
                rows: filterOutDraftClaims(sortedData),
                numberOfRows: response.maxNumberOfResults
            };
        });
    };

    const openClaims = (paginationParams, paramProducerCode, sorted) =>{
        let claimsPromise = null;
        claimsPromise = _.isNull(paramProducerCode)
            ? WniGatewayClaimService.getOpenClaimsForCurrentUser(
                [
                    gatewayParamConfig.claimsCreatedInLastXDays,
                    paginationParams,
                    paramProducerCode,
                    producerCodeList
                ],
                authHeader
            )
            : WniGatewayClaimService.getOpenClaimsForProducerCode(
                [
                    gatewayParamConfig.claimsCreatedInLastXDays,
                    paginationParams,
                    paramProducerCode
                ],
                authHeader
            );

        return claimsPromise.then((response) => {
            setSelectedHeaderContent(messages.openClaims)
            const data = processResponseData(response.claims);
            const sortedData = _.orderBy(
                data,
                sorted.map(({ id }) => (row) => row[id]),
                sorted.map(({ desc }) => (desc ? 'desc' : 'asc'))
            );
            return {
                rows: filterOutDraftClaims(sortedData),
                numberOfRows: response.maxNumberOfResults
            };
        });
    };

    const closedClaims = (paginationParams, paramProducerCode, sorted) => {
        let claimsPromise = null;
        claimsPromise = _.isNull(paramProducerCode)
            ? WniGatewayClaimService.getClosedClaimsForCurrentUser(
                [
                    gatewayParamConfig.claimsCreatedInLastXDays,
                    paginationParams,
                    paramProducerCode,
                    producerCodeList
                ],
                authHeader
            )
            : WniGatewayClaimService.getClosedClaimsForProducerCode(
                [
                    gatewayParamConfig.claimsCreatedInLastXDays,
                    paginationParams,
                    paramProducerCode
                ],
                authHeader
            );

        return claimsPromise.then((response) => {
            setSelectedHeaderContent(messages.closedClaims)
            const data = processResponseData(response.claims);
            const sortedData = _.orderBy(
                data,
                sorted.map(({ id }) => (row) => row[id]),
                sorted.map(({ desc }) => (desc ? 'desc' : 'asc'))
            );
            return {
                rows: filterOutDraftClaims(sortedData),
                numberOfRows: response.maxNumberOfResults
            };
        });
    };

    const loadData = ({ sorted, pageSize, page }) => {
        const paramProducerCode = selectedProducerCode === 'all' ? null : selectedProducerCode;
        let tile = 'recentlyViewedTile';
        if (currentView) {
            tile = currentView;
        }

        const startIndex = pageSize * page;
        const offsetEndMinusOne = startIndex + pageSize - 1;
        const paginationParams = {
            offsetStart: startIndex,
            offsetEnd: offsetEndMinusOne,
            orderBy: gatewayParamConfig.landingOrderBy,
            orderByDescending: gatewayParamConfig.landingOrderByDescending
        };
        if (tile === 'recentlyViewedTile') {
            return recentView(sorted);
        }
        if (tile === 'recentlyCreatedTile') {
            return recentCreated(paginationParams, paramProducerCode, sorted);
        }
        if (tile === 'openClaimsTile') {
            return openClaims(paginationParams, paramProducerCode, sorted);
        }
        if (tile === 'closedClaimsTile') {
            return closedClaims(paginationParams, paramProducerCode, sorted);
        }
        return { rows: [], numberOfRows: 0 };
    };

    const tableKey = currentView + selectedProducerCode;
    const overrides = {
        recentlyViewedTableGrid: {
            onFetchData: loadData,
            key: tableKey
        },
        [currentView]: {
            active: true
        },
        recentlyViewedId: {
            content: translator(selectedHeaderContent)
        },
        paidColumnTitle: {
            visible: appConfig.persona === 'producer' || appConfig.persona === 'csr'
        },
        netIncurredColumnTitle: {
            visible: appConfig.persona === 'producer' || appConfig.persona === 'csr'
        }
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getCell: getCell,
            getCurrencyCell: getCurrencyCell,
            getAccount: getAccount,
            getLink: getLink,
            getClaimDetailsLink: getClaimDetailsLink,
            handleOnClick: handleOnClick,
            handleValueChange: handleValueChange,
            sortCurrency: DatatableUtil.sortCurrency,
            sortString: DatatableUtil.sortString,
            sortDate: DatatableUtil.sortDate
        },
        resolveComponentMap: {
            selectproducercode: SelectProducerCode
        }
    };
    if(!(enableClaimsForPE && enableClaimsTab)) {
        modalApi.showAlert({
            title: messages.modalError,
            message: messages.viewClaimError,
            status: 'error',
            icon: 'mi-error-outline',
            confirmButtonText: messages.modalOK
        }).then(() => {history.push('/')})
        return null;
    }
    const claimsLandingpage = <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
    return <div>{claimsLandingpage}</div>;

}

// export default withRouter(withIntl(withAuthenticationContext(ClaimsLanding)));
export default withRouter(ClaimsLanding);