import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { WizardPageTemplate } from 'gw-portals-wizard-components-ui';
import { withRouter } from 'react-router-dom';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { FNOLLossLocation, fnolCommonMessages } from 'gw-capability-fnol-common-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './CollisionDetailsPage.metadata.json5';
import styles from './CollisionDetailsPage.module.scss';
import './CollisionDetailsPage.messages';

function FNOLCACollisionDetailsPage(props) {
    const {
        wizardData: claimVM,
        updateWizardData,
        history: {
            location: { state = {} }
        }
    } = props;
    const translator = useTranslator();
    const [collisionTypeCodes, updateCollisionTypeCodes] = useState([]);
    const [showPropertyIncident, updateShowPropertyIncident] = useState(false);
    const {
        onValidate,
        isComponentValid,
        initialValidation,
        registerInitialComponentValidation
    } = useValidation('FNOLCACollisionPage');

    useEffect(() => {
        const detailsCollisionTypeCodes = _.filter(
            claimVM.lossCause.aspects.availableValues,
            (typeCode) => {
                const nonCollisionAutoCodes = [
                    'theftentire',
                    'glassbreakage',
                    'theftparts',
                    'otherobjcoll'
                ];
                return nonCollisionAutoCodes.indexOf(typeCode.code) === -1;
            }
        ).map((collisionObj) => {
            return {
                code: collisionObj.code,
                name: translator({
                    id: collisionObj.name,
                    defaultMessage: collisionObj.name
                })
            };
        });
        const propertyDescriptionCheck = _.get(
            claimVM,
            'lobs.commercialAuto.fixedPropertyIncident.propertyDescription.value'
        );
        if (!_.isEmpty(propertyDescriptionCheck)) {
            updateShowPropertyIncident(true);
        }
        updateCollisionTypeCodes(detailsCollisionTypeCodes);
        _.set(claimVM.value, 'propertyDamage', false);
        updateWizardData(claimVM);
        // Call once when page is mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onHandlePropertyDamageChange = useCallback(
        (value, path) => {
            updateShowPropertyIncident(value);
            if (value === false) {
                _.set(
                    claimVM,
                    'lobs.commercialAuto.fixedPropertyIncident.propertyDescription.value',
                    undefined
                );
            }
            _.set(claimVM.value, path, value);
            updateWizardData(claimVM);
        },
        [claimVM, updateWizardData]
    );

    const onHandleCollisionTypeChange = (value, path) => {
        _.set(claimVM, path, value);
        updateWizardData(claimVM);
    };

    const isClaimStatus = useCallback(() => {
        const { claimStatus } = state;
        return !_.isEmpty(claimStatus);
    }, [state]);

    useEffect(() => {
        registerInitialComponentValidation(isClaimStatus);
    }, [registerInitialComponentValidation, isClaimStatus]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
            phoneWide: {
                labelPosition: 'top'
            }
        },
        typeOfCollisionDropDown: {
            availableValues: collisionTypeCodes
        },
        wasAnyPropertyDamaged: {
            value: showPropertyIncident
        },
        describePropertyDamage: {
            visible: showPropertyIncident
        },
        lossLocationComponent: {
            path: !_.isUndefined(claimVM) ? claimVM : null,
            updatePath: updateWizardData
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onHandlePropertyDamageChange: onHandlePropertyDamageChange,
            onHandleCollisionTypeChange: onHandleCollisionTypeChange,
            onValidate: onValidate
        },
        resolveComponentMap: {
            losslocationcomponent: FNOLLossLocation
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, claimVM, id, path, overrideProps);
        },
        [claimVM, overrideProps]
    );

    return (
        <WizardPage
            cancelLabel={translator(fnolCommonMessages.fnolSaveandExit)}
            skipWhen={initialValidation}
            template={WizardPageTemplate}
            disableNext={!isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

FNOLCACollisionDetailsPage.propTypes = wizardProps;
export default withRouter(FNOLCACollisionDetailsPage);
