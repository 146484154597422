import React, { useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { fnolCommonMessages } from 'gw-capability-fnol-common-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './CAContactItemComponent.metadata.json5';
import caClaimVehicle from '../../pages/Vehicles/VehiclesPage.module.scss';

function FNOLCAContactItemComponent(props) {
    const { data: claimVM, incidentIndex, updateAddPassengers } = props;
    const translator = useTranslator();

    const generatePassengerTableRow = useCallback(
        (evt, index) => {
            evt.preventDefault();
            const getCurrentMenuItemID = evt.currentTarget.id;
            const getCurrentMenuItemIDNew = getCurrentMenuItemID.split('_');
            const contactsPath = 'contacts.value';
            const contactsItems = _.get(claimVM, contactsPath, []);
            if (getCurrentMenuItemIDNew[0].indexOf('anotherPersonLink') === -1) {
                const selectedPassenger = contactsItems[index];
                if (_.filter(contactsItems, selectedPassenger).length) {
                    claimVM.lobs.commercialAuto.vehicleIncidents.value[incidentIndex].addPassenger(
                        selectedPassenger,
                        claimVM.value
                    );
                }
            } else {
                claimVM.lobs.commercialAuto.vehicleIncidents.value[incidentIndex].addPassenger(
                    null,
                    claimVM.value
                );
            }
            updateAddPassengers(claimVM);
        },
        [claimVM, incidentIndex, updateAddPassengers]
    );

    const generateAddPassengerOverrides = useCallback(() => {
        const contactsPath = 'contacts.value';
        const contactsItems = _.get(claimVM, contactsPath, []);
        const addedContacts = claimVM.lobs.commercialAuto.vehicleIncidents.value[
            incidentIndex
        ].getAvailablePassengers(claimVM.lobs.commercialAuto.value);
        const overrides = contactsItems.map((contactsItem, index) => {
            return {
                [`contactItemsDropDown${index}`]: {
                    content: contactsItem.getDisplayName(translator(fnolCommonMessages.fnolUnnamed)),
                    visible: _.includes(addedContacts, contactsItem),
                    onClick: (e) => {
                        generatePassengerTableRow(e, index);
                    }
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [claimVM, generatePassengerTableRow, incidentIndex, translator]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left'
        },
        ...generateAddPassengerOverrides()
    };
    const resolvers = {
        resolveClassNameMap: caClaimVehicle
    };
    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.componentContent, claimVM, id, path, overrideProps);
        },
        [claimVM, overrideProps]
    );

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={claimVM}
            overrideProps={overrideProps}
            onModelChange={updateAddPassengers}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

FNOLCAContactItemComponent.propTypes = {
    data: PropTypes.shape({}).isRequired,
    updateAddPassengers: PropTypes.func.isRequired,
    incidentIndex: PropTypes.number.isRequired
};
export default FNOLCAContactItemComponent;
