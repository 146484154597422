import React, {
    useContext
} from 'react'
import _ from 'lodash'
import { useTranslator } from '@jutro/locale';
import { WniDropdownSelect } from "wni-common-base-components"
import CoveragesConfigContext from '../../../context/CUCoveragesConfigContext'
import SingleClauseContext from '../../../context/CUSingleClauseContext'
import { ValuePath } from '../../../util/CUScheduleItemsUtil'

function formatAvailableValues(availableValues) {
    return _.sortBy(availableValues, 'priority')
        .map((availableValue) => ({
            code: availableValue.code,
            name: availableValue.name
        }));
}

const ScheduleItemEditableCellDropdown = (props) => {
    const {
        propertyInfo,
        scheduleItem,
        scheduleItemVMPath,
    } = props

    const {
        id: propertyInfoId,
        required,
        valueRange_Ext: valueRange,
        typeKeyName_Ext: typeKeyName
    } = propertyInfo

    const {
        itemNumber,
        itemData: {
            [propertyInfoId]: itemDataField
        }
    } = scheduleItem

    const {
        options_Ext: allOptions = [],
        [ValuePath.TypeCodeValue]: value,
        available_Ext: available
    } = itemDataField

    const translator = useTranslator();
    const coveragesConfig = useContext(CoveragesConfigContext);


    const {
        clauseCode,
        showErrors,
        onChangeClause,
        onChangeSubmissionAndSync,
    } = useContext(SingleClauseContext)
    const {
        scheduleConfig: {
            optionFilters: {
                [clauseCode]: optionFilter = {},
            },
            fieldsNotNeedSyncWhileChange: {
                [clauseCode]: fieldsNotNeedSync = []
            }
        }
    } = coveragesConfig;

    const notNeedSyncImmediately = fieldsNotNeedSync.includes(propertyInfoId)

    const optionFilterForCurrentField = _.get(optionFilter, propertyInfoId)
    const optionValues = _.isNil(optionFilterForCurrentField) ? allOptions
        : optionFilterForCurrentField(scheduleItem, allOptions);

    const  formatAvailableTypeKeyValues = () => {
        return valueRange.map((typeKey) => ({
                code: typeKey.code,
                name: translator({id: `${typeKeyName}.${typeKey.code}`})
            }));
    }
    
    const availableValues = !_.isEmpty(valueRange) ? formatAvailableTypeKeyValues(): formatAvailableValues(optionValues)

    const onDropdownValueChange = (newValue) => {
        const newItemDataField = {
            ...itemDataField,
            [ValuePath.TypeCodeValue]: newValue,
            updated_Ext: true
        };
        const path = `${scheduleItemVMPath}.itemData.${propertyInfoId}`
        if (notNeedSyncImmediately) {
            onChangeClause(newItemDataField, path)
            return
        }
        onChangeSubmissionAndSync(newItemDataField, path)
        // console.log(path)
    }

    return <WniDropdownSelect
        id = {`${propertyInfoId}_${itemNumber}`}
        dataType = 'string'
        required = {required}
        showRequired = {required}
        availableValues = {availableValues}
        value = {value}
        onValueChange = {onDropdownValueChange}
        showErrors = {showErrors}
        disabled={!available}
    />
}

export default ScheduleItemEditableCellDropdown