import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import CUPolicyChangeStartPage from './pages/Start/CUPolicyChangeStartPage';
import CUPaySuccessChangePage from './pages/PaySuccess/CUPaySuccessChangePage';

import CUUnderlyingChangePage from './pages/Underlying/CUUnderlyingChangePage';
import CUUnderlyingChangeReadOnlyPage from './pages/Underlying/CUUnderlyingChangePage.readonly';

import CURiskAnalysisChangePage from './pages/RiskAnalysis/CURiskAnalysisChangePage';
import CURiskAnalysisChangeReadOnlyPage from './pages/RiskAnalysis/CURiskAnalysisChangePage.readonly';


// WIZARD PAGE IMPORT ANCHOR

import CUQuoteChangePage from './pages/Quote/CUQuoteChangePage';
import CUQuoteChangeReadOnlyPage from './pages/Quote/CUQuoteChangePage.readonly';

setComponentMapOverrides(
    {
        CUPolicyChangeStartPage: { component: 'CUPolicyChangeStartPage' },
        CUPaySuccessChangePage: { component: 'CUPaySuccessChangePage' },

        CUUnderlyingChangePage: { component: 'CUUnderlyingChangePage'},
        CUUnderlyingChangeReadOnlyPage: { component: 'CUUnderlyingChangeReadOnlyPage'},

        CURiskAnalysisChangePage: { component: 'CURiskAnalysisChangePage'},
        CURiskAnalysisChangeReadOnlyPage: { component: 'CURiskAnalysisChangeReadOnlyPage'},


// WIZARD PAGE COMPONENT NAME ANCHOR
        CUQuoteChangePage: { component: 'CUQuoteChangePage' },
        CUQuoteChangeReadOnlyPage: { component: 'CUQuoteChangeReadOnlyPage' },
    },
    {
        CUPolicyChangeStartPage,
        CUPaySuccessChangePage,

        CUUnderlyingChangePage,
        CUUnderlyingChangeReadOnlyPage,

        CURiskAnalysisChangePage,
        CURiskAnalysisChangeReadOnlyPage,


// WIZARD PAGE COMPONENT MAP ANCHOR
        CUQuoteChangePage,
        CUQuoteChangeReadOnlyPage,
    }
);

export { default as CUPolicyChangeWizard } from './CUPolicyChangeWizard';