import React, {
    useState,
    useContext,
    useCallback,
    useEffect,
    useRef,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import { ServiceManager } from '@jutro/legacy/services';
import {
    WniDateUtil,
    QuoteUtil,
    TermTypeUtil,
    ErrorsAndWarningsUtil,
    WniQuestionSetUtil,
    WniPNIUtil,
    WindowUtil,
    IssuanceValidationUtil,
} from 'wni-portals-util-js';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react'
import { useValidation } from '@xengage/gw-portals-validation-react';
import {
    AgencyAndAgentInfoComponent,
    // PAUnderwritingQuestionsInput,
    PrimaryNamedInsuredInputPopup,
} from 'wni-capability-gateway-react';
import { R1Config, WizardConstants } from 'wni-portals-config-js';
import { QuestionSetComponent } from 'gw-components-platform-react';
// eslint-disable-next-line import/no-unresolved
import questionSetsMetadata from 'question-sets-metadata';
import { ValidationIssuesComponent, AsteriskIsRequiredNotification, useWniModal, useFeatureToggle } from 'wni-components-platform-react';
import { Loader } from '@jutro/components';
import { WniPAQuoteService, WniCommonQuoteService } from 'wni-capability-quoteandbind';
import { WniAccountService } from 'wni-capability-gateway';
import { useProductsData, DomRenderUtil } from 'wni-portals-util-react';
import metadata from './PAPolicyDetailsPage.metadata.json5';
import messages from './PAPolicyDetailsPage.messages';

const TERM_TYPE_OTHER = 'Other';

const PAP_STATES = ['AK', 'AZ', 'IA', 'MN', 'SD', 'WI'];

function PAPolicyDetailsPage(props) {
    const modalApi = useWniModal();
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const {
        initialValidation,
        isComponentValid,
        disregardFieldValidation,
        onValidate,
        registerComponentValidation,
        invalidFields
    } = useValidation('PAPolicyDetailsPage');
    // const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const {
        wizardData: submissionVM,
        wizardSnapshot,
        updateWizardData,
        updateWizardSnapshot,
        updateWizardPageData,
        // currentStepIndex,
        isSkipping,
    } = props;
    const { authHeader } = useAuthentication();
    const [showErrors, updateShowErrors] = useState(false);
    const [showLoader, updateShowLoader] = useState(false);
    const [noProductForEffectiveDate, updateNoProductForEffectiveDate] = useState(false);
    const [validationIssuesWarning, updateValidationIssuesWarning] = useState([]);
    const originPeriodStartDate = useRef(_.get(submissionVM.value, 'baseData.periodStartDate'));

    const {
        quoteID,
        sessionUUID,
        baseData: {
            baseState_Ext: policyState,
            quoteFlow_Ext: quoteFlow,
            selectedVersion_Ext: selectedVersion,
            policyInfoSource_Ext: policyInfoSource,
            primaryNamedInsured_Ext: PNI,
            isCopiedSubmission_Ext: isCopiedSubmission,
        },
        lobData: {
            personalAuto: {
                offerings
            }
        }
    } = submissionVM.value;

    const selectedVersionOfferingsIndex = offerings
        .findIndex((offering) => offering.publicID_Ext === selectedVersion);

    const featureToggle = useFeatureToggle();
    const enablePAforArizona = featureToggle.getFeatureCodeValue('EnablePAforArizona');

    // const quoteFlow = _.get(submissionVM, 'value.quoteFlow_Ext');

    const handlePolicyTypeChange = useCallback(async (isForNamedNonOwner) => {
        if (_.get(submissionVM.value, 'lobData.personalAuto.isForNamedNonOwner_Ext') === isForNamedNonOwner) {
            return;
        }
        const coveragePath = `lobData.personalAuto.offerings.children[${selectedVersionOfferingsIndex}].coverages.lineCoverages`;
        const oldSubmissionVM = viewModelService.clone(submissionVM);
        updateShowLoader(true);

        const termTypeCode = _.get(submissionVM.value, 'baseData.termType');
        const quoteBaseDataWithOldPeriodStart = {
            ..._.get(submissionVM.value, 'baseData'),
            periodStartDate: originPeriodStartDate.current
        };
        if (TERM_TYPE_OTHER !== termTypeCode) {
            const periodEndDateWithOriginPeriodStartDate = WniDateUtil
                .getPolicyEndDate(originPeriodStartDate.current, termTypeCode);

            _.set(quoteBaseDataWithOldPeriodStart, 'periodEndDate', periodEndDateWithOriginPeriodStartDate);
        }

        const request = {
            quoteID,
            sessionUUID,
            lineCode: 'PersonalAutoLine',
            isForNamedNonOwner: isForNamedNonOwner,
            quoteBaseData: quoteBaseDataWithOldPeriodStart,
            firstPreQualQuestionSet: _.get(submissionVM.value, `lobData.personalAuto.preQualQuestionSets[${selectedVersionOfferingsIndex}]`),
            flowStepID: 'PAPolicyDetails',
        };
        submissionVM.value = await WniPAQuoteService.changePolicyType(request, authHeader);
        // keep old period start date and end date
        const oldPeriodStartDate = _.get(oldSubmissionVM, 'baseData.effectiveDate_Ext.value');
        if (TERM_TYPE_OTHER !== termTypeCode) {
            const newPeriodEndDate = WniDateUtil.getPolicyEndDate(oldPeriodStartDate, termTypeCode);

            _.set(submissionVM.value.baseData, 'periodEndDate', newPeriodEndDate);
        }
        _.set(submissionVM.value.baseData, 'periodStartDate', oldPeriodStartDate);
        _.set(submissionVM.value.baseData, 'effectiveDate_Ext', oldPeriodStartDate);

        const fieldsToRemoveFromValidation = ClausesUtil.getRemovedClausesID(
            oldSubmissionVM, submissionVM, coveragePath
        );
        disregardFieldValidation(fieldsToRemoveFromValidation);
        updateWizardSnapshot(submissionVM);
        updateShowLoader(false);
    }, [
        authHeader,
        submissionVM,
        updateWizardSnapshot,
        disregardFieldValidation,
        viewModelService,
        quoteID,
        sessionUUID,
        selectedVersionOfferingsIndex,
    ]);


    const isAgencyAndAgentInfoValid = useCallback(() => {
        const {
            baseData: {
                producerCode_Ext: produerCode,
                producerOrLicensedAgent_Ext: producerOrLicensedAgent,
            },
        } = submissionVM.value;
        if (!_.isEmpty(produerCode)
            && !_.isEmpty(producerOrLicensedAgent)
        ) {
            return true;
        }
        return false;
    }, [submissionVM]);

    const PAPolicyDetailsValidation = useCallback(() => {
        // const questionAnswers = _.get(
        //     submissionVM.value,
        //     'lobData.personalAuto.preQualQuestionSets[0].answers',
        //     []
        // );
        return isAgencyAndAgentInfoValid()
            && WniPNIUtil.isPNIValid(PNI);
        // && WniQuestionSetUtil.isPAPreQualQuestionValid(questionAnswers);
    }, [PNI, isAgencyAndAgentInfoValid]);

    useEffect(() => {
        registerComponentValidation(PAPolicyDetailsValidation);
    }, [registerComponentValidation, PAPolicyDetailsValidation]);

    const getErrorsAndWarnings = useCallback(async () => {
        const errorsAndWarnings = await WniCommonQuoteService.getErrorsAndWarnings(
            quoteID, sessionUUID, 'PAPolicyDetails', authHeader
        );
        // This will mark all the following pages unvisisted,
        // i.e. they will be greyed out in the WizardSidebar
        // _.set(submissionVM, 'errorsAndWarnings', errorsAndWarnings);
        // updateWizardData(submissionVM);
        const newValidationIssues = ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings);
        updateValidationIssuesWarning(newValidationIssues);
    }, [
        authHeader,
        quoteID,
        sessionUUID,
        // submissionVM,
        // updateWizardData
    ]);

    useEffect(() => {
        const PNIRelationshipToInsured = _.get(submissionVM, 'value.baseData.primaryNamedInsured_Ext.relationshipToInsured_Ext', null);
        if (!PNIRelationshipToInsured) {
            _.set(submissionVM.baseData, 'primaryNamedInsured_Ext.relationshipToInsured_Ext', 'Insured_Ext');
            updateWizardData(submissionVM);
            updateWizardSnapshot(submissionVM);
        }
        // WizardUtil.updateNamedNonOwnerPageStep({
        //     isForNamedNonOwner,
        //     initialSteps,
        //     steps,
        //     changeNextSteps,
        //     driversPageId: 'PADriversPage',
        //     driverCoveragesPageId: 'PADriversCoveragesPage',
        // });
    }, []);

    useEffect(() => {
        const jobType = _.get(submissionVM.value, 'baseData.jobType', 'Submission');
        if (!isSkipping
            && (quoteFlow === 'finishquote' || quoteFlow === 'submitquote')
            && jobType !== 'PolicyChange'
        ) {
            getErrorsAndWarnings();
        }
    }, [isSkipping]);

        useEffect(() => {
            const policyStateValue = PAP_STATES.includes(policyState) ? policyState : 'IA';
            _.set(submissionVM.baseData, 'baseState_Ext', policyStateValue);
            updateWizardData(submissionVM);
        }, []);  

    
    const onTermTypeChange = (newTermType) => {
        // const periodStartDate = _.get(submissionVM, 'baseData.periodStartDate.value');
        const effectiveDate = _.get(submissionVM, 'baseData.effectiveDate_Ext.value');
        const termTypeCode = newTermType;
        if (TERM_TYPE_OTHER !== termTypeCode) {
            const newPeriodEndDate = WniDateUtil.getPolicyEndDate(effectiveDate, termTypeCode);

            _.set(submissionVM.baseData, 'periodEndDate', newPeriodEndDate);
        }
        _.set(submissionVM.baseData, 'termType', newTermType);
        updateWizardData(submissionVM);
    };

    const onStartDateChange = async (newPeriodStartDate) => {
        const termType = _.get(submissionVM, 'baseData.termType.value');
        const { code: termTypeCode } = termType;
        if (TERM_TYPE_OTHER !== termTypeCode) {
            const newPeriodEndDate = WniDateUtil.getPolicyEndDate(newPeriodStartDate, termTypeCode);
            _.set(submissionVM.baseData, 'periodEndDate', newPeriodEndDate);
        }
        _.set(submissionVM.baseData, 'periodStartDate', newPeriodStartDate);
        _.set(submissionVM.baseData, 'effectiveDate_Ext', newPeriodStartDate);
        updateWizardData(submissionVM);
        // Check whether PA line is still available for new start date
        const productCodeResponse = newPeriodStartDate ? await WniAccountService
            .getAvailableProductsForAccount(
                _.get(submissionVM, 'baseData.accountNumber.value'),
                policyState,
                ServiceManager.getService('locale-service').getDefaultCountryCode(),
                newPeriodStartDate,
                _.get(submissionVM, 'baseData.producerCode_Ext.value'),
                _.get(submissionVM, 'baseData.producerCodePublicID_Ext.value'),
                authHeader
            ) : [];
        const availableProductCodes = _.isEmpty(productCodeResponse) ? []
            : productCodeResponse.filter((productCode) => productCode.status === 'Available');

        // product is not available
        if (!availableProductCodes
            .find(
                (availableProductCode) => availableProductCode.productCode
                        === _.get(submissionVM, 'baseData.productCode.value')
            )) {
            updateNoProductForEffectiveDate(true);
        } else {
            updateNoProductForEffectiveDate(false);
        }
    };

    const isTermTypeOther = () => {
        const currentTermType = _.get(submissionVM, 'baseData.termType.value');
        return TERM_TYPE_OTHER === currentTermType.code;
    };

    const handleAgencyChange = (value, path, isUpdateSnapshot = false) => {
        if (path === 'producerCode_Ext' && _.isObject(value)) {
            _.set(submissionVM.baseData, 'producerOrLicensedAgent_Ext', '');
            _.set(submissionVM.baseData, 'servicingAgent_Ext', '');
            _.set(submissionVM.baseData, 'producerCodePublicID_Ext', value.publicID);
            _.set(submissionVM.baseData, path, value.code);
        } else {
            if (_.get(submissionVM.value.baseData, path) === value) {
                // If value is not updated, then no need to call updateWizardData()
                return;
            }
            _.set(submissionVM.baseData, path, value);
        }
        updateWizardData(submissionVM);
        if (isUpdateSnapshot) {
            updateWizardSnapshot(submissionVM);
        }
    };

    const showPrimaryNamedInsuredModal = useCallback(
        (primaryNamedInsuredVM, policyNamedInsuredCandidatesVM) => {
            const componentProps = {
                iconClassType: false,
                showCloseBtn: false,
                showCancelBtn: false,
                primaryNamedInsuredVM,
                policyNamedInsuredCandidatesVM,
                viewModelService,
                authHeader,
                disablePNISwitch: isCopiedSubmission,
            };
            return modalApi.showModal(
                <PrimaryNamedInsuredInputPopup {...componentProps} />
            );
        }, [viewModelService, authHeader, isCopiedSubmission]
    );

    const openEditPrimaryNamedInsuredPopup = useCallback(() => {
        const primaryNamedInsuredVM = _.get(submissionVM, 'baseData.primaryNamedInsured_Ext');
        const policyNamedInsuredCandidatesVM = _.get(
            submissionVM,
            'baseData.policyNamedInsuredCandidates_Ext'
        );
        showPrimaryNamedInsuredModal(primaryNamedInsuredVM, policyNamedInsuredCandidatesVM)
            .then((updatedVM) => {
                _.set(submissionVM.value, 'baseData.primaryNamedInsured_Ext', updatedVM.value);

                const newAddress = _.get(updatedVM.value, 'primaryAddress');
                _.set(submissionVM.value, 'baseData.policyAddress', newAddress);

                // Find updated account contact
                const accountContactsVM = _.get(
                    submissionVM,
                    'baseData.accountContacts_Ext'
                );
                const accountHolder = _.get(submissionVM.value, 'baseData.accountHolder');
                const newNamedInuseredVMIndex = _.get(accountContactsVM, 'value', []).findIndex(
                    (accountContactVM) => accountContactVM.publicID === _.get(updatedVM, 'value.publicID')
                );
                _.set(submissionVM.value, `baseData.accountContacts_Ext[${newNamedInuseredVMIndex}]`, updatedVM.value);
                if (accountHolder.publicID === _.get(updatedVM, 'value.publicID')) {
                    _.set(submissionVM.value, 'baseData.accountHolder', updatedVM.value);
                }
                updateWizardData(submissionVM);
            }).catch(() => _.noop());
    }, [showPrimaryNamedInsuredModal, submissionVM, updateWizardData, isCopiedSubmission]);

    const getPrimaryNamedInsuredDisplayName = useCallback(() => {
        const firstName = _.get(submissionVM, 'baseData.primaryNamedInsured_Ext.firstName.value');
        const lastName = _.get(submissionVM, 'baseData.primaryNamedInsured_Ext.lastName.value');
        return `${firstName} ${lastName}`;
    }, [submissionVM]);

    const getPrimaryPhoneNumberDisplay = useCallback(() => {
        let phoneNumber = '';
        phoneNumber = WniPNIUtil.getPrimaryPhoneNumberDisplay(_.get(submissionVM, 'baseData.primaryNamedInsured_Ext'));
        if(phoneNumber === '') {
            return <span className="font-error-light-16">* Primary Phone Required</span>;
        }
        return phoneNumber;
    }, [submissionVM]);

    const isShowNotification = useCallback(() => {
        const policyInfoSource = _.get(submissionVM, 'baseData.PolicyInfoSource_Ext');
        if ((policyInfoSource) && policyInfoSource === 'Coverted') {
            return true;
        }
        return false;
    }, [submissionVM]);

    const loadCovertPolicyInfo = useCallback(() => {
        const currentEffectiveDate = _.get(submissionVM, 'baseData.effectiveDate_Ext.value');
        const infoDate = WniDateUtil.formatDateWithPattern(moment(currentEffectiveDate).subtract(34, 'days'));
        const covertInfo = messages.covertedPolicyInfoStart.defaultMessage
        + infoDate + messages.covertedPolicyInfoEnd.defaultMessage;
        return covertInfo;
    }, [submissionVM]);

    const getFrontendValiationIssuesWarning = useCallback(() => {
        const quoteFlowExt = _.get(submissionVM.value, 'baseData.quoteFlow_Ext', '');
        if (quoteFlowExt !== 'draft') {
            return [];
        }
        const questionSet = questionSetsMetadata.PAPolicyInfoUWQuestions.orderedQuestions;
        return WniQuestionSetUtil.getQSWarningQuestionsByAnswersAndState(
            _.get(submissionVM.value, 'lobData.personalAuto.preQualQuestionSets[0].answers', []),
            policyState
        ).map((code) => {
            return {
                type: 'warning',
                // POI-17852: The beginning description of the warning messages are incorrect for both Policy Details and Quote screen
                reason: `Below required fields for issuance are missing: "${WniQuestionSetUtil.getQuestionNameInWarningMessage(
                    questionSet,
                    code,
                    translator
                )}"`
            };
        });
    }, [submissionVM, translator, policyState]);

    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
            WindowUtil.scrollToInvalidField(invalidFields);
            return false;
        },
        [updateShowErrors, invalidFields]
    );

    const hasChangesOnPolicyDetailsPage = useCallback(() => {
        const validationPath = [
            'baseData',
            'lobData.personalAuto.preQualQuestionSets'
        ];
        let hasChanges = false;
        validationPath.forEach((path) => {
            const preValue = _.get(wizardSnapshot.value, path, {});
            const curValue = _.get(submissionVM.value, path, {});
            if (!_.isEqual(preValue, curValue)) {
                hasChanges = true;
            }
        });
        return hasChanges;
    }, [wizardSnapshot, submissionVM]);

    const onNext = useCallback(async () => {
        _.unset(submissionVM.value, 'bindData');

        const hasQuoteValidationError = ErrorsAndWarningsUtil.hasValidationError(_.get(submissionVM.value, 'errorsAndWarnings', {}));
        let hasExistingValidationError = false;
        if (enablePAforArizona === undefined) {
            hasExistingValidationError = validationIssuesWarning.some((issue) => issue.type === 'error');
        } else 
            if (!_.isEmpty(validationIssuesWarning)) {
            hasExistingValidationError = validationIssuesWarning.some((issue) => issue.type === 'error');
        }
        const hasValidationError = hasQuoteValidationError || hasExistingValidationError;
        if (!hasChangesOnPolicyDetailsPage() && hasValidationError) {
            return false;
        }

        submissionVM.value = await WniPAQuoteService.updatePolicyDetails(
            quoteID,
            sessionUUID,
            _.get(submissionVM.value, 'baseData'),
            _.get(submissionVM.value, 'lobData.personalAuto.preQualQuestionSets[0]'),
            'PAPolicyDetails',
            authHeader
        );
        const oldValidationIssuesWarning = validationIssuesWarning;
        const backendErrorsAndWarnings = _.get(submissionVM.value, 'errorsAndWarnings');
        const backendValidationIssuesWarning = ErrorsAndWarningsUtil
            .getValidationIssues(backendErrorsAndWarnings);
        const frontendValidationIssuesWarning = getFrontendValiationIssuesWarning();
        const allValidationIssuesWarning = backendValidationIssuesWarning
            .concat(frontendValidationIssuesWarning);
        if (quoteFlow === 'submitquote' || quoteFlow === 'finishquote') {
            updateValidationIssuesWarning(backendValidationIssuesWarning);
        } else {
            updateValidationIssuesWarning(allValidationIssuesWarning);
        }
        if (ErrorsAndWarningsUtil
            .hasNewValidationIssue(oldValidationIssuesWarning, allValidationIssuesWarning)
                || ErrorsAndWarningsUtil.hasValidationError(_.get(submissionVM.value, 'errorsAndWarnings', {}))
        ) {
            updateWizardSnapshot(submissionVM);
            updateShowErrors(true);
            return false;
        }
        // clear payment cache.
        updateWizardPageData({
            [WizardConstants.paymentMethods]: undefined,
            [WizardConstants.dueDateExt]: undefined,
            [WizardConstants.paymentPlans]: undefined,
        });
        return submissionVM;
    }, [
        quoteFlow,
        submissionVM,
        updateWizardSnapshot,
        authHeader,
        validationIssuesWarning,
        quoteID,
        sessionUUID,
        updateWizardPageData,
        getFrontendValiationIssuesWarning,
        hasChangesOnPolicyDetailsPage]);


    //-----------------------------------------
    const questionSetFilter = WniQuestionSetUtil.getQSFilterForPolicyDetails(policyState);
    const questionSetMapper = WniQuestionSetUtil.getQSMapperForPASubmissionPolicyDetails();
    // const questionSetGetInvalidAnswerKeys = WniQuestionSetUtil.getPAInvalidAnswerKeys();

    const getMinDate = useCallback(() => {
        if (quoteFlow !== 'draft' && policyInfoSource !== 'Converted') {
            return moment().subtract(5, 'd').startOf('day').toDate();
        }
        return moment().startOf('day').toDate();
    }, [quoteFlow, policyInfoSource]);

    const getCoverageStartDateOverrideProps = () => {
        const periodStartDate = _.get(submissionVM.value, 'baseData.effectiveDate_Ext');
        const coverageStartDateOverrideProps = {
            minDate: getMinDate(),
            onValueChange: onStartDateChange,
            value: periodStartDate,
            showErrors: showErrors || noProductForEffectiveDate
        };

        // The priority of past date validation is higher than no product validation
        // If date is not valid, don't show no product validation message
        if (noProductForEffectiveDate
            && !WniDateUtil.compareWithMinDateByQuoteFlow(periodStartDate, quoteFlow)
        ) {
            _.set(coverageStartDateOverrideProps, 'validationMessages',
                [translator(R1Config.R1ValidationMessages.R1NoProductCode)]);
        }
        return coverageStartDateOverrideProps;
    };

    const renderPrimaryNamedInsuredValue = () => {
        return (
            <div>
                <div>{getPrimaryNamedInsuredDisplayName()}</div>
                <div>{WniPNIUtil.getPrimaryAddressDisplayName(_.get(submissionVM, 'baseData.primaryNamedInsured_Ext.primaryAddress'))}</div>
                <div>{getPrimaryPhoneNumberDisplay()}</div>
            </div>
        )
    }
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        asteriskIsRequiredNotification: {
            visible: quoteFlow === 'draft'
        },
        dynamicInlineNotificationContainer: {
            // validationIssues: validationIssuesWarning,
            visible: validationIssuesWarning.length > 0,
            scrollToIssues: true,
            validationIssues: IssuanceValidationUtil.getIssuesMap(validationIssuesWarning),
            issueRenderFn: DomRenderUtil.issueRenderFn,
        },
        coverageStartDate: {
            ...getCoverageStartDateOverrideProps()
        },
        paTermTypedropdownselect: {
            onValueChange: onTermTypeChange,
            availableValues: TermTypeUtil.getPATermTypeAvailableValues(translator)
        },
        coverageExpirationDate: {
            readOnly: !isTermTypeOther(),
        },
        primaryNamedInsuredField: {
            value: renderPrimaryNamedInsuredValue()
        },
        primaryNamedInsuredGrid: {
            className: WniPNIUtil.isPNIValid(PNI) ? 'form-with-address-group' : 'form-with-address-group-error-state'
        },
        primaryNamedInsuredEditIcon: {
            onClick: openEditPrimaryNamedInsuredPopup,
        },
        agencyAndAgentInfo: {
            model: submissionVM.baseData,
            onAgencyChange: handleAgencyChange,
            displayFields: {
                agencyOfRecord: true,
                licensedAgent: true,
                servicingAgent: true
            },
            producerCodePath: 'producerCode_Ext',
            shouldUpdateAgentOptions: !isSkipping,
            onValidate,
        },
        isForNamedNonOwner_Ext: {
            onValueChange: handlePolicyTypeChange
        },
        covertNotification: {
            visible: isShowNotification(),
            message: loadCovertPolicyInfo()
        },
        policyUnderwritingQuestionSets: {
            contentFilter: questionSetFilter,
            contentMapper: questionSetMapper,
            onValidate,
            // getInvalidAnswerKeys: questionSetGetInvalidAnswerKeys,
        }
    };

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            agencyagentinfo: AgencyAndAgentInfoComponent,
            validationissuescomponent: ValidationIssuesComponent,
            // paunderwritingquestionsinput: PAUnderwritingQuestionsInput,
            questionset: QuestionSetComponent,
            asteriskIsRequiredNotification: AsteriskIsRequiredNotification,
        }
    };

    return (
        <WizardPage
            // onNext={isComponentValid && !isMNStateAndNotLicenseState() ? onNext : handleValidation}
            onNext={isComponentValid ? onNext : handleValidation}
            showPrevious={false}
            skipWhen={QuoteUtil.getSkipRatedQuotedFn(initialValidation)}
            alwaysCallOnNext={
                ErrorsAndWarningsUtil.hasValidationIssue(_.get(submissionVM.value, 'errorsAndWarnings', {}), null)
                || validationIssuesWarning.length > 0
            }
            // disableNext={!isComponentValid}
            disableNext={showLoader || noProductForEffectiveDate}
        >
            {
                showLoader ? (<Loader showLoader />) : (
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        onModelChange={updateWizardData}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.resolveCallbackMap}
                        componentMap={resolvers.resolveComponentMap}
                        showErrors={showErrors}
                    />
                )
            }
        </WizardPage>
    );
}

PAPolicyDetailsPage.propTypes = {
    ...wizardProps
};
export default PAPolicyDetailsPage;