import React, { useCallback, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { WizardPageTemplate } from 'gw-portals-wizard-components-ui';
import { DateUtil } from '@xengage/gw-portals-util-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './WhatPage.metadata.json5';
import styles from './WhatPage.module.scss';

function FNOLCPWhatPage(props) {
    const translator = useTranslator();
    const {
        wizardData: claimVM,
        updateWizardData,
        history: { location: { state = {} } }
    } = props;
    const {
        onValidate, isComponentValid, initialValidation, registerInitialComponentValidation
    } = useValidation('FNOLCPWhatPage');

    const overrideProps = {
        '@field': {
            showOptional: true,
            phoneWide: {
                labelPosition: 'top'
            }
        },
        dateOfNotice: {
            phoneWideTime: {
                labelPosition: 'top'
            },
        }
    };
    const resolvers = {
        resolveClassNameMap: styles
    };
    const { claimStatus } = state;

    const validateClaimStatus = useCallback(() => {
        return !_.isEmpty(claimStatus) && claimStatus === translator({ id: 'typekey.ClaimState.draft', defaultMessage: 'Draft' });
    }, [claimStatus, translator]);

    useEffect(() => {
        _.set(claimVM, 'lobs.commercialProperty.dateOfNotice',
            _.get(claimVM, 'lobs.commercialProperty.dateOfNotice.value')
            || DateUtil.currentDateAtMidnight());
        updateWizardData(claimVM);
        // Should be called once when page is rendered
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        registerInitialComponentValidation(validateClaimStatus);
    }, [validateClaimStatus, registerInitialComponentValidation]);

    return (
        <WizardPage
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
            template={WizardPageTemplate}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

FNOLCPWhatPage.propTypes = wizardProps;
export default withRouter(FNOLCPWhatPage);
