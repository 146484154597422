import { MetadataContent } from '@jutro/legacy/uiconfig';
import _ from 'lodash';
import React from 'react';
import { getFieldModel } from 'wni-components-platform-react/components/FieldSetMode/FieldSetModel.util';
// import messages from '../../CAVehiclesPage.messages';
import { isNumberValid } from 'wni-components-platform-react'

const DetailsFieldMap = (props) => {
    const {
        vm,
        dataPath,
        onValueChange,
        onValidate,
        onBlur,
        onSearch,
        showErrors,
        isReadOnly,
        filterSizeClassMapsInChange = []
    } = props;
    
    const fieldModels = _.get(vm?.value, dataPath, []);

    /**
     * for policy change story（POI-2577） Size Class - Similar rule to vehicle type - 
     * cannot add a heavy, extra heavy, heavy trailer or extra heavy trailer unless there is an existing vehicle with that size class already on the policy pre-change
     * @param {Array} options 
     * @returns 
     */
    const filterAvailableValues = (fieldItem = {}) => {
        const { rangeValueOptions, rangeValue } = fieldItem;
        const newFilterSizeClassMapsInChange = filterSizeClassMapsInChange.filter((item) => item !== rangeValue);
        const options = rangeValueOptions.filter((item) => !_.includes(newFilterSizeClassMapsInChange, item));
        return options;
    }

    const handleValueChange = (newValue, index, fieldModel) => {
        onValueChange(newValue, `${dataPath}[${index}]`, fieldModel)
    };

    const handleBlur = (value, fieldItem) => {
        if(!isNumberValid(value, fieldItem)) {
            return false;
        }
        onBlur(value, fieldItem)
    };

    // const registerAdditionalValidation = () => {
    //     return isOrigionalCostNewValid(vm.value);
    // }
    const metadata = fieldModels.map((fieldItem, index) => {

        const needToAddTooltipMap = {
            CEOccupancyDesc_Ext:
                'If building is mixed use, select description associated to the largest percentage of occupancy.',
            SubStdConditionA: (
                <ul className="tooltipList">
                    <li>
                        Sub-Standard classification indicates that a building
                        would not be habitable or suitable due to conditions in
                        the specified sections:
                    </li>
                    <li>Sub-Standard A - Heating and Cooling</li>
                    <li>Sub-Standard B - Wiring</li>
                    <li>Sub-Standard C - Conversion</li>
                    <li>
                        Sub-Standard D - Physical Condition and Housekeeping
                    </li>
                    <li>Sub-Standard E - Exposure</li>
                </ul>
            ),
        };
        
        let additionalProps = {
            tooltip: {
                text:!_.isUndefined(needToAddTooltipMap[fieldItem.propertyName])
                ? needToAddTooltipMap[fieldItem.propertyName]
                : null
            }
        };

        if (fieldItem.propertyName === 'SizeClass') {
            additionalProps = {
                availableValues: filterAvailableValues(fieldItem),
            };
        };
        if (fieldItem.propertyName === 'OpenSides') {
            additionalProps = {
                alwaysShowPlaceholder: true,
                placeholder: 'None'
            };
        };

        // follow pcf logic, if no options but have rangeValue, in special class business income, coverge choose Business Income (And Extra Expense) Coverage Form - 12 Month Actual Loss, Extended Period Applies will have no options
        // commonet out because PC has issue on this field, below soulution can't handle all senarios
        // if (_.isEmpty(fieldItem.rangeValueOptions) && !_.isNil(fieldItem.rangeValue)) {
        //     const {component} = getFieldModel(fieldItem.inputSetMode);
        //     if (component === 'WniDropdownSelect') {
        //         Object.assign(fieldItem, {'rangeValueOptions': [fieldItem.rangeValue], 'readonly': true});
        //     }
        // }

        return {
            id: `${fieldItem.propertyName}Field`,
            type: 'field',
            component: 'WniFieldSetModel',
            componentProps: {
                className: 'fieldLayout_1_1',
                showRequired: true,
                showErrors: showErrors,
                readOnly: isReadOnly || fieldItem.readonly || !fieldItem.editable,
                onValueChange: (value, fieldModel) => handleValueChange(value, index, fieldModel),
                onBlur: (value) => handleBlur(value, fieldItem),
                fieldItem,
                onValidate,
                onSearch,
                ...additionalProps
            }
        };
    });

    const overrides = {};
    const resolvers = {
        resolveComponentMap: {
        },
    };

    return (
        <MetadataContent
            uiProps={metadata}
            overrideProps={overrides}
            {...resolvers}
        />
    );
};

export default DetailsFieldMap;
