
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { WizardConstants } from 'wni-portals-config-js';
import { WizardContext } from '@xengage/gw-portals-wizard-react';
import { ActiveQuoteUtil, WniGoLiveUtil } from 'wni-portals-util-js';

import { useBusinessData, useProductsData } from 'wni-portals-util-react';
import ActiveQuotesListComponent from '../ActiveQuotesList/ActiveQuotesListComponent';

// See ActiveQuotesWithWizardContext for another solution
function WizardActiveQuotesListComponent(props) {
    // const {
    // } = props;

    const {
        wizardData: submissionVM,
        wizardPageData,
    } = useContext(WizardContext);

    const {
        jobID, 
        quoteID, // for backward compatibility
        baseData: {
            accountHolder: {
                primaryAddress: {
                    postalCode,
                }
            }
        }
    } = submissionVM.value;

    const {
        [WizardConstants.accountActiveQuotes]: accountActiveQuotes,
    } = wizardPageData;

    const [accountJobs, updateAccountJobs] = useState([]);
    
    const actualJobID = jobID || quoteID;

    const { enableCommercialLine } = useBusinessData();
    const { getProductItem } = useProductsData();

    useEffect(() => {
        let otherActiveJobs = ActiveQuoteUtil.filterActiveJobDTOsByJobID(accountActiveQuotes, actualJobID);

        otherActiveJobs = WniGoLiveUtil.filterActiveQuotesByGoLiveConfig(otherActiveJobs, enableCommercialLine, getProductItem);

        updateAccountJobs(otherActiveJobs);
    }, [accountActiveQuotes, actualJobID]);

    // ==================
    if (_.isEmpty(accountJobs)) {
        return null;
    }
    return (
        <ActiveQuotesListComponent
            accountJobs={accountJobs}
            postalCode={postalCode}
        />
    );
}

WizardActiveQuotesListComponent.propTypes = {
    // postalCode: PropTypes.string.isRequired,
    // jobID: PropTypes.string,
};

WizardActiveQuotesListComponent.defaultProps = {
    // postalCode: undefined,
    // jobID: undefined,
};

export default WizardActiveQuotesListComponent;
