import React, {useState, useCallback, useEffect} from 'react'
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useTranslator } from '@jutro/locale';
import {
    QuoteUtil
} from 'wni-portals-util-js';
import { PortalConstants } from 'wni-portals-config-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { IMElectronicDataProcessingService as  ElectronicDataProcessingService} from 'wni-capability-quoteandbind-im';
import CoveragesConfigContext from '../../context/IMCoveragesConfigContext';
import IMCoveragesConfig from '../../config/IMCoverages.config';
import metadata from './ElectronicDataProcessingPage.metadata.json5';
import WizardPage from '../../templates/IMWizardPage';
import IMCommonMainCoverage from '../../components/IMCommonMainCoverage/MainCoveragesCard';
import IMCommonCovPartSchedule from '../../components/IMComonCovPartSchedule/CommonCovPartSchedule';
import IMCommonAdditionalCovCard from '../../components/IMCommonAdditionalCovCard/IMCommonAdditionalCovCard';
import IMExclusionAccordionCard from '../../components/IMCommonExclusionCard/IMCommonExclusionCard';
import LineMainCoveragesComponent from './LineMainCoveragesCard/LineMainCoveragesComponent';
import messages from './ElectronicDataProcessingPage.messages';

const ElectronicDataProcessingReadOnlyPage = (props) => {
    const {
        wizardData: submissionVM,
    } = props;

    const { initialValidation, onValidate, } = useValidation('ElectronicDataProcessingReadOnlyPage');
    const { authHeader } = useAuthentication();
    const translator = useTranslator();
    const [isEditing, setIsEditing] = useState(false);
    const [electronicDataProcessingClauses, setElectronicDataProcessingClauses] = useState();
    const [electronicDataProcessingLoadingClauses, setElectronicDataProcessingLoadingClauses] = useState();

    const {
        jobID,
        sessionUUID,
        baseData: { 
            periodStatus
        }
    } = submissionVM.value

    const {
        loadingMask: { isLoadingMask, setLoadingMask },
    } = useDependencies('loadingMask');
    const initCoverablePartClauses = useCallback(async () => {
        setLoadingMask(true)
        const isDraftPeriod = periodStatus ===  PortalConstants.QUOTE_STATUS_DRAFT

        const res = isDraftPeriod ? await ElectronicDataProcessingService.getElectronicDataProcessingClausesWithSync(jobID, sessionUUID, authHeader) 
            : await ElectronicDataProcessingService.getElectronicDataProcessingClauses(jobID, sessionUUID, authHeader)
        setElectronicDataProcessingClauses(res)
        setLoadingMask(false)
    }, [authHeader, jobID, periodStatus, sessionUUID, setLoadingMask]) 

    useEffect(() => {
        initCoverablePartClauses()
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const onPageNext = () => {
        return submissionVM
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const accordionCommonProps = {
            coveragePartClauses: electronicDataProcessingClauses,
            setCoveragePartClauses: setElectronicDataProcessingClauses,
            isEditing: false,
            isEditable: false,
            onValidate: onValidate,
            loadingClauses: electronicDataProcessingLoadingClauses,
            setLoadingClauses: setElectronicDataProcessingLoadingClauses,
            wizardData: submissionVM,
        }

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                isEditable: false,
            },
            // accor
            electronicDataProcessingCoverage: {
            },
            leftCoverageMainCovsCard: {
                ...accordionCommonProps,
                coveragePatterns: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.leftCoverage'),
                coverageFormPath: 'electronicDataProcessingCov.leftCoverage'
            },
            rightCoverageMainCovsCard: {
                ...accordionCommonProps,
                coveragePatterns: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.rightCoverage'),
                coverageFormPath: 'electronicDataProcessingCov.rightCoverage'
            },
            incomeLeftCoverageMainCovsCard: {
                ...accordionCommonProps,
                coveragePatterns: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.incomeLeftCoverage'),
                coverageFormPath: 'electronicDataProcessingCov.incomeLeftCoverage'
            },
            incomeRightCoverageMainCovsCard: {
                ...accordionCommonProps,
                coveragePatterns: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.incomeRightCoverage'),
                coverageFormPath: 'electronicDataProcessingCov.incomeRightCoverage'
            },
            coinsuranceHardwareMediaProgramsand: {
                dataType: 'string',
                required: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.coinsuranceHardwareMediaProgramsand.required'),
                showRequired: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.coinsuranceHardwareMediaProgramsand.required'),
                availableValues: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.coinsuranceHardwareMediaProgramsand.options'),
                value: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.coinsuranceHardwareMediaProgramsand.chosenTerm'),
                label: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.coinsuranceHardwareMediaProgramsand.name'),
                readOnly: true
            },
            coinsuranceDataRecordsProprietary: {
                dataType: 'string',
                required: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.coinsuranceDataRecordsProprietary.required'),
                showRequired: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.coinsuranceDataRecordsProprietary.required'),
                availableValues: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.coinsuranceDataRecordsProprietary.options'),
                value: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.coinsuranceDataRecordsProprietary.chosenTerm'),
                label: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.coinsuranceDataRecordsProprietary.name'),
                visible: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.coinsuranceDataRecordsProprietaryVisible'),
                readOnly: true
            },
            coinsurancePart7200ScheduleCovCard: {
                ...accordionCommonProps,
                coveragePartSchedule: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.electronicDataProcessingScheduledCovIM7200'),
                updateScheduleService: ElectronicDataProcessingService.updateElectronicDataProcessing7200Schedule,
                schedulePath: 'electronicDataProcessingCov.coinsurancePart.electronicDataProcessingScheduledCovIM7200',
                scheduleLable: translator(messages.ListOfScheduleItems),
                scheduleFormHeader: translator(messages.ScheduleDetails),
                visible: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.electronicDataProcessingScheduledCovIM7200Visible'),
            },
            coinsurancePart7201ScheduleCovCard: {
                ...accordionCommonProps,
                coveragePartSchedule: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.electronicDataProcessingScheduledCovIM7201'),
                updateScheduleService: ElectronicDataProcessingService.updateElectronicDataProcessing7201Schedule,
                schedulePath: 'electronicDataProcessingCov.coinsurancePart.electronicDataProcessingScheduledCovIM7201',
                scheduleLable: translator(messages.ListOfScheduleItems),
                scheduleFormHeader: translator(messages.ScheduleDetails),
                visible: _.get(electronicDataProcessingClauses, 'electronicDataProcessingCov.coinsurancePart.electronicDataProcessingScheduledCovIM7201Visible'),
            },
            additionalAccordionCard: {
                ...accordionCommonProps,
                coveragePartName: 'electronicDataProcessing'
            },
            exclusionAccordionCard: {
                ...accordionCommonProps,
                coveragePartName: 'electronicDataProcessing'
            }
        }
    }, [accordionCommonProps, electronicDataProcessingClauses, translator]);

    
    const overrideProps = generateOverrides();
    const resolvers = {
        callbackMap: {
        },
        componentMap: {
            leftCoverageMainCovsCard: LineMainCoveragesComponent,
            // leftCoverageEquipmentScheduleCovCard: IMCommonCovPartSchedule,
            // leftCoverageAdditionalInsuredScheduleCovCard: IMCommonCovPartSchedule,
            rightCoverageMainCovsCard: LineMainCoveragesComponent,
            incomeLeftCoverageMainCovsCard: LineMainCoveragesComponent,
            incomeRightCoverageMainCovsCard: LineMainCoveragesComponent,
            AdditionalAccordionCard: IMCommonAdditionalCovCard,
            ExclusionAccordionCard: IMExclusionAccordionCard,
            coinsurancePart7200ScheduleCovCard: IMCommonCovPartSchedule,
            coinsurancePart7201ScheduleCovCard: IMCommonCovPartSchedule,
            // coinsuranceHardwareMediaProgramsand: IMCommonMainCoverage
        },
    };

    const disableNavigator = isEditing || isLoadingMask || electronicDataProcessingLoadingClauses

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            disableNext={disableNavigator}
            disablePrevious={disableNavigator}
            disableCancel={disableNavigator}
            onNext={onPageNext}
            alwaysCallOnNext
            showRequiredInfoForFasterQuote
        >
            {electronicDataProcessingClauses && 
                <CoveragesConfigContext.Provider value={IMCoveragesConfig}>
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.callbackMap}
                        componentMap={resolvers.componentMap}
                    />
                </CoveragesConfigContext.Provider>
            }
        </WizardPage>
    );
}

export default ElectronicDataProcessingReadOnlyPage
