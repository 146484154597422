import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import FNOLPAWizard from './FNOLPAWizard';
import FNOLPAVehiclesPage from './pages/Vehicles/VehiclesPage';
import FNOLPATypeOfIncidentPage from './pages/TypeOfIncident/TypeOfIncidentPage';
import FNOLPATypeOfRepairPage from './pages/TypeOfRepair/TypeOfRepairPage';
import FNOLPARepairFacilityPage from './pages/RepairFacility/RepairFacilityPage';
import FNOLPACollisionDetailsPage from './pages/Details/Collision/CollisionDetailsPage';
import FNOLPAGlassDetailsPage from './pages/Details/Glass/GlassDetailsPage';
import FNOLPATheftDetailsPage from './pages/Details/Theft/TheftDetailsPage';
import FNOLPARepairFacilityMapPage from './pages/RepairFacilityMap/RepairFacilityMapPage';

setComponentMapOverrides(
    {
        FNOLPAWizard: { component: 'FNOLPAWizard' },
        FNOLPATypeOfIncidentPage: { component: 'FNOLPATypeOfIncidentPage' },
        FNOLPAVehiclesPage: { component: 'FNOLPAVehiclesPage' },
        FNOLPATypeOfRepairPage: { component: 'FNOLPATypeOfRepairPage' },
        FNOLPARepairFacilityPage: { component: 'FNOLPARepairFacilityPage' },
        FNOLPACollisionDetailsPage: { component: 'FNOLPACollisionDetailsPage' },
        FNOLPAGlassDetailsPage: { component: 'FNOLPAGlassDetailsPage' },
        FNOLPATheftDetailsPage: { component: 'FNOLPATheftDetailsPage' },
        FNOLPARepairFacilityMapPage: { component: 'FNOLPARepairFacilityMapPage' }
    },
    {
        FNOLPAWizard,
        FNOLPATypeOfIncidentPage,
        FNOLPAVehiclesPage,
        FNOLPATypeOfRepairPage,
        FNOLPARepairFacilityPage,
        FNOLPACollisionDetailsPage,
        FNOLPAGlassDetailsPage,
        FNOLPATheftDetailsPage,
        FNOLPARepairFacilityMapPage
    }
);

export {
    FNOLPAWizard as default
};
