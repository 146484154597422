import { defineMessages } from 'react-intl';

export default defineMessages({
    paDetailsBrokenGlass: {
        id: 'fnol.pa.views.pa-glass-details.Details (Broken Glass)',
        defaultMessage: 'Details (Broken Glass)'
    },
    paGlassDamageDetails: {
        id: 'fnol.pa.views.pa-glass-details.Glass Damage Details',
        defaultMessage: 'Glass Damage Details'
    },
    paVehicleDamaged: {
        id: 'fnol.pa.views.pa-glass-details.Which vehicle was damaged?',
        defaultMessage: 'Which vehicle was damaged?'
    },
    paGlassChooseVehicle: {
        id: 'fnol.pa.views.pa-glass-details.Choose Vehicle',
        defaultMessage: 'Choose Vehicle'
    },
    paGlassWhatHappened: {
        id: 'fnol.pa.views.pa-glass-details.Please describe what happened',
        defaultMessage: 'Please describe what happened'
    }
});
