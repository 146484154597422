import { AccordionCard } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { IMMsicFormsService } from 'wni-capability-quoteandbind-im';
import { WniCheckboxField, WniDropdownSelect, WniInputNumberWithAction } from 'wni-common-base-components';
import MainCoveragesCard from '../../../../components/IMCommonMainCoverage/MainCoveragesCard';
import messages from './ColdStorageLockerCoverageCard.messages';
import CoveredPremisesSchedule from './CoveredPremisesSchedule/CoveredPremisesSchedule';

const DropdownTermField = (props) => {

    const {
        path,
        isEditable,
        coldStorageLockerCoverageVM,
        updateColdStorageLockerCoverage,
        onValidate: onColdStorageLockerCoverageValidate,
        showErrors,
    } = props;

    const id = `DropdownTermField_${path}`;

    const term = coldStorageLockerCoverageVM.value[path]

    const {
        chosenTerm,
        name,
        required,
        options,
    } = term


    const { onValidate, isComponentValid } = useValidation(id);

    useEffect(() => {
        if (onColdStorageLockerCoverageValidate) {
            onColdStorageLockerCoverageValidate(isComponentValid, id);
        }
        return () => {
            if (onColdStorageLockerCoverageValidate) {
                onColdStorageLockerCoverageValidate(true, id);
            }
        };
    }, [id, isComponentValid, onColdStorageLockerCoverageValidate]);

    return (
        <WniDropdownSelect
            id={id}
            label={name}
            className='fieldLayout_3_2'
            readOnly={!isEditable}
            onValueChange={(value) => {
                const newTerm = {
                    ...term,
                    updated: true,
                    chosenTerm: value
                }
                updateColdStorageLockerCoverage(newTerm, path)
            }}
            value={chosenTerm}
            availableValues={options}
            required={required}
            showRequired={required}
            onValidationChange={onValidate}
            showErrors={showErrors}
        />
    );
};

const NumberTermField = (props) => {

    const {
        path,
        isEditable,
        setIsEditing,
        coldStorageLockerCoverageVM,
        updateColdStorageLockerCoverage,
        onValidate: onColdStorageLockerCoverageValidate,
        showErrors,
        actionConfig,
    } = props;

    const id = `NumberTermField_${path}`;

    const term = coldStorageLockerCoverageVM.value[path]

    const {
        directValue: originValue,
        name,
        required,
    } = term

    const [editingValue, setEditingValue] = useState(originValue);

    const { onValidate, isComponentValid } = useValidation(id);

    useEffect(() => {
        if (onColdStorageLockerCoverageValidate) {
            onColdStorageLockerCoverageValidate(isComponentValid, id);
        }
        return () => {
            if (onColdStorageLockerCoverageValidate) {
                onColdStorageLockerCoverageValidate(true, id);
            }
        };
    }, [id, isComponentValid, onColdStorageLockerCoverageValidate]);

    return (
        <WniInputNumberWithAction
            id={id}
            label={name}
            readOnly={!isEditable}
            onValueChange={(value) => {
                setIsEditing(true)
                setEditingValue(value)
            }}
            onBlur={() => {
                setIsEditing(false)
                const newTerm = {
                    ...term,
                    updated: true,
                    directValue: editingValue
                }
                updateColdStorageLockerCoverage(newTerm, path)
            }}
            value={editingValue}
            showFractions={false}
            required={required}
            showRequired={required}
            onValidationChange={onValidate}
            showErrors={showErrors}
            actionVisible={!!actionConfig}
            actionConfig={actionConfig}
        />
    );
};

const ColdStorageLockerCoverageCard = (props) => {
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');
    const viewModelService = useContext(ViewModelServiceContext);

    const withLoadingMask = async (serviceCallFunc) => {
        setLoadingMask(true);
        const res = await serviceCallFunc();
        setLoadingMask(false);
        return res;
    };

    const {
        miscForms,
        setMiscForms,
        setIsEditing,
        isEditable,
        showErrors,
        onValidate: onPageValidate,

        wizardData: submissionVM,
        updateWizardDataWhileSetPeriodStatus,
        defaultOpenId
    } = props;

    const { coldStorageLockerCoverage } = miscForms;

    const coldStorageLockerCoverageVM = useMemo(
        () =>
            viewModelService.create(
                coldStorageLockerCoverage,
                'pc',
                'wni.edge.capabilities.quote.lob.inlandmarine.dto.miscforms.IMMiscFormsColdStorageLockerCoverageDTO'
            ),
        [coldStorageLockerCoverage, viewModelService]
    );

    const setColdStorageLockerCoverage = (newColdStorageLockerCoverage) => {
        setMiscForms({
            ...miscForms,
            coldStorageLockerCoverage: newColdStorageLockerCoverage,
        });
    };

    const translator = useTranslator();

    const validationID = 'ColdStorageLockerCoverageCard';

    const { jobID, sessionUUID } = submissionVM.value;

    const { onValidate, isComponentValid } = useValidation(validationID);
    const { authHeader } = useAuthentication();

    useEffect(() => {
        if (onPageValidate) {
            onPageValidate(isComponentValid, validationID);
        }
        return () => {
            if (onPageValidate) {
                onPageValidate(true, validationID);
            }
        };
    }, [isComponentValid, onPageValidate]);

    if (!coldStorageLockerCoverage) {
        return null
    }

    const updateColdStorageLockerCoverage = async (value, path) => {
        const coldStorageLockerCoverageForUpdate = {
            [path]: value,
        };
        const newColdStorageLockerCoverage = await withLoadingMask(() =>
            IMMsicFormsService.updateColdStorageLockerCoverage(
                jobID,
                sessionUUID,
                coldStorageLockerCoverageForUpdate,
                authHeader
            )
        );
        setColdStorageLockerCoverage(newColdStorageLockerCoverage);
    };

    const commonFieldProps = {
        isEditable,
        coldStorageLockerCoverageVM,
        updateColdStorageLockerCoverage,
        onValidate,
        showErrors,
    }

    const commonNumberTermProps = {
        setIsEditing,
        ...commonFieldProps
    }

    return (
        <AccordionCard
            id="ColdStorageLockerCoverageCard"
            type="container"
            initialExpanded={defaultOpenId === 'ColdStorageLockerCoverageCard'}
            chevron
            cardHeadingClassName="font-PrimaryDark-bold-16 font-capitalize"
            title={translator(messages.ColdStorageLockerCoverage)}
            errorState={showErrors && !isComponentValid}
        >
            <DropdownTermField
                path='coverageFormTerm'
                {...commonFieldProps}
            />
            <DropdownTermField
                path='deductibleAmountTerm'
                {...commonFieldProps}
            />
            <h4 className='mb-10'>{translator(messages.OptionalCoverage)}</h4>
            <WniCheckboxField
                id='isChangeInTOrHCov_Checkbox'
                label= {translator(messages.ChangeinTemperatureOrHumidityCoverage)}
                readOnly={!isEditable}
                onValueChange={(value) => updateColdStorageLockerCoverage(value, 'isChangeInTOrHCov')}
                labelPosition = 'right'
                showInlineLabel
                value={coldStorageLockerCoverage.isChangeInTOrHCov}
            />
            {coldStorageLockerCoverage.isChangeInTOrHCov && <>
                {coldStorageLockerCoverage.hasIMColdStorageLockerCoverageChangeinTemperatureOrHumidityCoverageLimitTerm && <NumberTermField
                    path='changeinTemperatureOrHumidityCoverageLimitTerm'
                    {...commonNumberTermProps}
                />}
                {coldStorageLockerCoverage.hasIMColdStorageLockerCoverageChangeinTemperatureOrHumidityCoverageDeductibleTerm && <DropdownTermField
                    path='changeinTemperatureOrHumidityCoverageDeductibleTerm'
                    {...commonFieldProps}
                />}
            </>}
            <h4 className='mb-10'>{translator(messages.CoverageExtensions)}</h4>
            <MainCoveragesCard
                id="CoverageExtensions"
                coveragePartClauses={coldStorageLockerCoverage}
                setCoveragePartClauses={setColdStorageLockerCoverage}
                setIsEditing={setIsEditing}
                isEditable={isEditable}
                showErrors={showErrors}
                onValidate={onValidate}
                wizardData={submissionVM}
                updateWizardDataWhileSetPeriodStatus={
                    updateWizardDataWhileSetPeriodStatus
                }
                mainCoverage={coldStorageLockerCoverage.extensionTermsCoverage}
                coverageFormPath="extensionTermsCoverage"
                updateCoverageFormClausesService={
                    IMMsicFormsService.updateColdStorageLockerCoverage
                }
            />
            <h4 className='mb-10'>{translator(messages.CoverageExtensions)}</h4>
            <MainCoveragesCard
                id="SupplementalCoverageTermCoverage"
                coveragePartClauses={coldStorageLockerCoverage}
                setCoveragePartClauses={setColdStorageLockerCoverage}
                setIsEditing={setIsEditing}
                isEditable={isEditable}
                showErrors={showErrors}
                onValidate={onValidate}
                wizardData={submissionVM}
                updateWizardDataWhileSetPeriodStatus={
                    updateWizardDataWhileSetPeriodStatus
                }
                mainCoverage={coldStorageLockerCoverage.supplementalCoverageTermCoverage}
                coverageFormPath="supplementalCoverageTermCoverage"
                updateCoverageFormClausesService={
                    IMMsicFormsService.updateColdStorageLockerCoverage
                }
            />
            <h4 className='mb-10'>{translator(messages.CoveredPremises)}</h4>
            <CoveredPremisesSchedule
                clause={coldStorageLockerCoverage.coveredPremises}
                isEditable={isEditable}
                setCoveragePartClauses={setColdStorageLockerCoverage}
                jobID={jobID}
                sessionUUID={sessionUUID}
                setIsEditing={setIsEditing}
            />
        </AccordionCard>
    );
};

export default ColdStorageLockerCoverageCard;
