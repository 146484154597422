import React, { useCallback, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { withRouter } from 'react-router-dom';
import {
    WizardPage,
    wizardProps
} from '@xengage/gw-portals-wizard-react';
import { WizardPageTemplate, WizardPageTemplateWithTitle } from 'gw-portals-wizard-components-ui';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { fnolCommonMessages } from 'gw-capability-fnol-common-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import FNOLPARepairFacilityPage from '../RepairFacility/RepairFacilityPage';
import FNOLPARepairFacilityMapPage from '../RepairFacilityMap/RepairFacilityMapPage';
import messages from '../../FNOLPA.messages';
import metadata from './TypeOfRepairPage.metadata.json5';
import styles from './TypeOfRepairPage.module.scss'

const repairFacility = {
    path: '/repair',
    component: FNOLPARepairFacilityPage,
    title: messages.wizardPARepairFacility,
    stepProps: {
        template: WizardPageTemplateWithTitle
    }
};

const repairFacilityMap = {
    path: '/repair',
    component: FNOLPARepairFacilityMapPage,
    title: messages.wizardPARepairFacility,
    stepProps: {
        template: WizardPageTemplateWithTitle
    }
};

function FNOLPATypeOfRepairPage(props) {
    const translator = useTranslator();
    const {
        onValidate,
        isComponentValid,
        initialValidation,
        registerInitialComponentValidation
    } = useValidation('TypeOfRepairPage');
    const {
        wizardData: claimVM, updateWizardData, steps, changeNextSteps
    } = props;

    const collisionTypeCodes = useMemo(
        () => _.filter(claimVM.lossCause.aspects.availableValues, (typeCode) => {
            // for purposes of portal 'otherobjcoll' is not a collision code
            const nonCollisionAutoCodes = [
                'theftentire',
                'glassbreakage',
                'theftparts',
                'otherobjcoll'
            ];
            return nonCollisionAutoCodes.indexOf(typeCode.code) === -1;
        }),
        [claimVM]
    );

    const repairChoicesPreferredVendor = useCallback(
        (secondaryLabel) => {
            if (secondaryLabel.id === messages.recommendedFacilityYouGet.id) {
                return (
                    <React.Fragment>
                        <div>{translator(messages.recommendedFacilityYouGet)}</div>
                        <ul>
                            <li>{translator(messages.lifetimeGuarantee)}</li>
                            <li>{translator(messages.discountOnOtherRepairs)}</li>
                            <li>{translator(messages.promptRepairService)}</li>
                        </ul>
                    </React.Fragment>
                );
            }
            return translator(secondaryLabel);
        },
        [translator]
    );

    useEffect(() => {
        const repairOptionPath = 'lobs.personalAuto.repairOption.value';
        const repairOptionVIPath = 'lobs.personalAuto.repairOption.vehicleIncident.value';
        const vehicleIncidentsPath = 'lobs.personalAuto.vehicleIncidents.value';
        const vehicleIncidents = _.get(claimVM, vehicleIncidentsPath, []);
        if (!_.get(claimVM, repairOptionPath)) {
            _.set(claimVM, repairOptionPath, {
                repairOptionChoice: null,
                repairFacility: null,
                vehicleIncident: null
            });
        }
        if (!_.get(claimVM, repairOptionVIPath) && vehicleIncidents.length === 1) {
            _.set(claimVM, repairOptionVIPath, vehicleIncidents[0]);
        }
        updateWizardData(claimVM);
        // Do not add in dependencies here as we only need to load the above information once
        // Adding these dependencies will cause a constant reload
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const repairPageHandling = useCallback(() => {
        const typeOfRepairPageIndex = _.findIndex(steps, { path: '/type-of-repair' });
        const repairPageIndex = typeOfRepairPageIndex + 1;
        if (steps[repairPageIndex].path === '/repair') {
            steps.splice(repairPageIndex, 1);
        }
        const remainingSteps = steps.slice(repairPageIndex, steps.length);
        let newSteps = [];
        switch (_.get(claimVM.value, 'lobs.personalAuto.repairOption.repairOptionChoice')) {
            case 'PreferredVendor':
                newSteps = [repairFacilityMap, ...remainingSteps];
                break;
            case 'NewVendor':
                newSteps = [repairFacility, ...remainingSteps];
                break;
            case 'NoRepair':
                newSteps = remainingSteps;
                break;
            default:
        }
        if (newSteps.length > 0) {
            changeNextSteps(newSteps);
        }
        return claimVM;
    }, [changeNextSteps, claimVM, steps]);

    useEffect(() => {
        repairPageHandling();
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNext = () => {
        repairPageHandling();
        return claimVM;
    };

    const handleChange = useCallback(
        (value, path) => {
            const oldRepairOptionObject = _.get(
                claimVM,
                'lobs.personalAuto.repairOption.repairOptionChoice.value'
            );
            const oldValue = !_.isEmpty(oldRepairOptionObject) ? oldRepairOptionObject.code : null;
            if (value !== oldValue) {
                _.set(claimVM, 'lobs.personalAuto.repairOption.repairFacility.value', null);
            }
            _.set(claimVM, path, value);
            updateWizardData(claimVM);
        },
        [claimVM, updateWizardData]
    );

    const handleVehicleIncidentSelect = useCallback(
        (value) => {
            const vehicleIncidents = _.get(claimVM, 'lobs.personalAuto.vehicleIncidents.value', []);
            const selectedIncident = vehicleIncidents.find((vehicleIncident) => {
                return vehicleIncident.vehicle.getDisplayName() === value;
            });
            _.set(
                claimVM,
                'lobs.personalAuto.repairOption.vehicleIncident.value',
                selectedIncident
            );
            updateWizardData(claimVM);
        },
        [claimVM, updateWizardData]
    );

    const getVehicleIncidentAvailableValues = useCallback(() => {
        const vehicleIncidents = _.get(claimVM, 'lobs.personalAuto.vehicleIncidents.value', []);
        const vehicleIncidentsArray = vehicleIncidents.map((vehicleIncident) => {
            return {
                code: vehicleIncident.vehicle ? vehicleIncident.vehicle.getDisplayName() : '',
                name: vehicleIncident.vehicle ? vehicleIncident.vehicle.getDisplayName() : ''
            };
        });
        return vehicleIncidentsArray;
    }, [claimVM]);

    const getIncidentVehicleName = useCallback(() => {
        const selectedIncidentVehicle = _.get(
            claimVM,
            'lobs.personalAuto.repairOption.vehicleIncident.vehicle.value'
        );
        return !_.isEmpty(selectedIncidentVehicle) ? selectedIncidentVehicle.getDisplayName() : '';
    }, [claimVM]);

    const getIncidentVehicleDropDownVisibility = useCallback(() => {
        const isMoreThanOneVehicleIncident = _.get(claimVM, 'lobs.personalAuto.vehicleIncidents.value').length > 1;
        const lossCauseCondition = collisionTypeCodes.some(
            (collisionType) => collisionType.code === _.get(claimVM, 'lossCause.value.code')
        );
        const isNoRepairSelected = _.get(claimVM, 'lobs.personalAuto.repairOption.repairOptionChoice.value') !== null
            && _.get(claimVM, 'lobs.personalAuto.repairOption.repairOptionChoice.value.code')
                !== 'NoRepair';
        return isMoreThanOneVehicleIncident && lossCauseCondition && isNoRepairSelected;
    }, [claimVM, collisionTypeCodes]);

    const overrideProps = {
        repairChoices: {
            value: _.get(claimVM.value, 'lobs.personalAuto.repairOption.repairOptionChoice'),
            renderSecondaryLabel: repairChoicesPreferredVendor
        },
        vehicleIncidentDropDownID: {
            visible: getIncidentVehicleDropDownVisibility(),
            availableValues: getVehicleIncidentAvailableValues(),
            value: getIncidentVehicleName()
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            handleVehicleIncidentSelect: handleVehicleIncidentSelect,
            handleChange: handleChange
        },
        resolveClassNameMap: styles
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, claimVM, id, path, overrideProps);
        },
        [claimVM, overrideProps]
    );

    const shouldPageSkip = useCallback(() => {
        const repairOptionChoice = _.get(
            claimVM.value,
            'lobs.personalAuto.repairOption.repairOptionChoice'
        );
        return !_.isEmpty(repairOptionChoice);
    }, [claimVM]);

    useEffect(() => {
        registerInitialComponentValidation(shouldPageSkip);
    }, [registerInitialComponentValidation, shouldPageSkip]);

    return (
        <WizardPage
            cancelLabel={translator(fnolCommonMessages.fnolSaveandExit)}
            skipWhen={initialValidation}
            disableNext={!isComponentValid}
            onNext={onNext}
            template={WizardPageTemplate}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                resolveValue={readValue}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                onModelChange={updateWizardData}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

FNOLPATypeOfRepairPage.propTypes = wizardProps;
export default withRouter(FNOLPATypeOfRepairPage);
