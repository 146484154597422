
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WniAccountService, WniPolicyService } from 'wni-capability-gateway';
import { SelectProducerCode } from 'gw-gateway-common-react';
import { PolicyPageAdvanceFilter, PolicyPageQuickFilter } from 'wni-capability-gateway-react';
import metadata from './Policies.metadata.json5';

const PolicyPage = (props) => {
    const [selectedProducerCode, updateSelectedProducerCode] = useState();
    const [toggleFilter, updateToggleFilter] = useState(false);
    const [isInitLoading, setInitLoading] = useState(true);
    const [recentlyViewedPolicies, updateRecentlyViewedPolicies] = useState([]);
    const [accountData, updateAccountData] = useState({});
    const [policyData, updatePolicyData] = useState({});

    const {
        authHeader,
        authUserData: {
            producerCodes_Ext: producerCodesMap
           
        } = {}
     } = useAuthentication();
    const {
        interactionModel,
        loadingMask: { setLoadingMask },
    } = useDependencies(['interactionModel', 'loadingMask']);

    const getResponseRecentlyIssuedPolicies = async () => {
        setLoadingMask(true);
        const producerCodeList = producerCodesMap.map((item) => item.code)
        const recentlyViewedResponse = await WniPolicyService.getRecentlyViewedIssuedPolicies(authHeader);
        updateRecentlyViewedPolicies(recentlyViewedResponse);

        const currentJobsResponse = await WniAccountService.getRecentlyViewedJobsForCurrentUser(authHeader);
        updateAccountData(currentJobsResponse || {});

        // QueryNum default to 120, configurable through env var DEFUALT_NUM_OF_RECENTLY_ISSUED_POLICIES
        // const queryNum = PerformanceConfig.NumOfRecentlyIssuedPolicies;
        // const recentlyIssuedResponse = await PolicyService.getRecentlyIssuedPolicies(120, authHeader);
        // QueryNum now is configurable through ScriptParameter in PC
        const recentlyIssuedResponse = await WniPolicyService.getRecentlyIssuedPoliciesV2(authHeader);
     
        // get delinquent policies number from bc
        const delinquentResponse = await WniPolicyService.getDelinquentPolicies(producerCodeList, authHeader);
        // get delinquent policies details based on policynumber
        const delinquentPolicies = await WniPolicyService.getPolicySummariesFromPolicyNumbers(delinquentResponse || [], authHeader)

        updatePolicyData({
            recentlyViewed: recentlyViewedResponse,
            recentlyIssued: recentlyIssuedResponse,
            delinquent: delinquentPolicies
        });
        setLoadingMask(false);
        setInitLoading(false);
    };

    useEffect(() => {
        getResponseRecentlyIssuedPolicies()
    }, []);

    const handleSelectProducerChange = (producerCodeParam) => {
        updateSelectedProducerCode(producerCodeParam);
    };
    const handleToggleFilter = () => {
        updateToggleFilter(!toggleFilter)
    };
    //-------------------------------------------
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showRequired: true,
            showOptional: false,
            autoComplete: false
        },
        advanceFilterLink: {
            visible: toggleFilter
        },
        quickFilterLink: {
            visible: !toggleFilter
        },
        advanceFilterSection: {
            visible: !toggleFilter,
            policyData: recentlyViewedPolicies,
            accountData: accountData,
            selectedProducerCode,
            isInitLoading,
            ...props
        },
        quickFilterSection: {
            visible: toggleFilter,
            policyData,
            accountData: accountData,
            selectedProducerCode,
            ...props
        }
        
    };

    const resolvers = {
        resolveCallbackMap: {
            handleValueChange: handleSelectProducerChange,
            handleFilterClick: handleToggleFilter
        },
        resolveComponentMap: {
          selectproducercode: SelectProducerCode,
          policyadvancefilter: PolicyPageAdvanceFilter,
          policyquickfilter: PolicyPageQuickFilter
        }
    };
    return <MetadataContent uiProps={metadata.pageContent} overrideProps={overrideProps} {...resolvers} />
};

export default withRouter(PolicyPage);
