import React, { useEffect, useState } from 'react';
import { DropdownMenuButton } from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import appConfig from 'app-config';
import { Link as LinkComponent } from 'gw-components-platform-react';
import { useProductsData, useBusinessData } from 'wni-portals-util-react';
import { WniDateUtil, WniAccountsUtil, WniProductsUtil } from 'wni-portals-util-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { AccountService } from 'gw-capability-gateway-policy';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useWniModal } from 'wni-components-platform-react';
import messages from '../AccountSummary.messages';
import metadata from './ActivePolicies.metadata.json5';

import { DropdownMenuLink } from '@jutro/router';

const {
    lobEndorsementURL
} = appConfig;

function ActivePolicies(props) {
    const {
        data,
        availableProducerCodesForCurrentUser,
        accountNumber,
        accountType,
        history
    } = props;
    const [productFilter, updateProductFilter] = useState('all');
    const [tableData, updateTableData] = useState(data);
    const modalApi = useWniModal();
    const translator = useTranslator()
    const {
        authUserData: {
            isExternalUser_Ext: isExternalUser,
            permissionMap_Ext: {
                createcancellation: hasCreateCancellationPermission,
                createpolchange: hasCreatePolicyChangePermission
            }
        } = {}
    } = useAuthentication();
    const {
        interactionModel,
        loadingMask: { setLoadingMask }
    } = useDependencies(['interactionModel', 'loadingMask']);

    const isCL = WniAccountsUtil.isCLAccount(accountType);

    const { getFeatureCodeValue } = useBusinessData();
    const enableCommercialChangeCancelForPE = getFeatureCodeValue('EnableCommercialChangeCancelForPE');

    
    const generateTableData = (initData) => {
        return initData.map((item) => {
            return {
                ...item,
                productName: WniProductsUtil.getProductNameByLinePattern(_.get(item, 'product')),
                productCode: _.get(item, 'product.productCode'),
                effectiveDate: WniDateUtil.formatDateWithPattern(
                    item.effective
                ),
                expirationDate: WniDateUtil.formatDateWithPattern(
                    item.expiration
                )
            };
        });
    }

    useEffect(() => {
        const initData = _.cloneDeep(data);
        updateTableData(generateTableData(initData));
    }, [data]);

    const getPolicyAccess = (agencyOfServiceCode) => {
        let result = false;
        if (!isExternalUser) {
            result = true;
        } else {
            _.forEach(availableProducerCodesForCurrentUser, (agency) => {
                const { code } = agency;
                if (agencyOfServiceCode === code) {
                    result = true;
                    return false;
                }
                return true;
            });
        }
        return result;
    };

    const showWarningMsg = () => {
        modalApi.showAlert({
            title: 'Warning',
            status: 'warning',
            icon: 'gw-error-outline',
            message: messages.warningMsgNoRights,
        }).catch(() => {
            _.noop();
        });
    };

    const getPolicySummaryLink = (policyNumber) => {
        return interactionModel.getURLObj(accountType, 'policySummary', accountNumber, policyNumber);
    };

    const getViewPolicyCell = (rowData, index, { path }) => {
        const agencyOfServiceCode = rowData.producerCodeOfService;
        const access = getPolicyAccess(agencyOfServiceCode);
        const policyNumber = _.get(rowData, 'policyNumber');
        let linkFieldProps = {}

        if (!policyNumber || !accountNumber) {
            return '-';
        }
        if (!access) {
            linkFieldProps= {
                onClick: showWarningMsg
            }
        } else {
            linkFieldProps = {
                to: `/policies/${policyNumber}/summary`
            }
        }
        
        return (
            <LinkComponent
                id={`policy${rowData[path]}`}
                {...linkFieldProps}
            >
                {policyNumber}
            </LinkComponent>
        );
    };

    const onChangePolicyClick = (item) => {
        const { policyNumber, productCode } = item;
        history.push(lobEndorsementURL[productCode], {
            policyNumber: policyNumber,
        });
    };

    const onCancelPolicyClick = (item) => {
        const { policyNumber } = item;
        const historyState = {
            disableCancelLink: false,
            disableChangePolicyLink: true,
            displayBtnGroup: false
        };
        history.push(`/policies/${policyNumber}/summary`, historyState);
    };

    const renderPolicyAction = (item) => {
        if ((isCL && !enableCommercialChangeCancelForPE)) {
            return '-';
        }
        const actionAvailable = hasCreateCancellationPermission || hasCreatePolicyChangePermission;
        const access = getPolicyAccess(item.producerCodeOfService);
        if (actionAvailable) {
            return (
                <div>
                    <DropdownMenuButton
                        icon="gw-expand-more"
                        id="dropdownMenuButton"
                        className="dropDownMenuIconbtn"
                        menuClassName="dropDownMenuList"
                        alignRight
                    >
                        {hasCreatePolicyChangePermission && (
                            <DropdownMenuLink
                                onClick={access ? () => onChangePolicyClick(item) : showWarningMsg}
                            >
                                {translator(messages.changePolicy)}
                            </DropdownMenuLink>
                        )}
                        {hasCreateCancellationPermission && (
                            <DropdownMenuLink
                                onClick={access ? () => onCancelPolicyClick(item) : showWarningMsg}
                            >
                                {translator(messages.cancelPolicy)}
                            </DropdownMenuLink>
                        )}
                    </DropdownMenuButton>
                </div>
            );
        }
    };

    useEffect(() => {
        const newData = _.cloneDeep(data);
        const generateData = generateTableData(newData);
        let filterData = generateData;
        if(productFilter !== 'all') {
            filterData = filterData.filter((item) => item.productCode === productFilter)
        };
        updateTableData(filterData)
    }, [productFilter]);

    const handleProductFilterChange = (newFilter) => {
        updateProductFilter(newFilter);
    };

    const getProductFilterAvailableItems = () => {
        const options = [{
            code: 'all',
            name: translator(messages.productFilterAllProductsItemName),
        }];
        data.forEach((policySummary) => {
            const productCode = _.get(policySummary, 'product.productCode');
            if (!options.some((option) => option.code === productCode)) {
                options.push({
                    code: productCode,
                    name: WniProductsUtil.getProductNameByLinePattern(policySummary.product)
                });
            }
        });
        return options;
    };
    const productFilterAvailableValue = getProductFilterAvailableItems();

    const overrides = {
        activePoliciesTable: {
            data: tableData
        },
        productFilter: {
            availableValues: productFilterAvailableValue,
            value: productFilter,
            onValueChange: handleProductFilterChange
        },
    };
    const resolvers = {
        resolveCallbackMap: {
            getViewPolicyCell,
            renderPolicyAction
        },
        resolveComponentMap: {
        },
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={{}}
            overrideProps={overrides}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default ActivePolicies;
