
import React, {
    useCallback
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ServiceManager } from '@jutro/legacy/services';
import { useHistory } from 'react-router-dom';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import {  useProductsData } from 'wni-portals-util-react';
import { WniProductsUtil } from 'wni-portals-util-js';
import metadata from './QuoteAdditionalProductComponent.metadata.json5';
import styles from './QuoteAdditionalProductComponent.module.scss';
import { DropdownMenuLink } from '@jutro/router';

/**
 * Quote Additional Product for usage on Account Summary page and HOQuotePage
 * 
 * @param {object} props
 * @returns {object} React Component
 */
function QuoteAdditionalProductComponent(props) {
    const {
        startSubmissionFn: startSubmission,
        accountNumber,
        accountType,
        baseState,
        alreadyOwnedProductCode = [],
        productAvailableForAccount = [],
        dropUp
    } = props;
    const history = useHistory()
    const { getAvailableProducts } = useProductsData();

    const { interactionModel } = useDependencies(['interactionModel']);
    const localeService = ServiceManager.getService('locale-service');
    const defaultCountryCode = localeService.getDefaultCountryCode();

    const productClickFn = (productItem) => {
        const { code: productCode, jumpToAO } = productItem;
        if (jumpToAO){
            interactionModel.goToPage(null, history, 'newQuote', accountNumber);
        } else{
            startSubmission(productCode, defaultCountryCode);
        }
    };

    
    const generateAvailableProducts = useCallback(() => {
        const productList = getAvailableProducts(accountType, baseState);
        const filterProductList = !_.isEmpty(alreadyOwnedProductCode) ? productList.filter((item) => !alreadyOwnedProductCode.includes(item.code)) : productList;
        const availableProductsForAccount = filterProductList.filter((product) => WniProductsUtil.isProductAvailableForAccount(product.code, productAvailableForAccount));
        const retval = availableProductsForAccount.map((product) => (
                <DropdownMenuLink
                    key={product.code}
                    disabled={!product.available}
                    onClick={() => productClickFn(product)}
                >
                    {product.name}
                </DropdownMenuLink>
            ));
        return retval;
    }, [accountType, baseState, alreadyOwnedProductCode, productAvailableForAccount]);


    //----------------------------------
    const menuContent = generateAvailableProducts();
    const overrideProps = {
        '@all': {
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        quoteNewLineMenu: {
            content: menuContent,
            disabled: _.isEmpty(menuContent),
            dropUp
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {

        },
        resolveComponentMap: {

        },
    };

    //---------

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={{}}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

QuoteAdditionalProductComponent.propTypes = {
    // /**
    //  * A list of ProductSelectionDTO, typically from AccountQuoteDataDTO.AvailableProducts
    //  * 
    //  * Related components:
    //  * 1, WniAccountQuotePlugin
    //  * 2, AccountQuoteDataDTo
    //  */
    // availableProducts: PropTypes.arrayOf(PropTypes.object),
    /**
     * A function to create new submission provided productCode and an available flag.
     * 
     * Related Components:
     * 1, AQAccountSummaryPage.startSubmission()
     * 2, 
     */
    startSubmissionFn: PropTypes.func,
    /**
     * account number for creating new quote in local env (instead of jumping to AO)
     */
    accountNumber: PropTypes.string,
};

QuoteAdditionalProductComponent.defaultProps = {
    // availableProducts: undefined,
    startSubmissionFn: _.noop,
    accountNumber: undefined,
};

export default QuoteAdditionalProductComponent;
