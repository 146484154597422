import React, {
    useState,
    useEffect,
    useContext
} from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { CUTooltipUtil as tooltips } from 'wni-portals-tooltip';
import { WniDate, WniDropdownSelect, WniTextArea, WniToggle, WniInputText, WniInputNumberWithAction, WniCurrencyWithAction } from 'wni-common-base-components';
import CUCoverageUtil from '../../util/CUCoverageUtil';
import messages from './CUSingleClauseComponent.messages';
import clausesMessages from '../CUClausesComponentVM/CUClausesComponentVM.messages';
import SingleClauseContext from '../../context/CUSingleClauseContext';
import CoveragesConfigContext from '../../context/CUCoveragesConfigContext';


function getPath(changedValuePath) {
    // onBlur event returns an object instead of path as a String
    const pathToNormalise = _.isObject(changedValuePath)
        ? changedValuePath.model : changedValuePath;

    return pathToNormalise;
}

const originEditingValueFromTerm = (term) => {
    const valueType = _.get(term, 'valueType')
    if (valueType === 'Money') {
        return term.directValue
    }
    if (['shorttext', 'longtext'].includes(valueType)) {
        return term.directStringValue
    }
    if (valueType === 'datetime') {
        return term.directDateValue
    }
    return term.directValue
}

function CUTermComponent(props) {

    const {
        clauseCode,
        clausePath,
        isEditable,
        isDisabled,
        onChangeClause,
        // onSyncCoverages,
        onChangeSubmissionAndSync,
        labelPosition,
        labelTop,
        onValidate,
        setIsEditing,
        showErrors,
    } = useContext(SingleClauseContext);

    const coveragesConfig = useContext(CoveragesConfigContext);

    const {
        term = {},
        termIndex,
        hideLabel = false,
        className,
        calculateLimit
    } = props

    const {
        termConfig,
    } = coveragesConfig;

    const {
        name,
        isEditable: isTermEditable = true,
        options = [],
        code_Ext: code,
        chosenTerm,
        chosenTermValue,
        visibleCalculateButton_Ext: visibleCalculateButton,
        showAsTextArea_Ext: showAsTextArea
    } = term  

    const translator = useTranslator();

    const originEditingValue = originEditingValueFromTerm(term);
    const [editingValue, setEditingValue] = useState(originEditingValue)

    useEffect (() => {
        const targetOption = options.find(elt => elt.code === chosenTerm);
        if ( !_.isNil(targetOption) && targetOption.name !== chosenTermValue) {
            targetOption.name = chosenTermValue;
        }
    }) 

    useEffect(() => {
        setEditingValue(originEditingValue)
    }, [originEditingValue])

    useEffect(() => {
        if (onValidate) {
            onValidate(!CUCoverageUtil.isTermInvalid(term), term.code_Ext);
        }
        return () => {
            if (onValidate) {
                onValidate(true, term.code_Ext);
            }
        }
    }, [onValidate, term])

    const handleChangeClause = (value) => {
        if (editingValue !== value) {
            setIsEditing(true)
            setEditingValue(value)
        }
        
    };

    const handleSyncCoverages = (evt, changedValues) => {
        setIsEditing(false)
        const { beforeValue, value: newValue, model } = changedValues;
        const actualChangedPath = getPath(model);

        const isValueChange = (beforeValue !== newValue)

        if (isValueChange) {
            // Use changeWithSync here instead of sync, because editing value is not saved to submission
            // Use changeWithSync to save value to VM and sync to PC
            const fieldNotChangeWithSync = _.get(termConfig.termsNotChangeWithSync, clauseCode, [])
            if (fieldNotChangeWithSync.includes(code)) {
                if (onChangeClause) {
                    return Promise.resolve(onChangeClause(newValue, actualChangedPath));
                }
            }
            if (onChangeSubmissionAndSync) {
                return Promise.resolve(onChangeSubmissionAndSync(newValue, actualChangedPath));
            }
        }
        return Promise.resolve();
    };

    const handleChangeWithSync = (value, changedValuePath) => {
        if (_.get(term, 'chosenTerm') !== value) {
            const fieldNotChangeWithSync = _.get(termConfig.termsNotChangeWithSync, clauseCode, [])
            if (fieldNotChangeWithSync.includes(code)) {
                if (onChangeClause) {
                    return Promise.resolve(onChangeClause(value, changedValuePath));
                }
            }
            if (onChangeSubmissionAndSync) {
                return Promise.resolve(onChangeSubmissionAndSync(value, changedValuePath));
            }
        }
        return Promise.resolve();
    };

    const termPathPrefix = `${clausePath}.terms.children[${termIndex}]`;

    const commmonTermProps = {
        id: `${clauseCode}_ClauseTerm__[${code}]`,
        label: name,

        path: `${termPathPrefix}.chosenTerm`,
        readOnly: !isTermEditable || !isEditable || term.readOnly_Ext,
        disabled: isDisabled,
        value: term.chosenTerm,
        labelPosition: labelPosition,
        layout: labelTop ? 'full-width' : null,
        onValueChange: handleChangeWithSync,
        required: isEditable && term.required,
        showRequired: term.required,
        className: `clause_term ${className}`,
        tooltip: {
            text: tooltips[term.code_Ext]
        },
        showErrors,
        hideLabel,
    }
    const hasOption = !_.isEmpty(options)

    const availableValues = hasOption ? options.filter((option) => {
        // Filtering out the None Selected value as the backend sets the code value
        // for None Selected to be an empty string. This results in the option not
        // showing in the dropdown select component. We are handling this by using
        // None Selected as a placeholder which will always be shown to the user
        return option.code;
    }).map((option) => {
        return {
            code: '',
            id: '',
            ...option
        };
    }) : [];

    const hasAvailableValues = _.has(term, 'options') && !_.isEmpty(availableValues);
    const valueType = showAsTextArea ? 'longtext' : _.has(term, 'valueType') && term.valueType;

    const onlyOneAvailableValue = hasAvailableValues && availableValues.length === 1;

    const readOnlyCausedByOnlyOneAvailableValue = onlyOneAvailableValue &&
        _.get(term, 'chosenTerm') === _.get(availableValues, '[0].code')

        
    const getMessageByMinMax = () => {
        let key = 'maxValue';
        if (term.directValueMax && term.directValueMin) {
            key = 'selectedValue';
        } else if (term.directValueMin) {
            key = 'minValue';
        }
        return clausesMessages[key];
    };

    if (!hasAvailableValues && valueType === 'Money') {

        let currencyOutOfRange = false

        if (!_.isNil(editingValue)) {
            const lowerThanMinValue = !!term.directValueMin && editingValue < term.directValueMin
            const higherThanMaxValue = !!term.directValueMax && editingValue > term.directValueMax

            currencyOutOfRange = lowerThanMinValue || higherThanMaxValue
        }

        return <WniCurrencyWithAction
            {...commmonTermProps}
            dataType= 'number'
            value= {editingValue}
            onValueChange= {handleChangeClause}
            onBlur= {handleSyncCoverages}
            maxValue={term.directValueMax}
            minValue= {term.directValueMin}
            path= {`${termPathPrefix}.directValue`}
            required= {term.required}
            showFractions= {false}
            actionVisible= {visibleCalculateButton && isEditable}
            actionFn={() => {calculateLimit(code)}}
            actionConfig= {{
                "id": "action",
                "text": "Calculate",
                "type": "filled"
            }}
            validationMessages={currencyOutOfRange ? [translator(getMessageByMinMax(), {
                maxValue: term.directValueMax,
                minValue: term.directValueMin
            })] : undefined}
        />
    }

    if (hasAvailableValues && valueType === 'bit') {
        // chosenTerm for bit terms with more than 2 choices?
        return <WniToggle
            {...commmonTermProps}
            value= {term.chosenTerm}
            availableValues= {term.options.filter((option) => option.code)}
            required= {term.required}
        />
    }

    if (!hasAvailableValues && valueType === 'datetime') {
        return <WniDate
            {...commmonTermProps}
            dataType= 'string'
            onValueChange={handleChangeClause}
            onBlur= {handleSyncCoverages}
            value= {editingValue}
            path= {`${termPathPrefix}.directDateValue`}
            required= {term.required}
            showTime= {false}
        />
    }

    if (['longtext'].includes(valueType)) {
        return <WniTextArea
            {...commmonTermProps}
            className="textArea-minHeight-100 textArea-align-start"
            onValueChange= {handleChangeClause}
            onBlur= {handleSyncCoverages}
            path= {`${termPathPrefix}.directStringValue`}
            value= {editingValue}
            required= {term.required}
            // placeholder: term.required ? '-- Required for Quote --' : '',
            placeholder= ''
        />
    }

    if (['shorttext'].includes(valueType)) {
        const isShowTextArea = name === 'Additional Information';
        if (isShowTextArea) {
            return <WniTextArea
                {...commmonTermProps}
                className="textArea-minHeight-100 textArea-align-start"
                onValueChange= {handleChangeClause}
                onBlur= {handleSyncCoverages}
                path= {`${termPathPrefix}.directStringValue`}
                value= {editingValue}
                required= {term.required}
                placeholder= ''
            />
        } 
        return <WniInputText
            {...commmonTermProps}
            onValueChange= {handleChangeClause}
            onBlur= {handleSyncCoverages}
            path= {`${termPathPrefix}.directStringValue`}
            value= {editingValue}
            required= {term.required}
            // placeholder: term.required ? '-- Required for Quote --' : '',
            placeholder= ''
        />
    }

    if (!valueType) {
        return <WniDropdownSelect
            {...commmonTermProps}
            availableValues={availableValues}
            path= {`${termPathPrefix}.chosenTerm`}
            value= {term.chosenTerm}
            readOnly= {!isEditable || readOnlyCausedByOnlyOneAvailableValue || term.readOnly_Ext}
            required= {term.required}
            // placeholder: term.required ? '-- Required for Quote --' : '',
            placeholder= {
                _.some(term.options, ['name', translator(messages.noneSelected)])
                    ? translator(messages.noneSelected) : ''}
        />

    }

    if (valueType === 'Percent') {
        return <WniInputNumberWithAction
        {...commmonTermProps}
        onValueChange= {handleChangeClause}
        onBlur= {handleSyncCoverages}
        value= {editingValue}
        maxValue= {term.directValueMax}
        minValue= {term.directValueMin}
        path= {`${termPathPrefix}.directValue`}
        required= {term.required}
        decimalPlaces={4}
        placeholder= ''
        className='searchFieldWithButton'
        actionConfig= {{
            id: "naicsDescriptionSearchButton",
            icon: "cust-percent"
        }}
        messageProps= {{
            validationMaxValue: translator(getMessageByMinMax(), {
                maxValue: term.directValueMax,
                minValue: term.directValueMin
            }),
            validationMinValue: translator(getMessageByMinMax(), {
                maxValue: term.directValueMax,
                minValue: term.directValueMin
            }),
        }}
        />
    }

    return <WniInputNumberWithAction
        {...commmonTermProps}
        onValueChange= {handleChangeClause}
        onBlur= {handleSyncCoverages}
        value= {editingValue}
        maxValue= {term.directValueMax}
        minValue= {term.directValueMin}
        path= {`${termPathPrefix}.directValue`}
        required= {term.required}
        decimalPlaces={4}
        // placeholder: term.required ? '-- Required for Quote --' : '',
        placeholder= ''
        actionVisible= {visibleCalculateButton && isEditable}
        actionFn={() => {calculateLimit(code)}}
        actionConfig= {{
            "id": "action",
            "text": "Calculate",
            "type": "filled"
        }}
        messageProps= {{
            validationMaxValue: translator(getMessageByMinMax(), {
                maxValue: term.directValueMax,
                minValue: term.directValueMin
            }),
            validationMinValue: translator(getMessageByMinMax(), {
                maxValue: term.directValueMax,
                minValue: term.directValueMin
            }),
        }}
    />
}
export default CUTermComponent;